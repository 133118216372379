import React, { useContext, useEffect, useState } from "react";
import AnimationContext from "../../../context/AnimationContext";
import styled, { css } from "styled-components";

const AnimationIn = (props) => {
  const [visible, setVisible] = useState(true);
  const { route, setAnimationIn, windowWidth } = useContext(AnimationContext);
  useEffect(() => {
    setTimeout(() => {
      setVisible(false);
      setAnimationIn(false);
    }, props.delay);
  }, [props.delay]);

  if (route === "isRent" && windowWidth <= 1920) {
    return visible ? (
      <div className="video_wrapper">
        <video className="animation_video" autoPlay muted playsInline>
          <source
            src="https://househawkbucket.s3.amazonaws.com/househawkoneyellow_e46756e717.webm"
            type="video/webm"
          />
        </video>
      </div>
    ) : (
      <div className="video_wrapper fade-out">
        <div />
      </div>
    );
  }
  if (route === "isRent") {
    return visible ? (
      <div className="video_wrapper">
        <video className="animation_video" autoPlay muted playsInline>
          <source
            src="https://househawkbucket.s3.amazonaws.com/rent_in_ultra_64ebb0ba62.webm"
            type="video/webm"
          />
        </video>
      </div>
    ) : (
      <div className="video_wrapper fade-out">
        <div />
      </div>
    );
  }
  if (route === "isSell" && windowWidth <= 1920) {
    return visible ? (
      <div className="video_wrapper">
        <video className="animation_video" autoPlay muted playsInline>
          <source
            src="https://firebasestorage.googleapis.com/v0/b/thehousehawk-b7691.appspot.com/o/public%2Fanimations%2Fsell%2Fred_in.webm?alt=media&token=625e5e37-d5f4-400e-a5d8-10d5ff30d2d2"
            type="video/webm"
          />
        </video>
      </div>
    ) : (
      <div className="video_wrapper fade-out">
        <div />
      </div>
    );
  }
  if (route === "isSell") {
    return visible ? (
      <div className="video_wrapper">
        <video className="animation_video" autoPlay muted playsInline>
          <source
            src="https://firebasestorage.googleapis.com/v0/b/thehousehawk-b7691.appspot.com/o/public%2Fanimations%2Fsell%2Fsell_in_ultra.webm?alt=media&token=4321d1ec-dbbe-4e4b-9780-97f8740a7d0d"
            type="video/webm"
          />
        </video>
      </div>
    ) : (
      <div className="video_wrapper fade-out">
        <div />
      </div>
    );
  }
  if (route === "isInvest") {
    return visible ? (
      <div className="video_wrapper">
        <video className="animation_video" autoPlay muted playsInline>
          <source
            src="https://firebasestorage.googleapis.com/v0/b/thehousehawk-b7691.appspot.com/o/public%2Fanimations%2Finvest%2Finvest_in_ultra.webm?alt=media&token=d800732c-e432-4233-99cd-9643e6f366f5"
            type="video/webm"
          />
        </video>
      </div>
    ) : (
      <div className="video_wrapper fade-out">
        <div />
      </div>
    );
  }
  if (route === "isInvest" && windowWidth <= 1920) {
    return visible ? (
      <div className="video_wrapper">
        <video className="animation_video" autoPlay muted playsInline>
          <source
            src="https://firebasestorage.googleapis.com/v0/b/thehousehawk-b7691.appspot.com/o/public%2Fanimations%2Finvest%2Fgreen_in.webm?alt=media&token=1f74735d-1124-4ef1-966b-3f8c48d76f26"
            type="video/webm"
          />
        </video>
      </div>
    ) : (
      <div className="video_wrapper fade-out">
        <div />
      </div>
    );
  }
  if (route === "isBuy") {
    return visible ? (
      <div className="video_wrapper">
        <video
          className="animation_video"
          preload="auto"
          autoPlay
          muted
          playsInline
        >
          <source
            src="https://firebasestorage.googleapis.com/v0/b/thehousehawk-b7691.appspot.com/o/public%2Fanimations%2Fbuy%2Fblue_in.webm?alt=media&token=be5de990-6423-4c2f-8dc5-4fc77cb4aea3"
            type="video/webm"
          />
        </video>
      </div>
    ) : (
      <div className="video_wrapper fade-out">
        <div />
      </div>
    );
  }
  if (route === "isBuy" && windowWidth <= 1920) {
    return visible ? (
      <div className="video_wrapper">
        <video
          className="animation_video"
          preload="auto"
          autoPlay
          muted
          playsInline
        >
          <source
            src="https://firebasestorage.googleapis.com/v0/b/thehousehawk-b7691.appspot.com/o/public%2Fanimations%2Fbuy%2Fbuy_in_ultra_e0099e42d9.webm?alt=media&token=88a0fd02-1ba6-4ad6-8864-83b1f2b5029d"
            type="video/webm"
          />
        </video>
      </div>
    ) : (
      <div className="video_wrapper fade-out">
        <div />
      </div>
    );
  }
  return <div></div>;
};

export default AnimationIn;
