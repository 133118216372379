import React, { createContext, useState } from "react";

export const UserContext = createContext(null);

export const UserProvider = ({ data, children }) => {
  const [user, setUser] = useState(null);

  if (!user) {
    return (
      <UserContext.Provider value={{ user, setUser }}>
        {children}
      </UserContext.Provider>
    );
  }
  if (user) {
    return (
      <UserContext.Provider value={{ user, setUser }}>
        {children}
      </UserContext.Provider>
    );
  }
};

export default UserContext;
