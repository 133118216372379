import React, { useEffect, useState, useContext } from "react";
import AnimationContext from "../../../context/AnimationContext";
import styled, { css } from "styled-components";

const AnimationOut = ({ nextStep, goToNextStep, ...props }) => {
  const [visible, setVisible] = useState(true);
  const { route, animationOut, setAnimationOut, windowWidth, animation } =
    useContext(AnimationContext);
  useEffect(() => {
    setTimeout(() => {
      setAnimationOut(false);
    }, props.delay);
  }, [props.delay]);

  if (route === "isRent") {
    return visible ? (
      <div className="video_wrapper">
        <video className="animation_video" autoPlay muted playsInline>
          <source
            src="https://househawkbucket.s3.amazonaws.com/rent_out_ultra_3cb15a14bd.webm"
            type="video/webm"
          />
        </video>
      </div>
    ) : (
      <div />
    );
  }
  if (route === "isSell" && windowWidth <= 1920) {
    return visible ? (
      <div className="video_wrapper">
        <video className="animation_video" autoPlay muted playsInline>
          <source
            src="https://firebasestorage.googleapis.com/v0/b/thehousehawk-b7691.appspot.com/o/public%2Fanimations%2Fsell%2Fred_out.webm?alt=media&token=daa200b8-02db-4305-834b-d2d58a98e6dd"
            type="video/webm"
          />
        </video>
      </div>
    ) : (
      <div />
    );
  }
  if (route === "isSell") {
    return visible ? (
      <div className="video_wrapper">
        <video className="animation_video" autoPlay muted playsInline>
          <source
            src="https://firebasestorage.googleapis.com/v0/b/thehousehawk-b7691.appspot.com/o/public%2Fanimations%2Fsell%2Fsell_out_ultra.webm?alt=media&token=e452e976-a7f2-46a9-80a0-7d9d9f73b48e"
            type="video/webm"
          />
        </video>
      </div>
    ) : (
      <div />
    );
  }
  if (route === "isInvest" && windowWidth <= 1920) {
    return visible ? (
      <div className="video_wrapper">
        <video className="animation_video" autoPlay muted playsInline>
          <source
            src="https://firebasestorage.googleapis.com/v0/b/thehousehawk-b7691.appspot.com/o/public%2Fanimations%2Finvest%2Fgreen_out.webm?alt=media&token=be6cce44-5806-454b-a389-ba805725fa0b"
            type="video/webm"
          />
        </video>
      </div>
    ) : (
      <div />
    );
  }
  if (route === "isInvest") {
    return visible ? (
      <div className="video_wrapper">
        <video className="animation_video" autoPlay muted playsInline>
          <source
            src="https://firebasestorage.googleapis.com/v0/b/thehousehawk-b7691.appspot.com/o/public%2Fanimations%2Finvest%2Finvest_out_ultra.webm?alt=media&token=d800732c-e432-4233-99cd-9643e6f366f5"
            type="video/webm"
          />
        </video>
      </div>
    ) : (
      <div />
    );
  }

  if (route === "isBuy" && windowWidth <= 1920) {
    return visible ? (
      <div className="video_wrapper">
        <video className="animation_video" autoPlay muted playsInline>
          <source
            src="https://firebasestorage.googleapis.com/v0/b/thehousehawk-b7691.appspot.com/o/public%2Fanimations%2Fbuy%2Fblue_out.webm?alt=media&token=50a2d512-b296-45d0-a993-9e1bf880171d"
            type="video/webm"
          />
        </video>
      </div>
    ) : (
      <div />
    );
  }
  if (route === "isBuy") {
    return visible ? (
      <div className="video_wrapper">
        <video className="animation_video" autoPlay muted playsInline>
          <source
            src="https://firebasestorage.googleapis.com/v0/b/thehousehawk-b7691.appspot.com/o/public%2Fanimations%2Fbuy%2Fbuy_out_ultra_27bcf14fb4.webm?alt=media&token=d74b4634-95d5-4604-b71f-695202378a2e"
            type="video/webm"
          />
        </video>
      </div>
    ) : (
      <div />
    );
  }

  return <div></div>;
};

export default AnimationOut;

const Grass = styled.div`
  background-color: green;
  height: 30px;
  width: 100vw;
  bottom: 0px;
  position: absolute;
`;
