import React, { useEffect, useState, useContext } from "react";
import styled, { css, keyframes } from "styled-components";
import { Label } from ".";
import AnimationContext from "../../../context/AnimationContext";
const RadioButton = ({
  onClick,
  id,
  icon,
  icon_text,
  label,
  value,
  inpClass,
  lblClass,
  iconClass,
  outerClass,
  span,
  animationIcon,
  part,
  popOut,
  isOther,
  height,
  width
}) => {
  const { route } = useContext(AnimationContext);
  return (
    <StyledRadioButton>
      <StyledInput
        onClick={onClick}
        type="checkbox"
        id={id}
        value={value}
        name="check"
        route={route}
      />
      <StyledRadioLabel
        height={height}
        width={width}
        route={route}
        htmlFor={id}
      >
        <StyledIcon>
          {icon === null ? (
            <StyledSpan route={route} className={span}>
              {icon_text}
            </StyledSpan>
          ) : (
            <StyledSpan route={route}>
              <img alt="icon" src={icon.url} />
            </StyledSpan>
          )}
          {isOther && (
            <StyledSpan route={route} className={part}>
              <img
                alt="icon"
                src="https://househawkbucket.s3.amazonaws.com/other_956418866f.png"
              />
            </StyledSpan>
          )}
        </StyledIcon>
        <StyledLabel>{label}</StyledLabel>
      </StyledRadioLabel>
    </StyledRadioButton>
  );
};

export default RadioButton;

const StyledRadioButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
`;
const bounce = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
  

`;
const StyledRadioLabel = styled.label`
  justify-content: flex-start;
  text-align: center;
  display: flex;
  flex-direction: column;
  fled-wrap: wrap;
  align-items: center;
  background: #ffffff;
  color: black;
  border-radius: 5px;
  border: 2px solid #ffffff;
  margin-bottom: 0;
  cursor: pointer;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-in-out;
  font-size: 14px;
  font-weight: 400;
  width: 9rem;
  height: 9rem;
`;

const StyledLabel = styled.div`
  width: 100%;
  margin: auto;
  p {
    word-wrap: break-word;
  }
`;

const StyledInput = styled.input`
  display: none;
  position: absolute;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  &:checked + ${StyledRadioLabel} {
    animation-name: ${bounce};

    -webkit-animation: ${bounce} 0.5s alternate;
    -moz-animation: ${bounce} 0.5s alternate;
    animation: ${bounce} 0.5s alternate;
    background: #ffffff;
    ${props =>
      props.route === "isBuy" &&
      css`
        border-color: #0052ff;
        background-color: #5bbaff;
        color: white;
      `}
    ${props =>
      props.route === "isRent" &&
      css`
        border-color: #ffe817;
        color: black;
        background-color: #ffba17;
      `}
      ${props =>
        props.route === "isInvest" &&
        css`
          border-color: #00b050;
          background-color: #55d668;
          color: white;
        `}
      ${props =>
        props.route === "isSell" &&
        css`
          border-color: #ff0800;
          background-color: #ff9c99;
          color: white;
        `}
  }
`;
const StyledIcon = styled.div`
  padding: 0.6rem;
  display: flex;
  height: 28px;
  flex-direction: column;
  align-items: center;
`;
const StyledSpan = styled.span`
  width: 28px;
  height: 28px;
  font-size: 16px;
  text-align: center;
  line-height: 24px;
  font-weight: bold;
  border-radius: 50%;
  img {
    height: 24px;
    width: 24px;
    border-radius: 50%;
    text-align: center;
  }
  ${props =>
    props.route === "isBuy" &&
    css`
      color: white;
      background: #0052ff;
    `}
  ${props =>
    props.route === "isRent" &&
    css`
      background: #ffe817;
    `}
    ${props =>
      props.route === "isInvest" &&
      css`
        color: white;
        background: #00b050;
      `}
    ${props =>
      props.route === "isSell" &&
      css`
        color: white;
        background: #ff0800;
      `}
`;
