import React, { useContext, useState } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import CreatableSelect from "react-select/creatable";
import styled, { css } from "styled-components";
import { device } from "../../../utils/mediaQueries";
import AnimationContext from "../../../context/AnimationContext";

const animatedComponents = makeAnimated();

export const DropdownComponent = ({
  onChange,
  isClearable,
  isMulti,
  options,
  placeholder,
  icon,
}) => {
  const { route } = useContext(AnimationContext);
  const [isSearchable, setIsSearchable] = useState(false);

  return (
    <SelectWrapper route={route}>
      <img src={icon} alt="" />
      <Select
        className="option-color"
        components={animatedComponents}
        isMulti={isMulti}
        isClearable={isClearable}
        options={options}
        onChange={onChange}
        placeholder={placeholder}
        isSearchable={isSearchable}
        inputProps={{
          style: {
            pointerEvents: "none",
            userSelect: "none",
            MozUserSelect: "none",
            WebkitUserSelect: "none",
          },
        }}
      />
    </SelectWrapper>
  );
};

export const SelectDropdownComponent = ({
  placeholder,
  isClearable,
  isMulti,
  options,
}) => {
  const handleChange = (newValue, actionMeta) => {
    console.group("Value Changed");
    console.groupEnd();
  };
  const handleInputChange = (inputValue, actionMeta) => {
    console.group("Input Changed");
    console.groupEnd();
  };
  return (
    <CreatableSelect
      className="option-color"
      components={animatedComponents}
      isMulti={isMulti}
      isClearable={isClearable}
      options={options}
      onChange={onChange}
      placeholder={placeholder}
      isSearchable={isSearchable}
      inputProps={{
        style: {
          pointerEvents: "none",
          userSelect: "none",
          MozUserSelect: "none",
          WebkitUserSelect: "none",
        },
      }}
    />
  );
};

const SelectWrapper = styled.div`
  // width: 240px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 0.8rem;
  margin: 4rem 0.8rem;
  ${(props) =>
    props.route === "isBuy" &&
    css`
      border: 2px solid #0052ff;
    `}
  ${(props) =>
    props.route === "isRent" &&
    css`
      border: 2px solid #ffe817;
    `}
      ${(props) =>
    props.route === "isSell" &&
    css`
      border: 2px solid #ff0800;
    `}
        ${(props) =>
    props.route === "isInvest" &&
    css`
      border: 2px solid #00b050;
    `}
  input {
    width: 100% !important;
    color: black !important;
  }
  .option-color {
    color: black !important;
    font-size: 12px;
  }
  img {
    width: 2.4rem;
    height: 2.4rem;
    padding: 0.4rem;
    ${(props) =>
      props.route === "isBuy" &&
      css`
        filter: invert(76%) sepia(37%) saturate(2813%) hue-rotate(133deg)
          brightness(99%) contrast(107%);
      `}
    ${(props) =>
      props.route === "isRent" &&
      css`
        filter: invert(76%) sepia(37%) saturate(2813%) hue-rotate(133deg)
          brightness(99%) contrast(107%);
      `}
        ${(props) =>
      props.route === "isSell" &&
      css`
        filter: invert(76%) sepia(37%) saturate(2813%) hue-rotate(133deg)
          brightness(99%) contrast(107%);
      `}
          ${(props) =>
      props.route === "isInvest" &&
      css`
        filter: invert(76%) sepia(37%) saturate(2813%) hue-rotate(133deg)
          brightness(99%) contrast(107%);
      `}
  }
`;
