import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
const Footer = () => {
  return (
    <FooterWrapper>
      <a href="https://firebasestorage.googleapis.com/v0/b/thehousehawk-b7691.appspot.com/o/public%2Fdocs%2FIABS%201-0.pdf?alt=media&token=e285c4ce-3c46-4596-8f56-9cd1b70b3cee">
        Texas Information About Brokerage Services
      </a>
      <a href="https://firebasestorage.googleapis.com/v0/b/thehousehawk-b7691.appspot.com/o/public%2Fdocs%2FCN%201-4-1_1.pdf?alt=media&token=cabdcf00-2a1c-4273-bddf-862c2142cb38">
        Texas Consumer Protection Notice
      </a>
    </FooterWrapper>
  );
};

export default Footer;

const FooterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 2rem;
  height: 5vh;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  font-size: 10px;
  a {
    margin-right: 2rem;
  }
`;
