import * as React from "react";
import { Wizard, useWizard } from "react-use-wizard";
import { useState, useContext, useEffect } from "react";
import AnimationContext from "../../../context/AnimationContext";
import BuyContext from "../../../context/BuyContext.jsx";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { db, firestore } from "../../../utils/firebase";
import { onValue, ref } from "firebase/database";
import { collection, addDoc, arrayUnion } from "firebase/firestore";
import { PatternFormat } from "react-number-format";
import { Slider, Typography, InputAdornment } from "@mui/material";
import { NumericFormat } from "react-number-format";
import { PulseLoader } from "react-spinners";

import {
  ResponsiveOtherInput,
  OtherInput,
  ResponsiveNewMap,
  ResponsiveMapQuestionBox,
  GeoCoderNewMap,
  ResponsiveInputButtonBlock,
  InputButtonBlock,
  MultipleInputs,
  LaundryButtonBlock,
  MattersButtonBlock,
  MattersLongRadioButton,
  ResponsiveButtonBlockDropDown,
  ResponsiveDualSliderBlock,
  Background,
  StepProgressBar,
  ResponsiveLongRadioButton,
  ResponsiveSelectRadioButton,
  ResponsiveSliderBlock,
  ResponsiveDropdown,
  SelectResponsiveDropdown,
  ResponsiveRadioButton,
  ResponsiveButtonBlock,
  SelectButtonBlock,
  SelectRadioButton,
  LongButtonBlock,
  SelectItem,
  MapQuestionBlock,
  BottomBar,
  FinalQuestionBlock,
  LongRadioButton,
  CheckBoxBlock,
  SliderBlock,
  BlockRow,
  AnimationQuestionBlock,
  ButtonRow,
  ButtonBlock,
  QuestionBlock,
  ProgressBar,
  ResultsLoader,
  Map,
  AnimationLoading,
  DropdownComponent,
  RadioButton,
  SelectDropdownComponent,
  Button,
  Input,
  Label,
  CheckBox,
  Expire,
  TheSun,
  Delay,
  AnimationIn,
  AnimationOut,
  NewMap,
} from "../../common/index";
//final svgs
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import PersonIcon from "@mui/icons-material/Person";

// contenxt
import MapContext from "../../../context/MapContext";

const Header = () => {
  const { activeStep, stepCount } = useWizard();
  const { buyData } = useContext(BuyContext);
  const {
    animationOut,
    animation,
    isVisible,
    animationIn,
    windowWidth,
    setWindowWidth,
    windowHeight,
    setWindowHeight,
    route,
    setRoute,
  } = useContext(AnimationContext);
  return (
    <div
      style={{
        fontSize: "16px",
        position: "fixed",
        display: "none",
      }}
    >
      ANIMATION: {JSON.stringify(animation)}
      <br />
      animationin: {JSON.stringify(animationIn)}
      <br />
      animationOut: {JSON.stringify(animationOut)}
      <br />
      Window Width: {JSON.stringify(windowWidth)}
      <br />
      Window Height: {JSON.stringify(windowHeight)}
      <br />
      Current Step: {activeStep + 1}
      <br />
      Type: {JSON.stringify(buyData)}
    </div>
  );
};

const Footer = ({ route }) => {
  const { activeStep, stepCount } = useWizard();
  const percent = (activeStep / stepCount) * 100;

  return (
    <BottomBar
      route="isBuy"
      percent={percent}
      activeStep={activeStep}
      stepCount={stepCount}
    />
  );
};

const LoadingComponent = () => {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "rgba(255, 255, 255, 0.8)",
      }}
    >
      <PulseLoader color="#0052FF90" size={34} />
    </div>
  );
};
const Wrapper = ({ children, route }) => {
  const {
    isLoading,
    isLastStep,
    isFirstStep,
    activeStep,
    stepCount,
    previousStep,
    nextStep,
    goToStep,
    handleStep,
  } = useWizard();
  const {
    animationOut,
    animation,
    isVisible,
    animationIn,
    windowWidth,
    setWindowWidth,
    windowHeight,
    setWindowHeight,
    setAnimationOut,
    setAnimationIn,
  } = useContext(AnimationContext);

  // Attach an optional handler
  handleStep(() => {
    setAnimationOut(true);
    setAnimationIn(false);
  });
  if (isFirstStep) {
    if (!animation) {
      return <div className="">{children}</div>;
    }
    if (animation) {
      return (
        <div className="animation__question__wrapper">
          <div className="theSun">
            <div className="ray_box">
              <div className="ray ray1"></div>
              <div className="ray ray2"></div>
              <div className="ray ray3"></div>
              <div className="ray ray4"></div>
              <div className="ray ray5"></div>
              <div className="ray ray6"></div>
              <div className="ray ray7"></div>
              <div className="ray ray8"></div>
              <div className="ray ray9"></div>
              <div className="ray ray10"></div>
            </div>
          </div>
          <div className="background_overlay"></div>
          <div>
            {animationIn ? (
              <AnimationIn delay="2400" />
            ) : (
              <AnimationQuestionBlock animation={true}>
                <div>{children}</div>
              </AnimationQuestionBlock>
            )}
          </div>
        </div>
      );
    }
  }
  if (isLastStep) {
    return <div>{children}</div>;
  }
  if (!animation) {
    return <div className="">{children}</div>;
  }
  if (animation) {
    return (
      <>
        <div className="animation__question__wrapper">
          <div className="theSun">
            <div className="ray_box">
              <div className="ray ray1"></div>
              <div className="ray ray2"></div>
              <div className="ray ray3"></div>
              <div className="ray ray4"></div>
              <div className="ray ray5"></div>
              <div className="ray ray6"></div>
              <div className="ray ray7"></div>
              <div className="ray ray8"></div>
              <div className="ray ray9"></div>
              <div className="ray ray10"></div>
            </div>
          </div>
          <div className="background_overlay"></div>
          {/* <div className="cloud-intro"></div> */}

          {/* <div className="x1">
            <div className="cloud"></div>
          </div>
          <div className="x2">
            <div className="cloud"></div>
          </div>
          <div className="x3">
            <div className="cloud"></div>
          </div> */}
          <div>
            {animationOut ? (
              <AnimationOut delay="3100" />
            ) : (
              <AnimationQuestionBlock animation={true}>
                <div>{children}</div>
              </AnimationQuestionBlock>
            )}
          </div>
        </div>
      </>
    );
  }
};

const Buy = () => {
  const [isImageLoaded, setImageLoaded] = useState({});
  const [background, setBackground] = useState(null);

  const { animation, setAnimation, animationOut, setAnimationOut } =
    useContext(AnimationContext);
  const [buyQuestion, setBuyQuestion] = useState();

  useEffect(() => {
    const query = ref(db, "buy_questions");
    return onValue(query, (snapshot) => {
      const data = snapshot.val();
      setBuyQuestion(data);

      const screenWidth = window.innerWidth;

      if (data) {
        Object.keys(data).forEach((step) => {
          if (data[step].background_url) {
            const originalUrl = data[step].background_url;
            const baseUrl =
              originalUrl.split("public%2Fbackgrounds%2F")[0] +
              "public%2Fbackgrounds%2Fpublic%2Fbackgrounds%2Fresized%2F";
            const imageName = originalUrl.split("%2F").pop().split(".")[0]; // Extract the image name without the file extension

            let imageSize;
            if (screenWidth <= 768) {
              // For mobile devices
              imageSize = "750x1334";
            } else if (screenWidth <= 1024) {
              // For tablets or small desktops
              imageSize = "1080x1920";
            } else {
              // For large desktops
              imageSize = "1920x1080";
            }

            const img = new Image();
            // Construct the URL for the resized image
            img.src = `${baseUrl}${imageName}_${imageSize}.webp?alt=media`;
            console.log("img.src", img.src);
            img.onload = () => {
              setImageLoaded((prevImages) => ({
                ...prevImages,
                [step]: true,
              }));
            };
          }
        });
      }
    });
  }, []);

  return (
    <Wizard
      startIndex={0}
      header={<Header />}
      footer={<Footer />}
      wrapper={<Wrapper />}
    >
      <Step1
        animation={animation}
        buyQuestion={buyQuestion}
        setAnimationOut={setAnimationOut}
        animationOut={animationOut}
        isImageLoaded={isImageLoaded}
        background={buyQuestion?.step_1?.background_url}
      />
      <Step2
        animation={animation}
        buyQuestion={buyQuestion}
        setAnimationOut={setAnimationOut}
        animationOut={animationOut}
        isImageLoaded={isImageLoaded}
        background={buyQuestion?.step_2?.background_url}
      />
      <Step3
        animation={animation}
        buyQuestion={buyQuestion}
        setAnimationOut={setAnimationOut}
        animationOut={animationOut}
        isImageLoaded={isImageLoaded}
        background={buyQuestion?.step_3?.background_url}
      />
      <Step4
        animation={animation}
        buyQuestion={buyQuestion}
        setAnimationOut={setAnimationOut}
        animationOut={animationOut}
        isImageLoaded={isImageLoaded}
        background={buyQuestion?.step_4?.background_url}
      />
      <Step5
        animation={animation}
        buyQuestion={buyQuestion}
        setAnimationOut={setAnimationOut}
        animationOut={animationOut}
        isImageLoaded={isImageLoaded}
        background={buyQuestion?.step_5?.background_url}
      />
      <Step6
        animation={animation}
        buyQuestion={buyQuestion}
        setAnimationOut={setAnimationOut}
        animationOut={animationOut}
        isImageLoaded={isImageLoaded}
        background={buyQuestion?.step_6?.background_url}
      />
      <Step7
        animation={animation}
        buyQuestion={buyQuestion}
        setAnimationOut={setAnimationOut}
        animationOut={animationOut}
        isImageLoaded={isImageLoaded}
        background={buyQuestion?.step_7?.background_url}
      />
      <Step8
        animation={animation}
        buyQuestion={buyQuestion}
        setAnimationOut={setAnimationOut}
        animationOut={animationOut}
        isImageLoaded={isImageLoaded}
        background={buyQuestion?.step_8?.background_url}
      />
      <Step9
        buyQuestion={buyQuestion}
        animation={animation}
        setAnimationOut={setAnimationOut}
        animationOut={animationOut}
        isImageLoaded={isImageLoaded}
        background={buyQuestion?.step_9?.background_url}
      />
      <Step10
        buyQuestion={buyQuestion}
        animation={animation}
        setAnimationOut={setAnimationOut}
        animationOut={animationOut}
        isImageLoaded={isImageLoaded}
        background={buyQuestion?.step_10?.background_url}
      />
      <Step11
        buyQuestion={buyQuestion}
        animation={animation}
        setAnimationOut={setAnimationOut}
        animationOut={animationOut}
        isImageLoaded={isImageLoaded}
        background={buyQuestion?.step_11?.background_url}
      />
    </Wizard>
  );
};

const Step1 = ({
  setAnimationOut,
  route,
  animationOut,
  animation,
  buyQuestion,
  isImageLoaded,
  background,
}) => {
  // checkbox
  const [checked, setChecked] = useState(true);
  const [loading, setLoading] = useState(true);
  const checkState = () => {
    setChecked(true);
  };
  // Multi Select
  const [selected, setSelected] = useState([]);
  const handleOnClick = (e) => {
    const item = e.target.value;
    var index = selected.indexOf(item);
    const newList = selected;
    const filtered = newList.filter((select) => select !== item);

    if (index == -1) {
      setSelected([...selected, item]);
    } else {
      setSelected([...filtered]);
    }
  };
  const { setType } = useContext(BuyContext);

  // set Animation
  const { toggleAnimation } = useContext(AnimationContext);

  const {
    isLoading,
    isLastStep,
    isFirstStep,
    activeStep,
    stepCount,
    previousStep,
    nextStep,
    goToStep,
    handleStep,
  } = useWizard();

  useEffect(() => {
    if (buyQuestion && isImageLoaded) {
      setLoading(false);
    }
  }, [buyQuestion, isImageLoaded]);

  if (loading) return <LoadingComponent />;
  // if (error) return "Error!";
  if (buyQuestion) {
    if (animation) {
      return (
        <>
          <Delay>
            <QuestionHeader style={{ transform: "translateY(-80px)" }}>
              {buyQuestion.step_1.title}
            </QuestionHeader>
            <ButtonBlock>
              {buyQuestion.step_1.options.map((x, i) => {
                return (
                  <React.Fragment key={x.id}>
                    <RadioButton
                      route={route}
                      id={x.id}
                      icon={null}
                      icon_text={x.icon_text}
                      onClick={(e) => handleOnClick(e)}
                      label={x.label}
                      value={x.label}
                      minWidth="54px"
                      minHeight="80px"
                      type="checked"
                    />
                  </React.Fragment>
                );
              })}
            </ButtonBlock>

            {selected.length === 0 ? (
              <Button disabled onClick={() => nextStep()} label="Next" />
            ) : (
              <Button
                onClick={() => {
                  nextStep();
                  // nextStep();
                  setType(selected);
                }}
                label="Next"
              />
            )}
          </Delay>
        </>
      );
    }
    // const background = buyQuestion.step_1.background_url;
    return (
      <>
        <QuestionBlock
          backgroundImage={background}
          headerText={buyQuestion.step_1.title}
        >
          <ResponsiveButtonBlock>
            {buyQuestion.step_1.options.map((x, i) => {
              return (
                <React.Fragment key={x.id}>
                  <ResponsiveRadioButton
                    route={route}
                    id={x.id}
                    icon={null}
                    icon_text={x.icon_text}
                    onClick={(e) => handleOnClick(e)}
                    label={x.label}
                    value={x.label}
                    type="checked"
                  />
                </React.Fragment>
              );
            })}
          </ResponsiveButtonBlock>
          {selected.length === 0 ? (
            <Button disabled onClick={() => nextStep()} label="Next" />
          ) : (
            <Button
              onClick={() => {
                nextStep();
                setType(selected);
              }}
              label="Next"
            />
          )}
        </QuestionBlock>
      </>
    );
  }
};

const Step2 = ({
  route,
  animation,
  buyQuestion,
  background,
  isImageLoaded,
}) => {
  const [loading, setLoading] = useState(true);

  const { handleStep, previousStep, nextStep } = useWizard();
  const [bedroomSelected, setBedroomSelected] = useState("User Skipped");
  const [bathRoomSelected, setBathRoomSelected] = useState("User Skipped");
  // Select Dropdown
  const handleChangeBedRoom = (newValue, actionMeta) => {
    setBedroomSelected(newValue.label);
  };

  const handleChangeBathRoom = (newValue, actionMeta) => {
    setBathRoomSelected(newValue.label);
  };
  const { setBedrooms } = useContext(BuyContext);
  const { setBathrooms } = useContext(BuyContext);

  // set Animation
  const { toggleAnimation } = useContext(AnimationContext);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    if (buyQuestion && isImageLoaded) {
      setLoading(false);
    }
  }, [buyQuestion, isImageLoaded]);

  if (loading) return <LoadingComponent />;

  if (buyQuestion) {
    if (animation) {
      const bedRoomData = buyQuestion.step_2.options[0];
      const bathRoomData = buyQuestion.step_2.options[1];
      return (
        <>
          {isVisible && (
            <>
              <Delay ß>
                <QuestionHeader style={{ transform: "translateY(-80px)" }}>
                  {buyQuestion.step_2.title}
                </QuestionHeader>

                <ButtonBlock>
                  <DropdownComponent
                    onChange={handleChangeBedRoom}
                    options={bedRoomData.bedrooms}
                    placeholder="Select # Of Bedrooms"
                    icon="https://firebasestorage.googleapis.com/v0/b/thehousehawk-b7691.appspot.com/o/public%2Ficons%2Fbedroom.png?alt=media&token=f0afd388-4182-4c10-86e4-209dfc7e3a50"
                  />
                  <DropdownComponent
                    onChange={handleChangeBathRoom}
                    options={bathRoomData.bathrooms}
                    placeholder="Select # Of Bathrooms"
                    icon="https://firebasestorage.googleapis.com/v0/b/thehousehawk-b7691.appspot.com/o/public%2Ficons%2Fbathroom.png?alt=media&token=0cc63611-b0dc-4087-afa7-6068879a2bf2"
                  />
                </ButtonBlock>
                <ButtonWrapper>
                  <Button
                    onClick={() => {
                      nextStep();

                      setBathrooms(bathRoomSelected);
                      setBedrooms(bedroomSelected);
                    }}
                    label="Next"
                  />
                </ButtonWrapper>
              </Delay>
            </>
          )}
        </>
      );
    }
    // const background = buyQuestion.step_2.background_url;
    const bedRoomData = buyQuestion.step_2.options[0];
    const bathRoomData = buyQuestion.step_2.options[1];
    return (
      <>
        <QuestionBlock
          backgroundImage={background}
          headerText={buyQuestion.step_2.title}
        >
          <ResponsiveButtonBlockDropDown>
            <ResponsiveDropdown
              route={route}
              onChange={handleChangeBedRoom}
              options={bedRoomData.bedrooms}
              placeholder="Select # of Bedrooms"
              icon="https://firebasestorage.googleapis.com/v0/b/thehousehawk-b7691.appspot.com/o/public%2Ficons%2Fbedroom.png?alt=media&token=f0afd388-4182-4c10-86e4-209dfc7e3a50"
            />
            <ResponsiveDropdown
              route={route}
              onChange={handleChangeBathRoom}
              options={bathRoomData.bathrooms}
              placeholder="Select # of Bathrooms"
              icon="https://firebasestorage.googleapis.com/v0/b/thehousehawk-b7691.appspot.com/o/public%2Ficons%2Fbathroom.png?alt=media&token=0cc63611-b0dc-4087-afa7-6068879a2bf2"
            />
            <ButtonWrapper>
              <Button
                onClick={() => {
                  nextStep();
                  setBathrooms(bathRoomSelected);
                  setBedrooms(bedroomSelected);
                }}
                label="Next"
              />
            </ButtonWrapper>
          </ResponsiveButtonBlockDropDown>
        </QuestionBlock>
      </>
    );
  }
};

const Step3 = ({
  route,
  animation,
  buyQuestion,
  background,
  isImageLoaded,
}) => {
  const { handleStep, previousStep, nextStep } = useWizard();
  const [loading, setLoading] = useState(true);

  const marks = {
    0: "$0",
    1000000: "$1M",
  };
  const { setBudget, budget, setMonthly, monthly } = useContext(BuyContext);
  const [totalValue, setTotalValue] = React.useState(0);

  const handleSliderChange = (event, newValue) => {
    setBudget(newValue);
  };

  const handleInputChange = (event) => {
    let value =
      event.target.value === ""
        ? ""
        : Number(event.target.value.replace(/[$,]+/g, ""));
    if (isNaN(value)) {
      value = 0;
    }
    if (value < 0) {
      value = 0;
    }
    if (value > 1000000) {
      value = 1000000;
    }

    setBudget(value);
  };

  const handleMonthlySliderChange = (event, newValue) => {
    setMonthly(newValue);
  };

  const handleMonthlyInputChange = (event) => {
    let value =
      event.target.value === ""
        ? ""
        : Number(event.target.value.replace(/[$,]+/g, ""));
    if (isNaN(value)) {
      value = 0;
    }
    if (value < 0) {
      value = 0;
    }
    if (value > 10000) {
      value = 10000;
    }

    setMonthly(value);
  };
  // set Animation
  const { toggleAnimation } = useContext(AnimationContext);
  const [animationOut, setAnimationOut] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  const NumberFormatCustom = (props) => {
    const { inputRef, onChange, ...other } = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              value: values.value,
            },
          });
        }}
        thousandSeparator
        isNumericString
        prefix="$"
      />
    );
  };

  useEffect(() => {
    if (buyQuestion && isImageLoaded) {
      setLoading(false);
    }
  }, [buyQuestion, isImageLoaded]);

  if (loading) return <LoadingComponent />;

  if (buyQuestion) {
    if (animation) {
      const first = buyQuestion.step_3.options[0].sliders[0];
      const second = buyQuestion.step_3.options[0].sliders[1];

      return (
        <>
          {isVisible && (
            <>
              <QuestionHeader style={{ transform: "translateY(-60px)" }}>
                {buyQuestion.step_3.title}
              </QuestionHeader>
              <SliderWrapper>
                <StyledSliderStep3>
                  Total
                  <StyledNumber
                    value={budget === 0 ? "$0" : `$${budget.toLocaleString()}`}
                    margin="dense"
                    onChange={handleInputChange}
                    endAdornment={
                      <InputAdornment position="end">$</InputAdornment>
                    }
                    startAdornment={
                      <InputAdornment position="start">Total</InputAdornment>
                    }
                  />
                </StyledSliderStep3>
                <Slider
                  value={budget}
                  onChange={handleSliderChange}
                  aria-labelledby="total-amount-slider"
                  min={0}
                  max={1000000}
                  step={5000}
                />
              </SliderWrapper>
              OR
              <SliderWrapper>
                <StyledSliderStep3>
                  Monthly
                  <StyledNumber
                    value={
                      monthly === 0 ? "$0" : `$${monthly.toLocaleString()}`
                    }
                    margin="dense"
                    onChange={handleMonthlyInputChange}
                    endAdornment={
                      <InputAdornment position="end">$</InputAdornment>
                    }
                    startAdornment={
                      <InputAdornment position="start">Total</InputAdornment>
                    }
                  />
                </StyledSliderStep3>
                <Slider
                  value={monthly}
                  onChange={handleMonthlySliderChange}
                  aria-labelledby="total-amount-slider"
                  min={0}
                  max={10000}
                  step={100}
                />
              </SliderWrapper>
              <p>You can type or slide your answer</p>
              <ButtonWrapper>
                {!budget & !monthly ? (
                  <Button disabled onClick={() => nextStep()} label="Next" />
                ) : (
                  <Button
                    onClick={() => {
                      nextStep();
                      setBudget(budget);
                      setMonthly(monthly);
                    }}
                    label="Next"
                  />
                )}
              </ButtonWrapper>
            </>
          )}
        </>
      );
    }
    // const background = buyQuestion.step_3.background_url;
    const first = buyQuestion.step_3.options[0].sliders[0];
    const second = buyQuestion.step_3.options[0].sliders[1];
    return (
      <>
        <QuestionBlock
          backgroundImage={background}
          headerText={buyQuestion.step_3.title}
        >
          <SliderWrapper>
            <StyledSliderStep3>
              Total
              <StyledNumber
                value={budget === 0 ? "$0" : `$${budget.toLocaleString()}`}
                margin="dense"
                onChange={handleInputChange}
                endAdornment={<InputAdornment position="end">$</InputAdornment>}
                startAdornment={
                  <InputAdornment position="start">Total</InputAdornment>
                }
              />
            </StyledSliderStep3>
            <Slider
              value={budget}
              onChange={handleSliderChange}
              aria-labelledby="total-amount-slider"
              min={0}
              max={1000000}
              step={5000}
            />
          </SliderWrapper>
          OR
          <SliderWrapper>
            <StyledSliderStep3>
              Monthly
              <StyledNumber
                value={monthly === 0 ? "$0" : `$${monthly.toLocaleString()}`}
                margin="dense"
                onChange={handleMonthlyInputChange}
                endAdornment={<InputAdornment position="end">$</InputAdornment>}
                startAdornment={
                  <InputAdornment position="start">Total</InputAdornment>
                }
              />
            </StyledSliderStep3>
            <Slider
              value={monthly}
              onChange={handleMonthlySliderChange}
              aria-labelledby="total-amount-slider"
              min={0}
              max={10000}
              step={100}
            />
          </SliderWrapper>
          <p>You can type or slide your answer</p>
          <ButtonWrapper>
            {!budget & !monthly ? (
              <Button disabled onClick={() => nextStep()} label="Next" />
            ) : (
              <Button
                onClick={() => {
                  nextStep();
                  setBudget(budget);
                  setMonthly(monthly);
                }}
                label="Next"
              />
            )}
          </ButtonWrapper>
        </QuestionBlock>
      </>
    );
  }
};

const Step4 = ({
  route,
  animation,
  buyQuestion,
  background,
  isImageLoaded,
}) => {
  const { nextStep } = useWizard();
  // checkbox
  const [checked, setChecked] = useState(true);
  const [loading, setLoading] = useState(true);

  const checkState = () => {
    setChecked(true);
  };
  // Multi Select
  const [selected, setSelected] = useState([]);
  const handleOnClick = (e) => {
    const item = e.target.value;
    var index = selected.indexOf(item);
    const newList = selected;
    const filtered = newList.filter((select) => select !== item);

    if (index === -1) {
      setSelected([...selected, item]);
    } else {
      setSelected([...filtered]);
    }
  };
  // Other Input
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const handleOther = (e) => {
    setInputVisible(!inputVisible);
  };

  const handleInputValue = (e) => {
    var index = selected.indexOf(inputValue);
    const newList = selected;
    const filtered = newList.filter((select) => select !== inputValue);
    if (index == -1) {
      setSelected([...selected, inputValue]);
    } else {
      setSelected([...filtered]);
    }
  };
  const { setFeatures } = useContext(BuyContext);
  // set Animation
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    if (buyQuestion && isImageLoaded) {
      setLoading(false);
    }
  }, [buyQuestion, isImageLoaded]);

  if (loading) return <LoadingComponent />;

  if (buyQuestion) {
    if (animation) {
      return (
        <>
          {isVisible && (
            <Delay delay="0">
              <QuestionHeader style={{ transform: "translateY(-70px)" }}>
                {buyQuestion.step_4.title}
              </QuestionHeader>

              <SelectButtonBlock>
                {buyQuestion.step_4.options.map((x, i) => {
                  return (
                    <React.Fragment key={x.id}>
                      <SelectRadioButton
                        id={x.id}
                        icon={x.icon}
                        icon_text={x.icon_text}
                        onClick={(e) => handleOnClick(e)}
                        label={x.label}
                        value={x.label}
                        route={route}
                      />
                    </React.Fragment>
                  );
                })}

                <OtherInput
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  onBlur={handleInputValue}
                  placeholder="Other – Type Here"
                  type="text"
                />
              </SelectButtonBlock>

              <Button
                onClick={() => {
                  nextStep();

                  setFeatures(selected);
                }}
                label="Next"
              />
            </Delay>
          )}
        </>
      );
    }
    // const background = buyQuestion.step_4.background_url;
    return (
      <>
        <QuestionBlock
          backgroundImage={background}
          headerText={buyQuestion.step_4.title}
        >
          <ResponsiveButtonBlock>
            {buyQuestion.step_4.options.map((x, i) => {
              return (
                <React.Fragment key={x.id}>
                  <ResponsiveSelectRadioButton
                    id={x.id}
                    icon={x.icon}
                    icon_text={x.icon_text}
                    onClick={(e) => handleOnClick(e)}
                    label={x.label}
                    value={x.label}
                    route={route}
                  />
                </React.Fragment>
              );
            })}
            <div>
              <ResponsiveOtherInput
                icon="https://househawkbucket.s3.amazonaws.com/other_956418866f.png"
                onChange={(e) => setInputValue(e.target.value)}
                onBlur={handleInputValue}
                placeholder="Other - Type Here"
                type="text"
                value={inputValue}
              />
            </div>
          </ResponsiveButtonBlock>

          {selected.length === 0 ? (
            <Button disabled onClick={() => nextStep()} label="Next" />
          ) : (
            <Button
              onClick={() => {
                nextStep();
                setFeatures(selected);
              }}
              label="Next"
            />
          )}
        </QuestionBlock>
      </>
    );
  }
};

const Step5 = ({
  route,
  animation,
  buyQuestion,
  background,
  isImageLoaded,
}) => {
  const [loading, setLoading] = useState(true);

  const { handleStep, previousStep, nextStep } = useWizard();
  // toggle Map
  const [showModal, setShowModal] = useState(false);
  const [map, setMap] = useState(false);
  // set Animation
  const { toggleAnimation } = useContext(AnimationContext);
  const [animationOut, setAnimationOut] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    if (buyQuestion && isImageLoaded) {
      setLoading(false);
    }
  }, [buyQuestion, isImageLoaded]);

  if (loading) return <LoadingComponent />;

  if (buyQuestion) {
    if (animation) {
      return (
        <>
          {isVisible && (
            <Delay delay="0">
              <NewMap
                animationOut={animationOut}
                setAnimationOut={setAnimationOut}
                nextStep={nextStep}
                setIsVisible={setIsVisible}
                isVisible={isVisible}
                headerText={buyQuestion.step_5.title}
                route={route}
              />
            </Delay>
          )}
        </>
      );
    }
    // const background = buyQuestion.step_5.background_url;
    return (
      <>
        <ResponsiveMapQuestionBox
          backgroundImage={background}
          route={route}
          animation={false}
        >
          <ResponsiveNewMap
            setIsVisible={setIsVisible}
            isVisible={isVisible}
            nextStep={nextStep}
            headerText={buyQuestion.step_5.title}
            route={route}
          />
        </ResponsiveMapQuestionBox>
      </>
    );
  }
};
const Step6 = ({
  route,
  animation,
  buyQuestion,
  background,
  isImageLoaded,
}) => {
  const { handleStep, previousStep, nextStep } = useWizard();
  const [loading, setLoading] = useState(true);

  // checkbox
  const [checked, setChecked] = useState(true);
  const checkState = () => {
    setChecked(true);
  };
  // Multi Select
  const [selected, setSelected] = useState([]);
  const handleOnClick = (e) => {
    const item = e.target.value;
    var index = selected.indexOf(item);
    const newList = selected;
    const filtered = newList.filter((select) => select !== item);

    if (index == -1) {
      setSelected([...selected, item]);
    } else {
      setSelected([...filtered]);
    }
  };
  const { setFloors } = useContext(BuyContext);
  // set Animation
  const { toggleAnimation } = useContext(AnimationContext);
  const [animationOut, setAnimationOut] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    if (buyQuestion && isImageLoaded) {
      setLoading(false);
    }
  }, [buyQuestion, isImageLoaded]);

  if (loading) return <LoadingComponent />;

  if (buyQuestion) {
    if (animation) {
      return (
        <>
          {isVisible && (
            <Delay delay="0">
              <QuestionHeader style={{ transform: "translateY(-80px)" }}>
                {buyQuestion.step_6.title}
              </QuestionHeader>

              <ButtonBlock width="100%">
                {buyQuestion.step_6.options.map((x, i) => {
                  return (
                    <React.Fragment key={x.id}>
                      <RadioButton
                        id={x.id}
                        route={route}
                        icon={null}
                        icon_text={x.icon}
                        onClick={(e) => handleOnClick(e)}
                        label={x.label}
                        value={x.label}
                        minWidth="54px"
                        minHeight="80px"
                        type="check"
                      />
                    </React.Fragment>
                  );
                })}
              </ButtonBlock>

              <Button
                onClick={() => {
                  nextStep();
                  setFloors(selected);
                }}
                label="Next"
              />
            </Delay>
          )}
        </>
      );
    }
    // const background = buyQuestion.step_6.background_url;
    return (
      <>
        <QuestionBlock
          backgroundImage={background}
          headerText={buyQuestion.step_6.title}
        >
          <ResponsiveButtonBlock>
            {buyQuestion.step_6.options.map((x, i) => {
              return (
                <React.Fragment key={x.id}>
                  <ResponsiveRadioButton
                    id={x.id}
                    icon={null}
                    icon_text={x.icon}
                    onClick={(e) => handleOnClick(e)}
                    label={x.label}
                    value={x.label}
                    minWidth="54px"
                    minHeight="80px"
                    type="check"
                  />
                </React.Fragment>
              );
            })}
          </ResponsiveButtonBlock>

          {selected.length === 0 ? (
            <Button disabled onClick={() => nextStep()} label="Next" />
          ) : (
            <Button
              onClick={() => {
                nextStep();
                setFloors(selected);
              }}
              label="Next"
            />
          )}
        </QuestionBlock>
      </>
    );
  }
};
const Step7 = ({
  route,
  animation,
  buyQuestion,
  background,
  isImageLoaded,
}) => {
  const { handleStep, previousStep, nextStep } = useWizard();
  const [value, setValue] = React.useState([1900, 2023]);
  const [loading, setLoading] = useState(true);

  const handleMinYearChange = (event) => {
    setMinYear(event.target.value === "" ? "" : Number(event.target.value));
  };

  const handleMaxYearChange = (event) => {
    setMaxYear(event.target.value === "" ? "" : Number(event.target.value));
  };

  const handleSliderChange = (event, newValue) => {
    setMinYear(newValue[0]);
    setMaxYear(newValue[1]);
  };
  const [minYearSelected, setMinYearSelected] = useState();
  const [maxYearSelected, setMaxYearSelected] = useState();

  const { setMinYear, minYear } = useContext(BuyContext);
  const { setMaxYear, maxYear } = useContext(BuyContext);
  const handleAmountMaxInput = (event) => {
    setMaxYear(event.target.value);
  };
  const handleAmountMinInput = (event) => {
    setMinYear(event.target.value);
  };

  const handleChange = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setValue([Math.min(newValue[0], value[1] - minDistance), value[1]]);
      setMaxYear(value[1]);
      setMinYear(value[0]);
    } else {
      setValue([value[0], Math.max(newValue[1], value[0] + minDistance)]);
      setMaxYear(value[1]);
      setMinYear(value[0]);
    }
  };

  const marks = [
    {
      value: 1900,
      label: "1900",
    },
    {
      value: 2023,
      label: "2023",
    },
  ];
  const minDistance = 1;

  // set Animation
  const { toggleAnimation } = useContext(AnimationContext);
  const [animationOut, setAnimationOut] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    if (buyQuestion && isImageLoaded) {
      setLoading(false);
    }
  }, [buyQuestion, isImageLoaded]);

  if (loading) return <LoadingComponent />;

  if (buyQuestion) {
    if (animation) {
      const min = buyQuestion.step_7.options[0].sliders[0];
      const max = buyQuestion.step_7.options[0].sliders[1];

      return (
        <>
          {isVisible && (
            <Delay delay="0">
              <QuestionHeader style={{ transform: "translateY(-80px)" }}>
                {buyQuestion.step_7.title}
              </QuestionHeader>

              <ButtonBlock>
                <SliderWrapper>
                  <StlyedSliderInput>
                    Min Year
                    <StyledNumber
                      value={minYear}
                      margin="dense"
                      onChange={handleMinYearChange}
                      inputProps={{
                        step: 1,
                        min: 1900,
                        max: 2023,
                        type: "number",
                        "aria-labelledby": "range-slider",
                      }}
                    />
                  </StlyedSliderInput>
                  <StlyedSliderInput>
                    Max Year
                    <StyledNumber
                      value={maxYear}
                      margin="dense"
                      onChange={handleMaxYearChange}
                      inputProps={{
                        step: 1,
                        min: 1900,
                        max: 2023,
                        type: "number",
                        "aria-labelledby": "range-slider",
                      }}
                    />
                  </StlyedSliderInput>
                  <StyledSlider
                    value={[minYear, maxYear]}
                    onChange={handleSliderChange}
                    aria-labelledby="range-slider"
                    min={1900}
                    max={2023}
                    step={1}
                    marks={marks}
                    disableSwap
                  />
                </SliderWrapper>
              </ButtonBlock>
              <p>You can type or slide your answer</p>

              {!maxYear ? (
                <Button disabled onClick={() => nextStep()} label="Next" />
              ) : (
                <Button
                  onClick={() => {
                    nextStep();
                  }}
                  label="Next"
                />
              )}

              <div
                className="skip-question"
                onClick={() => {
                  nextStep();
                }}
              >
                Skip this Question
              </div>
            </Delay>
          )}
        </>
      );
    }
    // const background = buyQuestion.step_7.background_url;
    const min = buyQuestion.step_7.options[0].sliders[0];
    const max = buyQuestion.step_7.options[0].sliders[1];
    return (
      <>
        <QuestionBlock
          backgroundImage={background}
          headerText={buyQuestion.step_7.title}
        >
          <ResponsiveButtonBlock>
            <SliderWrapper>
              <StlyedSliderInput>
                Min Year
                <StyledNumber
                  value={minYear}
                  margin="dense"
                  onChange={handleMinYearChange}
                  inputProps={{
                    step: 1,
                    min: 1900,
                    max: 2023,
                    type: "number",
                    "aria-labelledby": "range-slider",
                  }}
                />
              </StlyedSliderInput>
              <StlyedSliderInput>
                Max Year
                <StyledNumber
                  value={maxYear}
                  margin="dense"
                  onChange={handleMaxYearChange}
                  inputProps={{
                    step: 1,
                    min: 1900,
                    max: 2023,
                    type: "number",
                    "aria-labelledby": "range-slider",
                  }}
                />
              </StlyedSliderInput>
              <StyledSlider
                value={[minYear, maxYear]}
                onChange={handleSliderChange}
                aria-labelledby="range-slider"
                min={1900}
                max={2023}
                step={1}
                marks={marks}
                disableSwap
              />
            </SliderWrapper>
          </ResponsiveButtonBlock>
          <p>You can type or slide your answer</p>
          {!maxYear ? (
            <Button disabled onClick={() => nextStep()} label="Next" />
          ) : (
            <Button
              onClick={() => {
                nextStep();
              }}
              label="Next"
            />
          )}
          <div
            className="skip-question"
            onClick={() => {
              nextStep();
            }}
          >
            Skip this Question
          </div>
        </QuestionBlock>
      </>
    );
  }
};

const Step8 = ({
  route,
  animation,
  buyQuestion,
  background,
  isImageLoaded,
}) => {
  const { handleStep, previousStep, nextStep } = useWizard();
  const [loading, setLoading] = useState(true);

  // checkbox
  const [checked, setChecked] = useState(true);
  const checkState = () => {
    setChecked(true);
  };
  // Multi Select
  const [selected, setSelected] = useState([]);
  const handleOnClick = (e) => {
    const item = e.target.value;
    var index = selected.indexOf(item);
    const newList = selected;
    const filtered = newList.filter((select) => select !== item);

    if (index == -1) {
      setSelected([...selected, item]);
    } else {
      setSelected([...filtered]);
    }
  };
  // Other Input
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const handleOther = (e) => {
    setInputVisible(!inputVisible);
  };

  const handleInputValue = (e) => {
    var index = selected.indexOf(inputValue);
    const newList = selected;
    const filtered = newList.filter((select) => select !== inputValue);

    if (index == -1) {
      setSelected([...selected, inputValue]);
    } else {
      setSelected([...filtered]);
    }
  };

  //// OTHER input
  const { setAmenities } = useContext(BuyContext);

  // set Animation
  const { toggleAnimation } = useContext(AnimationContext);
  const [animationOut, setAnimationOut] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    if (buyQuestion && isImageLoaded) {
      setLoading(false);
    }
  }, [buyQuestion, isImageLoaded]);

  if (loading) return <LoadingComponent />;

  if (buyQuestion) {
    if (animation) {
      return (
        <>
          {isVisible && (
            <Delay delay="0">
              <QuestionHeader style={{ transform: "translateY(-60px)" }}>
                {buyQuestion.step_8.title}
              </QuestionHeader>

              <SelectButtonBlock>
                {buyQuestion.step_8.options.map((x, i) => {
                  return (
                    <React.Fragment key={x.id}>
                      <SelectRadioButton
                        id={x.id}
                        icon={x.icon}
                        icon_text={x.icon_text}
                        onClick={(e) => handleOnClick(e)}
                        label={x.label}
                        value={x.label}
                      />
                    </React.Fragment>
                  );
                })}
                <OtherInput
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value)}
                  onBlur={handleInputValue}
                  placeholder="Other - Type Here"
                  type="text"
                />
              </SelectButtonBlock>

              <Button
                onClick={() => {
                  nextStep();
                  setAmenities(selected);
                }}
                label="Next"
              />
            </Delay>
          )}
        </>
      );
    }
    // const background = buyQuestion.step_8.background_url;
    return (
      <>
        <QuestionBlock
          backgroundImage={background}
          headerText={buyQuestion.step_8.title}
        >
          <ResponsiveButtonBlock>
            {buyQuestion.step_8.options.map((x, i) => {
              return (
                <React.Fragment key={x.id}>
                  <ResponsiveSelectRadioButton
                    id={x.id}
                    icon={x.icon}
                    icon_text={x.icon_text}
                    onClick={(e) => handleOnClick(e)}
                    label={x.label}
                    value={x.label}
                    route={route}
                    width="190px"
                    height="50px"
                  />
                </React.Fragment>
              );
            })}
            <div>
              <ResponsiveOtherInput
                icon="https://househawkbucket.s3.amazonaws.com/other_956418866f.png"
                onChange={(e) => setInputValue(e.target.value)}
                onBlur={handleInputValue}
                placeholder="Other - Type Here"
                type="text"
                value={inputValue}
              />
            </div>
          </ResponsiveButtonBlock>

          {selected.length === 0 ? (
            <Button disabled onClick={() => nextStep()} label="Next" />
          ) : (
            <Button
              onClick={() => {
                nextStep();
                setAmenities(selected);
              }}
              label="Next"
            />
          )}
        </QuestionBlock>
      </>
    );
  }
};

const Step9 = ({
  route,
  animation,
  buyQuestion,
  background,
  isImageLoaded,
}) => {
  const { handleStep, previousStep, nextStep } = useWizard();
  const [loading, setLoading] = useState(true);

  // checkbox
  const [checked, setChecked] = useState(true);
  const checkState = () => {
    setChecked(true);
  };
  // Multi Select
  const [selected, setSelected] = useState([]);
  const handleOnClick = (e) => {
    setSelected(e.target.value);
  };
  const { setMatters } = useContext(BuyContext);

  // set Animation
  const { toggleAnimation } = useContext(AnimationContext);
  const [animationOut, setAnimationOut] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    if (buyQuestion && isImageLoaded) {
      setLoading(false);
    }
  }, [buyQuestion, isImageLoaded]);

  if (loading) return <LoadingComponent />;

  if (buyQuestion) {
    if (animation) {
      return (
        <>
          {isVisible && (
            <Delay delay="0">
              <MattersButtonBlock>
                <QuestionHeader style={{ transform: "translateY(-60px)" }}>
                  {buyQuestion.step_9.title}
                </QuestionHeader>

                {buyQuestion.step_9.options.map((x, i) => {
                  return (
                    <React.Fragment key={x.id}>
                      <MattersLongRadioButton
                        id={x.id}
                        icon={x.icon}
                        icon_text={x.icon}
                        onClick={(e) => handleOnClick(e)}
                        label={x.label}
                        value={x.label}
                        route={route}
                        type="radio"
                        name="select"
                      />
                    </React.Fragment>
                  );
                })}
              </MattersButtonBlock>

              <Content> Don't worry, this won't remove any matches</Content>

              {selected.length === 0 ? (
                <Button disabled onClick={() => nextStep()} label="Next" />
              ) : (
                <Button
                  onClick={() => {
                    nextStep();
                    setMatters(selected);
                  }}
                  label="Next"
                />
              )}
            </Delay>
          )}
        </>
      );
    }
    // const background = buyQuestion.step_9.background_url;
    return (
      <>
        <QuestionBlock
          backgroundImage={background}
          headerText={buyQuestion.step_9.title}
        >
          <ResponsiveButtonBlock>
            {buyQuestion.step_9.options.map((x, i) => {
              return (
                <React.Fragment key={x.id}>
                  <ResponsiveLongRadioButton
                    id={x.id}
                    icon={x.icon}
                    icon_text={x.icon}
                    onClick={(e) => handleOnClick(e)}
                    label={x.label}
                    value={x.label}
                    route={route}
                    type="radio"
                    name="select"
                  />
                </React.Fragment>
              );
            })}

            <Content> Don't worry, this won't remove any matches</Content>
          </ResponsiveButtonBlock>

          {selected.length === 0 ? (
            <Button disabled onClick={() => nextStep()} label="Next" />
          ) : (
            <Button
              onClick={() => {
                nextStep();
                setMatters(selected);
              }}
              label="Next"
            />
          )}
        </QuestionBlock>
      </>
    );
  }
};
const Step10 = ({
  route,
  animation,
  buyQuestion,
  background,
  isImageLoaded,
}) => {
  const { handleStep, previousStep, nextStep } = useWizard();
  const [loading, setLoading] = useState(true);

  // checkbox
  const [checked, setChecked] = useState(true);
  const checkState = () => {
    setChecked(true);
  };
  // Multi Select
  const [selected, setSelected] = useState([]);
  const handleOnClick = (e) => {
    setSelected(e.target.value);
  };
  const { setPriority } = useContext(BuyContext);
  // set Animation
  const { toggleAnimation } = useContext(AnimationContext);
  const [animationOut, setAnimationOut] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    if (buyQuestion && isImageLoaded) {
      setLoading(false);
    }
  }, [buyQuestion, isImageLoaded]);

  if (loading) return <LoadingComponent />;

  if (buyQuestion) {
    if (animation) {
      return (
        <>
          {isVisible && (
            <Delay delay="0">
              <QuestionHeader style={{ transform: "translateY(-40px)" }}>
                {buyQuestion.step_10.title}
              </QuestionHeader>

              <LongButtonBlock>
                {buyQuestion.step_10.options.map((x, i) => {
                  return (
                    <React.Fragment key={x.id}>
                      <ResponsiveLongRadioButton
                        id={x.id}
                        icon={null}
                        icon_text={x.icon}
                        onClick={(e) => handleOnClick(e)}
                        label={x.label}
                        value={x.label}
                        route={route}
                        name="select"
                        type="radio"
                      />
                    </React.Fragment>
                  );
                })}
              </LongButtonBlock>

              {selected.length === 0 ? (
                <Button disabled onClick={() => nextStep()} label="Next" />
              ) : (
                <Button
                  onClick={() => {
                    nextStep();
                    setPriority(selected);
                  }}
                  label="Next"
                />
              )}
            </Delay>
          )}
        </>
      );
    }
    // const background = buyQuestion.step_10.background_url;
    return (
      <>
        <QuestionBlock
          backgroundImage={background}
          headerText={buyQuestion.step_10.title}
        >
          <ResponsiveButtonBlock>
            {buyQuestion.step_10.options.map((x, i) => {
              return (
                <React.Fragment key={x.id}>
                  <LongRadioButton
                    id={x.id}
                    icon={null}
                    icon_text={x.icon}
                    onClick={(e) => handleOnClick(e)}
                    label={x.label}
                    value={x.label}
                    route={route}
                    name="select"
                    type="radio"
                  />
                </React.Fragment>
              );
            })}
          </ResponsiveButtonBlock>

          {selected.length === 0 ? (
            <Button disabled onClick={() => nextStep()} label="Next" />
          ) : (
            <Button
              onClick={() => {
                nextStep();
                setPriority(selected);
              }}
              label="Next"
            />
          )}
        </QuestionBlock>
      </>
    );
  }
};
const Step11 = ({ route, animation, buyQuestion, isImageLoaded }) => {
  const { handleStep, previousStep, nextStep } = useWizard();
  const [loading, setLoading] = useState(true);

  // set Animation
  const { toggleAnimation } = useContext(AnimationContext);
  const [animationOut, setAnimationOut] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  // Rental
  const {
    type,
    bedrooms,
    bathrooms,
    budget,
    monthly,
    features,
    floors,
    minYear,
    maxYear,
    amenities,
    matters,
    priority,
  } = useContext(BuyContext);
  let history = useHistory();

  const { address, boundaries } = useContext(MapContext);
  const { loader } = useContext(AnimationContext);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [formattedPhone, setFormattedPhone] = useState("");
  const [phone, setPhone] = useState("");

  const data = {
    name: name,
    email: email,
    phone: phone,
    type: type,
    bedrooms: bedrooms,
    bathrooms: bathrooms,
    budget: budget,
    monthly: monthly,
    location: address,
    amenities: amenities,
    floors: floors,
    features: features,
    matters: matters,
    priority: priority,
    minAge: minYear,
    maxAge: maxYear,
    boundaries: boundaries,
  };

  const isButtonDisabled = () => {
    return !(
      name &&
      (email || (formattedPhone && isValidPhoneNumber(formattedPhone)))
    );
  };

  const isValidPhoneNumber = (phone) => {
    // A full phone number typically has 10 digits in it
    return phone.length >= 10;
  };

  function removeNonNumeric(phoneNumber) {
    // Using regular expression to match any non-numeric characters and replace them with an empty string
    return phoneNumber.replace(/\D/g, "");
  }

  const handlePhoneChange = (event) => {
    const stringPhone = removeNonNumeric(event.target.value);
    setFormattedPhone(stringPhone);
  };
  const addBuy = async (event) => {
    event.preventDefault();

    try {
      const docRef = await addDoc(collection(firestore, "buy_inquiries"), {
        name: name,
        email: email,
        created_at: Date.now(),
        boundaries: JSON.stringify(boundaries),
        phone_number: formattedPhone,
        property_type: type,
        bedrooms: bedrooms,
        bathrooms: bathrooms,
        budget: budget,
        looking_to_pay: {
          amount: monthly,
          frequency: "Per Month",
        },
        location: address,
        amenities: amenities,
        floor_preferences: floors,
        features: features,
        matters: matters,
        priority: priority,
        year_built: {
          min: minYear,
          max: maxYear,
        },
        status: "Submitted",
      });
      console.log("Document written with ID: ", docRef.id);
      history.push("/success");
    } catch (err) {
      console.error("Error adding document: ", err.message);
    }
  };

  // const background =
  //   "https://househawkbucket.s3.amazonaws.com/final_step_77fca47fa6.png";

  const background =
    "https://firebasestorage.googleapis.com/v0/b/thehousehawk-b7691.appspot.com/o/public%2Fbackgrounds%2Fpublic%2Fbackgrounds%2Fresized%2Ffinal_bg_1920x1080.webp?alt=media&token=d3dcd4aa-fcd8-4bb5-a333-9dfdefea3d07";

  if (loader) {
    return <ResultsLoader delay="4800" />;
  }
  if (!loader) {
    return (
      <>
        <FinalQuestionBlock backgroundImage={background}>
          <p>
            Enter your information to see your results and get real-time updates
            delivered right to your inbox.
          </p>
          <form onSubmit={addBuy} className="final-form-buy">
            <Label htmlFor="name">
              <span className="image_space">
                <PersonIcon />
              </span>
              <Input
                placeholder="Name"
                type="name"
                id="name"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Label>
            <Label htmlFor="Email">
              <span className="image_space">
                <EmailIcon />
              </span>
              <Input
                placeholder="Email"
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Label>
            <p
              style={{
                textAlign: "center",
                marginBottom: 10,
              }}
            >
              And/Or
            </p>
            <Label htmlFor="phone">
              <span className="image_space">
                <LocalPhoneIcon />
              </span>
              <PatternFormat
                placeholder="Phone"
                type="phone"
                id="phone"
                name="phone"
                value={phone}
                onChange={handlePhoneChange}
                format="(###)-###-####"
                mask="_"
              />
            </Label>
            <Button
              disabled={isButtonDisabled()}
              type="submit"
              label="See My Matches"
              btnClass="submit-button"
            />
            <FooterText>
              You agree to The House Hawk LLC's Terms of Use & Privacy Policy
              and to be contacted by us or third parties. By registering, you
              give us your express written consent to deliver automated emails
              to you. Consent is not a condition of purchase. Your registration
              acts as your binding electronic signature.
            </FooterText>
          </form>
        </FinalQuestionBlock>
      </>
    );
  }
};

export default Buy;
const ButtonWrapper = styled.div`
  display: block;
  margin: 0 auto;
`;
const QuestionHeader = styled.div`
  text-align: center;
  font-size: 1.8rem;
  margin: 0 auto;
  width: 420px;
  font-weight: bold;
  text-shadow: 2px 2px #000;
`;
const Seperator = styled.div`
  width: 100%;
  padding-top: 4px;
  padding-bottom: 4px;
`;
const Content = styled.div`
  font-size: 16px;
  margin: 0px auto;
  margin-top: 16px;
  margin-bottom: 16px;
`;
const SliderWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

const StyledNumber = styled(Input)`
  width: 60%;
  margin: 0px auto;
  text-align: center;
  letter-spacing: 5.6px;
  border-radius: 8px;
  border: 2px solid black;
  height: 18px;
  background: #fdcae1;
  padding: 5px;
  @media () &::placeholder {
    color: #f9398f;
  }
`;
const StlyedSliderInput = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 240px;
  margin: 10px;
  background: #fdcae1;
  color: #f9398f;
  letter-spacing: 2px;
  border-radius: 25px;
  border: 2px solid #adcae6;
  margin-bottom: 10px;
`;

const StyledSliderStep3 = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 80%;
  margin: 10px;
  background: #fdcae1;
  color: #f9398f;
  letter-spacing: 2px;
  border-radius: 25px;
  border: 2px solid #adcae6;
`;

const StyledSlider = styled(Slider)`
  touch-action: pan-x !important;
  max-width: 80%;
  padding-left: 10px;
  .MuiSlider-thumb {
    height: 30px;
    width: 30px;
    pointer-events: none !important;
  }
`;

const FooterText = styled.p`
  font-size: 14px;
`;
