import React, { useContext } from "react";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import AnimationContext from "../../../context/AnimationContext";
import { useHistory } from "react-router-dom";
import CircleIcon from "@mui/icons-material/Circle";
const StepProgressBar = ({
  activeStep,
  stepCount,
  accomplished,
  index,
  percent,
  color,
}) => {
  const { route } = useContext(AnimationContext);
  let history = useHistory();

  if (route === "isBuy") {
    return (
      <ProgressBar
        height="8px"
        filledBackground="linear-gradient(to right, #a3dfff, #0052FF90)"
        percent={percent}
      >
        <Step transition="scale">
          {({ accomplished, index }) => (
            <div
              className={`transitionStep ${
                accomplished ? "accomplished" : null
              }`}
            >
              <img
                style={{ filter: `grayscale(${accomplished ? 0 : 10}%)` }}
                width="20"
                src="/assets/logo.png"
                alt="logo.png"
              />{" "}
            </div>
          )}
        </Step>
        <Step transition="scale">
          {({ accomplished, index }) => (
            <div
              className={`transitionStep ${
                accomplished ? "accomplished" : null
              }`}
            >
              <CircleIcon />
            </div>
          )}
        </Step>
        <Step transition="scale">
          {({ accomplished, index }) => (
            <div
              className={`transitionStep ${
                accomplished ? "accomplished" : null
              }`}
            >
              <CircleIcon />
            </div>
          )}
        </Step>

        <Step transition="scale">
          {({ accomplished, index }) => (
            <div
              className={`transitionStep ${
                accomplished ? "accomplished" : null
              }`}
            >
              <CircleIcon
                style={{ filter: `grayscale(${accomplished ? 0 : 10}%)` }}
              />
            </div>
          )}
        </Step>

        <Step transition="scale">
          {({ accomplished, index }) => (
            <div
              className={`transitionStep ${
                accomplished ? "accomplished" : null
              }`}
            >
              <CircleIcon
                style={{ filter: `grayscale(${accomplished ? 0 : 10}%)` }}
              />
            </div>
          )}
        </Step>
        <Step transition="scale">
          {({ accomplished, index }) => (
            <div
              className={`transitionStep ${
                accomplished ? "accomplished" : null
              }`}
            >
              <img
                style={{ filter: `grayscale(${accomplished ? 0 : 10}%)` }}
                width="20"
                src="/assets/logo.png"
                alt="hawk svg"
              />
            </div>
          )}
        </Step>
      </ProgressBar>
    );
  }
  if (route === "isInvest") {
    return (
      <ProgressBar
        height="8px"
        filledBackground="linear-gradient(to right, #70ff85, #00b050)"
        percent={percent}
      >
        <Step transition="scale">
          {({ accomplished, index }) => (
            <div
              className={`transitionStep ${
                accomplished ? "accomplished" : null
              }`}
            >
              <img
                style={{ filter: `grayscale(${accomplished ? 0 : 10}%)` }}
                width="20"
                src="/assets/logo.png"
                alt="hawk svg"
              />{" "}
            </div>
          )}
        </Step>
        <Step transition="scale">
          {({ accomplished, index }) => (
            <div
              className={`transitionStep ${
                accomplished ? "accomplished" : null
              }`}
            >
              <CircleIcon />
            </div>
          )}
        </Step>
        <Step transition="scale">
          {({ accomplished, index }) => (
            <div
              className={`transitionStep ${
                accomplished ? "accomplished" : null
              }`}
            >
              <CircleIcon />
            </div>
          )}
        </Step>
        <Step transition="scale">
          {({ accomplished, index }) => (
            <div
              className={`transitionStep ${
                accomplished ? "accomplished" : null
              }`}
            >
              <CircleIcon />
            </div>
          )}
        </Step>
        <Step transition="scale">
          {({ accomplished, index }) => (
            <div
              className={`transitionStep ${
                accomplished ? "accomplished" : null
              }`}
            >
              <img
                style={{ filter: `grayscale(${accomplished ? 0 : 10}%)` }}
                width="20"
                src="/assets/logo.png"
                alt="hawk svg"
              />{" "}
            </div>
          )}
        </Step>
      </ProgressBar>
    );
  }
  if (route === "isRent") {
    return (
      <ProgressBar
        height="8px"
        filledBackground="linear-gradient(to right, #fefb72, #f0bb31)"
        percent={percent}
      >
        <Step transition="scale">
          {({ accomplished, index }) => (
            <div
              className={`transitionStep ${
                accomplished ? "accomplished" : null
              }`}
            >
              <img
                style={{ filter: `grayscale(${accomplished ? 0 : 10}%)` }}
                width="20"
                src="/assets/logo.png"
                alt="hawk svg"
              />{" "}
            </div>
          )}
        </Step>
        <Step transition="scale">
          {({ accomplished, index }) => (
            <div
              className={`transitionStep ${
                accomplished ? "accomplished" : null
              }`}
            >
              <CircleIcon />
            </div>
          )}
        </Step>
        <Step transition="scale">
          {({ accomplished, index }) => (
            <div
              className={`transitionStep ${
                accomplished ? "accomplished" : null
              }`}
            >
              <CircleIcon />
            </div>
          )}
        </Step>
        <Step transition="scale">
          {({ accomplished, index }) => (
            <div
              className={`transitionStep ${
                accomplished ? "accomplished" : null
              }`}
            >
              <CircleIcon />
            </div>
          )}
        </Step>
        <Step transition="scale">
          {({ accomplished, index }) => (
            <div
              className={`transitionStep ${
                accomplished ? "accomplished" : null
              }`}
            >
              <img
                style={{ filter: `grayscale(${accomplished ? 0 : 10}%)` }}
                width="20"
                src="/assets/logo.png"
                alt="hawk svg"
              />{" "}
            </div>
          )}
        </Step>
      </ProgressBar>
    );
  }
  if (route === "isSell") {
    return (
      <ProgressBar
        height="8px"
        filledBackground="linear-gradient(to right, #ff635e, #FF0800)"
        percent={percent}
      >
        <Step transition="scale">
          {({ accomplished, index }) => (
            <div
              className={`transitionStep ${
                accomplished ? "accomplished" : null
              }`}
            >
              <img
                style={{ filter: `grayscale(${accomplished ? 0 : 10}%)` }}
                width="20"
                src="/assets/logo.png"
                alt="hawk svg"
              />{" "}
            </div>
          )}
        </Step>
        <Step transition="scale">
          {({ accomplished, index }) => (
            <div
              className={`transitionStep ${
                accomplished ? "accomplished" : null
              }`}
            >
              <CircleIcon />
            </div>
          )}
        </Step>
        <Step transition="scale">
          {({ accomplished, index }) => (
            <div
              className={`transitionStep ${
                accomplished ? "accomplished" : null
              }`}
            >
              <CircleIcon />
            </div>
          )}
        </Step>
        <Step transition="scale">
          {({ accomplished, index }) => (
            <div
              className={`transitionStep ${
                accomplished ? "accomplished" : null
              }`}
            >
              <CircleIcon />
            </div>
          )}
        </Step>
        <Step transition="scale">
          {({ accomplished, index }) => (
            <div
              className={`transitionStep ${
                accomplished ? "accomplished" : null
              }`}
            >
              <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                <circle cx="50" cy="50" r="50" />
              </svg>
            </div>
          )}
        </Step>
      </ProgressBar>
    );
  }
  if (!route || route === "") {
    history.push("/");
    return null;
  }
};
export default StepProgressBar;
