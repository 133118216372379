import React, { createContext, useState, useEffect } from "react";

const AnimationContext = createContext();

export const AnimationProvider = (props) => {
  const [loader, setLoader] = useState(true);
  const [isVisible, setIsVisible] = useState(false);
  const [route, setRoute] = useState("");
  const [animationOut, setAnimationOut] = useState(false);
  const [animation, setAnimation] = useState(true);
  const [animationIn, setAnimationIn] = useState(true);
  const [delayIn, setDelayIn] = useState("2400");
  const [delayOut, setDelayOut] = useState("3200");

  const toggleAnimation = () => {
    setAnimation(!animation);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
      if (window.innerWidth >= 1281) {
        setAnimation(true);
      }
      if (window.innerWidth <= 1280) {
        setAnimation(false);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const maxSize = () => {
    setWindowWidth(1280);
  };
  return (
    <AnimationContext.Provider
      value={{
        maxSize,
        animationIn,
        setAnimationIn,
        loader,
        setLoader,
        animationOut,
        setAnimationOut,
        animation,
        setAnimation,
        toggleAnimation,
        route,
        setRoute,
        isVisible,
        setIsVisible,
        windowWidth,
        setWindowWidth,
        windowHeight,
        setWindowHeight,
        delayIn,
        setDelayIn,
        delayOut,
        setDelayOut,
      }}
    >
      {props.children}
    </AnimationContext.Provider>
  );
};

export default AnimationContext;
