import React from "react";
import {
  Button,
  Input,
  ResultsLoader,
  Label,
  QuestionBlock,
  ButtonBlock,
  ButtonRow,
  AnimationQuestionBlock,
  FinalQuestionBlock
} from "../common";
const SuccessPage = () => {
  const background =
    "https://househawkbucket.s3.amazonaws.com/final_step_77fca47fa6.png";
  return (
    <FinalQuestionBlock backgroundImage={background}>
      <h1 style={{ margin: "0 auto" }}>THANK YOU!!</h1>
      <h3 style={{ margin: "0 auto 2.4rem auto" }}>
        Your matches are on the way!
      </h3>
      <h3>
        An email is being custom curated just for you and will contain the best
        properties that match your criteria.
      </h3>
    </FinalQuestionBlock>
  );
};

export default SuccessPage;
