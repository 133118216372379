import React, { useState, useEffect, useContext } from "react";
import styled, { css, keyframes } from "styled-components";
import { Map, TileLayer, Marker, Popup, FeatureGroup } from "react-leaflet";
import Button from "./Button";
import ClickMap from "./ClickMap";
import MapContext from "../../../context/MapContext";
import { EditControl } from "react-leaflet-draw";
import { useRef } from "react";
import { geosearch } from "esri-leaflet-geocoder";
import { GeoCoderNewMap, ResponsiveButtonBlock } from ".";
import { device } from "../../../utils/mediaQueries";

import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import "esri-leaflet-geocoder/dist/esri-leaflet-geocoder.css";
import "leaflet-fullscreen/dist/Leaflet.fullscreen.js";
import "leaflet-fullscreen/dist/leaflet.fullscreen.css";
import AnimationContext from "../../../context/AnimationContext";

const ResponsiveNewMap = ({ nextStep, headerText }) => {
  const { route } = useContext(AnimationContext);
  console.log(route);

  const [isMapVisible, setisMapVisible] = useState(false);
  const [animationOut, setAnimationOut] = useState(false);
  const [mapLayers, setMapLayers] = useState([]);
  const ZOOM_LEVEL = 12;
  const mapRef = useRef();
  const { autoLocation, setAddress, boundaries, setBoundaries, latlng } =
    useContext(MapContext);
  const [auto, setAuto] = useState([]);

  const checkAutoLocation = () => {
    const getAutoLocation = JSON.parse(localStorage.getItem("autoLocation"));
    console.log(getAutoLocation);
    if (getAutoLocation) {
      setAuto(getAutoLocation);
      console.log("runAuto, setAuto", auto);
      setCenter(getAutoLocation);
      console.log("checkAutoLocation, if SETauto", center);
    } else {
      setCenter(latlng);
    }
  };

  useEffect(() => {
    checkAutoLocation();
  }, []);

  const [center, setCenter] = useState();
  useEffect(() => {
    const { current = {} } = mapRef;
    const { leafletElement: map } = current;
    if (!map) return;
    const control = geosearch();

    control.addTo(map);

    control.on("results", handleOnSearchResuts);
    console.log("MATP", map);

    return () => {
      control.off("results", handleOnSearchResuts);
    };
  }, [mapRef]);

  /**
   * handleOnSearchResuts
   * @param {object} data Results object from esri-leaflet-geocoder
   */

  function handleOnSearchResuts(data) {
    setAddress(data.text);
  }

  const createLayer = (e) => {
    const { layerType, layer } = e;
    if (layerType === "polygon") {
      const { _leaflet_id } = layer;

      setMapLayers((layers) => [
        ...layers,
        { id: _leaflet_id, latlngs: layer.getLatLngs()[0] },
      ]);
    }
  };

  const editLayer = (e) => {
    const {
      layers: { _layers },
    } = e;

    Object.values(_layers).map(({ _leaflet_id, editing }) => {
      setMapLayers((layers) =>
        layers.map((l) =>
          l.id === _leaflet_id
            ? { ...l, latlngs: { ...editing.latlngs[0] } }
            : l
        )
      );
    });
  };

  const deleteLayer = (e) => {
    const {
      layers: { _layers },
    } = e;

    Object.values(_layers).map(({ _leaflet_id }) => {
      setMapLayers((layers) => layers.filter((l) => l.id !== _leaflet_id));
    });
  };

  return (
    <>
      <Hidden route={route} isMapVisible={isMapVisible}>
        <Map
          style={{
            position: "fixed",
            width: "100vw",
            height: "100vh",
            top: 0,
            left: 0,
          }}
          center={center}
          zoom={ZOOM_LEVEL}
          ref={mapRef}
          fullscreenControl={true}
        >
          <FeatureGroup>
            <EditControl
              position="topright"
              onCreated={createLayer}
              onEdited={editLayer}
              onDeleted={deleteLayer}
              draw={{
                rectangle: false,
                polyline: false,
                circle: false,
                circlemarker: false,
                marker: false,
              }}
            />
          </FeatureGroup>
          {mapLayers.length === 0 ? (
            <MapButton
              route={route}
              onClick={() => {
                setisMapVisible(!isMapVisible);
              }}
            >
              {" "}
              Exit{" "}
            </MapButton>
          ) : (
            <MapButton
              isReady
              onClick={() => {
                setisMapVisible(!isMapVisible);
                setBoundaries(mapLayers, 0, 2);
              }}
            >
              Save & Close{" "}
            </MapButton>
          )}

          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />
        </Map>
      </Hidden>
      <StyledBox>
        <Content>
          {headerText}

          <ClickMap
            onClick={() => setisMapVisible(!isMapVisible)}
            label={
              mapLayers.length === 0
                ? "Click Here to Set Location Boundaries"
                : "Location Set! Click Next"
            }
          />
          <ButtonWrapper>
            {mapLayers.length === 0 ? (
              <Button btnClass="map_button" disabled label="Next" />
            ) : (
              <Button
                btnClass="map_button"
                onClick={() => {
                  setAnimationOut(!animationOut);
                  nextStep();
                  setBoundaries(mapLayers, 0, 2);
                }}
                label="Next"
              />
            )}
            <div className="skip-question" onClick={() => nextStep()}>
              Skip this Question
            </div>
            {/* Current Location:
          <GeoCoderNewMap newBoundaries={boundaries} /> */}
          </ButtonWrapper>
        </Content>
      </StyledBox>
    </>
  );
};

export default ResponsiveNewMap;
const MapButton = styled.button`
  position: absolute;
  padding: 6px;
  border: 1px solid #fff;
  border-radius: 3px;
  white-space: nowrap;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
  z-index: 9000;
  margin-top: 135px;
  right: 0;
  margin-right: 10px;
  width: 100px;
  height: 40px;
  ${(props) =>
    props.route === "isBuy" &&
    css`
      color: white;
      background: #0052ff !important;
    `}
  ${(props) =>
    props.route === "isRent" &&
    css`
      color: black;
      background: #ffe817;
    `}
    ${(props) =>
    props.route === "isSell" &&
    css`
      color: white;

      background: #ff0800;
    `}
      ${(props) =>
    props.route === "isInvest" &&
    css`
      color: white;
      background: #2bb43f;
    `}
  ${(props) =>
    props.isReady &&
    css`
      background-color: #ffe817;
    `}
`;

const StyledBox = styled.div`
  @media ${device.phone} {
    width: 320px;
    height: 500px;
    flex-wrap: wrap;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0 auto;
    border: 1px solid black;
    background-color: white;
  }
  @media ${device.xs} {
    width: 576px;
  }
`;
const Content = styled.div`
  padding: 0px;
  background-color: light-grey;
  display: flex;
  flex-direction: column;
  width: 420px;
  justify-content: center;
  border: 0px;
  align-items: center;
  text-align: center;
  margin: 0 auto;
  font-size: 1.6rem;
`;
const Hidden = styled.div`
  z-index: 1000;
  background-color: blue;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  visibility: ${(props) => (props.isMapVisible ? "visible" : "hidden")};
  .leaflet-draw-draw-polygon {
    width: 100px !important;
    background-image: none !important;\
    ${(props) =>
      props.route === "isBuy" &&
      css`
        color: white;
        background: #0052ff !important;
      `}
    ${(props) =>
      props.route === "isRent" &&
      css`
        color: black;
        background: #ffe817;
      `}
      ${(props) =>
        props.route === "isSell" &&
        css`
          color: white;

          background: #ff0800;
        `}
        ${(props) =>
          props.route === "isInvest" &&
          css`
            color: white;
            background: #2bb43f;
          `}
  }
  .leaflet-draw-draw-polygon::before {
    content: "Start Outline";
    width: 100px;
    background-image: none !important;
    font-size: 14px;

   
  }
  .leaflet-draw-edit-edit {
    width: 100px !important;
    background-image: none !important;
    font-size: 14px;
    ${(props) =>
      props.route === "isBuy" &&
      css`
        color: white;
        background: #0052ff !important;
      `}
    ${(props) =>
      props.route === "isRent" &&
      css`
        color: black;
        background: #ffe817;
      `}
      ${(props) =>
        props.route === "isSell" &&
        css`
          color: white;

          background: #ff0800;
        `}
        ${(props) =>
          props.route === "isInvest" &&
          css`
            color: white;
            background: #2bb43f;
          `}
  }
  .leaflet-draw-edit-edit::before {
    content: "Edit Outline";
    width: 100px;
    font-size: 14px;
    ${(props) =>
      props.route === "isBuy" &&
      css`
        color: white;
        background: #0052ff !important;
      `}
    ${(props) =>
      props.route === "isRent" &&
      css`
        color: black;
        background: #ffe817;
      `}
      ${(props) =>
        props.route === "isSell" &&
        css`
          color: white;

          background: #ff0800;
        `}
        ${(props) =>
          props.route === "isInvest" &&
          css`
            color: white;
            background: #2bb43f;
          `}
  }
  .leaflet-draw-edit-remove {
    width: 100px !important;
    background-image: none !important;
    font-size: 14px;
    ${(props) =>
      props.route === "isBuy" &&
      css`
        color: white;
        background: #0052ff !important;
      `}
    ${(props) =>
      props.route === "isRent" &&
      css`
        color: black;
        background: #ffe817;
      `}
      ${(props) =>
        props.route === "isSell" &&
        css`
          color: white;

          background: #ff0800;
        `}
        ${(props) =>
          props.route === "isInvest" &&
          css`
            color: white;
            background: #2bb43f;
          `}
  }
  .leaflet-draw-edit-remove::before {
    content: "Reset";
    width: 100px;
    background-image: none !important;
    font-size: 14px;
    ${(props) =>
      props.route === "isBuy" &&
      css`
        color: white;
        background: #0052ff !important;
      `}
    ${(props) =>
      props.route === "isRent" &&
      css`
        color: black;
        background: #ffe817;
      `}
      ${(props) =>
        props.route === "isSell" &&
        css`
          color: white;

          background: #ff0800;
        `}
        ${(props) =>
          props.route === "isInvest" &&
          css`
            color: white;
            background: #2bb43f;
          `}
  }

  .leaflet-touch .leaflet-draw-actions {
    margin-right: 100%;
  }
  .leaflet-touch .leaflet-draw-actions a {
    font-size: 14px;
    line-height: 30px;
    height: 30px;
    background-color: #ffe817;
    color: black;
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
