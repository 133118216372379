import React from "react";
import styled, { css } from "styled-components";
import { device } from "../../../utils/mediaQueries";

const LaundryButtonBlock = ({ ml, width, children }) => {
  return (
    <StyledLaundryButtonBlock width={width} ml={ml}>
      {children}
    </StyledLaundryButtonBlock>
  );
};

export default LaundryButtonBlock;

const StyledLaundryButtonBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 500px;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
`;
