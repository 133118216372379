import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { device } from "../../../utils/mediaQueries";

const QuestionBlock = ({
  backgroundImage,
  headerText,
  route,
  animation,
  children,
  isKeyboardOpen,
}) => {
  const [isImgLoaded, setIsImgLoaded] = useState(false);

  useEffect(() => {
    if (backgroundImage) {
      const img = new Image();
      img.src = backgroundImage;
      img.onload = () => {
        setIsImgLoaded(true);
      };
    }
  }, [backgroundImage]);
  return (
    <QuestionBlockWrapper
      backgroundImage={backgroundImage}
      route={route}
      isImgLoaded={isImgLoaded}
    >
      <StyledQuestionBlock isKeyboardOpen={isKeyboardOpen}>
        <Content>
          <QuestionHeader>
            <p>{headerText} </p>
          </QuestionHeader>
          {children}
        </Content>
      </StyledQuestionBlock>
    </QuestionBlockWrapper>
  );
};

export default QuestionBlock;

const QuestionBlockWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-image: url("${(props) => props.backgroundImage}");
`;

const StyledQuestionBlock = styled.div`
  @media ${device.phone} {
    width: 320px;
    height: 500px;
    flex-wrap: wrap;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    position: fixed;
    top: ${(props) => {
      return props.isKeyboardOpen ? "30%" : "50%";
    }};
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0 auto;
    border: 1px solid black;
    background-color: white;
  }
  @media ${device.xs} {
    width: 576px;
  }
`;

const Content = styled.div`
  @media ${device.phone} {
    max-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 0 auto;
    justify-content: center;
    font-size: 1.6rem;
    flex-wrap: wrap;
  }
  @media ${device.xs} {
    max-width: 576px;
    height: 500px;
  }
`;

const QuestionHeader = styled.div`
  @media ${device.phone} {
    text-align: center;
    font-size: 1.6rem;
    padding: 10px;
  }
`;
