import React, { useEffect, useState, useContext } from "react";
import styled, { css, keyframes } from "styled-components";
import { Label } from ".";
import { device } from "../../../utils/mediaQueries";
import AnimationContext from "../../../context/AnimationContext";

const ResponsiveRadioButton = ({
  onClick,
  id,
  icon,
  icon_text,
  label,
  value,
  inpClass,
  lblClass,
  iconClass,
  outerClass,
  span,
  animationIcon,
  part,
  popOut,
  isOther,
  height,
  width
}) => {
  const { route } = useContext(AnimationContext);

  console.log("RADIO", route);
  return (
    <StyledRadioButton>
      <StyledInput
        onClick={onClick}
        type="checkbox"
        id={id}
        value={value}
        name="check"
        route={route}
      />
      <StyledRadioLabel
        height={height}
        width={width}
        route={route}
        htmlFor={id}
      >
        <StyledIcon>
          {icon === null ? (
            <StyledSpan route={route} className={span}>
              {icon_text}
            </StyledSpan>
          ) : (
            <StyledSpan route={route}>
              <img alt="icon" src={icon.url} />
            </StyledSpan>
          )}
          {isOther && (
            <StyledSpan route={route} className={part}>
              <img
                alt="icon"
                src="https://househawkbucket.s3.amazonaws.com/other_956418866f.png"
              />
            </StyledSpan>
          )}
          <p> {label}</p>
        </StyledIcon>
      </StyledRadioLabel>
    </StyledRadioButton>
  );
};

export default ResponsiveRadioButton;

const StyledRadioButton = styled.div`
  @media ${device.phone} {
    width: 130px;
    margin: 10px;
  }
`;
const bounce = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }

`;
const StyledRadioLabel = styled.label`
  justify-content: center;
  text-align: center;
  display: flex;
  align-items: center;
  background: #ffffff;
  color: black;
  border-radius: 5px;
  padding: 7px 10px;
  border: 2px solid #ffffff;
  margin-bottom: 0;
  cursor: pointer;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-in-out;
  font-size: 12px;
  font-weight: 400;
`;

const StyledInput = styled.input`
  display: none;
  position: absolute;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  &:checked + ${StyledRadioLabel} {
    -webkit-animation: ${bounce} 0.5s alternate;
    -moz-animation: ${bounce} 0.5s alternate;
    animation: ${bounce} 0.5s alternate;
    background: #ffffff;
    ${props =>
      props.route === "isBuy" &&
      css`
        border-color: #0052ff;
        background-color: #5bbaff;
        color: white;
      `}
    ${props =>
      props.route === "isRent" &&
      css`
        border-color: #ffe817;
      `}
      ${props =>
        props.route === "isInvest" &&
        css`
          border-color: #00b050;
          background-color: #55d668;
          color: white;
        `}
      ${props =>
        props.route === "isSell" &&
        css`
          border-color: #ff0800;
          background-color: #ff9c99;
          color: white;
        `}
    -webkit-animation: ${bounce} 0.5s alternate;
    -moz-animation: ${bounce} 0.5s alternate;
    animation: ${bounce} 0.5s alternate;
  }
`;
const StyledIcon = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    margin-top: 10px;
  }
`;
const StyledSpan = styled.span`
  width: 28px;
  height: 28px;
  font-size: 16px;
  text-align: center;
  line-height: 24px;
  font-weight: bold;
  border-radius: 50%;
  img {
    height: 24px;
    width: 24px;
    border-radius: 50%;
    text-align: center;
  }
  ${props =>
    props.route === "isBuy" &&
    css`
      color: white;
      background: #0052ff;
    `}
  ${props =>
    props.route === "isRent" &&
    css`
      background: #ffe817;
    `}
    ${props =>
      props.route === "isInvest" &&
      css`
        color: white;
        background: #00b050;
      `}
    ${props =>
      props.route === "isSell" &&
      css`
        color: white;
        background: #ff0800;
      `}
`;
