import React from "react";

const Input = ({
  onBlur,
  onFocus,
  min,
  max,
  step,
  id,
  type,
  placeholder,
  value,
  onChange,
  className,
  style,
  InputLabelLeft,
}) => {
  return (
    <>
      {InputLabelLeft}
      <input
        style={style}
        onBlur={onBlur}
        onFocus={onFocus}
        min={min}
        max={max}
        id={id}
        type={type}
        step={step}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className={className}
      />
    </>
  );
};

export default Input;
