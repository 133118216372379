import React, { useEffect, useState } from "react";
import styled, { css, keyframes } from "styled-components";
import { Label } from ".";
import { device } from "../../../utils/mediaQueries";
const CheckBox = ({
  route,
  onClick,
  id,
  icon,
  icon_text,
  label,
  value,
  inpClass,
  lblClass,
  iconClass,
  outerClass,
  span,
  animationIcon,
  part,
  popOut,
  isOther,
  children,
  height,
  width,
  ml,
  fontColor,
  bgColor,
}) => {
  return (
    <StyledCheckboxButton>
      <StyledCheckboxLabel route={route} htmlFor={id}>
        {icon === null ? (
          <StyledSpan route={route} className={span}>
            {icon_text}
          </StyledSpan>
        ) : (
          <StyledSpan route={route}>
            <img alt="icon" src={icon.url} />
          </StyledSpan>
        )}
        {isOther && (
          <StyledSpan route={route} className={part}>
            <img
              alt="icon"
              src="https://househawkbucket.s3.amazonaws.com/other_956418866f.png"
            />
          </StyledSpan>
        )}
        {label}
      </StyledCheckboxLabel>
      <StyledInput
        onClick={onClick}
        type="checkbox"
        id={id}
        value={value}
        name="check"
        route={route}
      />
    </StyledCheckboxButton>
  );
};

export default CheckBox;
const StyledCheckboxButton = styled.div`
  margin-bottom: 5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 42px;
  align-items: center;
  width: 48%;
  margin-left: 5px;
`;

const StyledCheckboxLabel = styled.label`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  font-size: 14px;
`;

const StyledInput = styled.input.attrs({ type: "checkbox" })`
  background-color: #444;
`;
const StyledSpan = styled.span`
  font-size: 16px;
  text-align: center;
  line-height: 30px;
  font-weight: bold;
  display: flex;

  img {
    height: 24px;
    padding: 6px;

    width: 24px;
    border-radius: 50%;
    background: #999;
    margin-right: 0.8rem;
  }
`;
