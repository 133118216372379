import React from "react";
import styled from "styled-components";
import Input from "./Input";
import { device } from "../../../utils/mediaQueries";
import { NumericFormat } from "react-number-format";

const ResponsiveSliderBlock = ({
  value,
  min,
  max,
  budget,
  onChange,
  minLabel,
  maxLabel,
  sliderId,
  ml,
  width,
  inputLabelLeft,
  inputLabelRight,
  step,
  placeholder,
  type,
}) => {
  const numberFormat = (value) =>
    new Intl.NumberFormat("en-US", {
      currency: "USD",
      currencyDisplay: "narrowSymbol",
      minimumFractionDigits: 0,
    }).format(value);

  console.log("value", value);
  return (
    <SliderWrapper width={width} ml={ml}>
      <StlyedSliderInput>
        {inputLabelLeft}
        <NumericFormat
          value={value}
          allowLeadingZeros
          thousandSeparator=","
          prefix={"$"}
          placeholder={placeholder}
          onChange={onChange}
          className="input__field"
        />
        {/* <Input
          min={min}
          max={max}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          className="input__field"
        /> */}
        {inputLabelRight}
      </StlyedSliderInput>
      <SliderRange>
        <ul>
          <li>${numberFormat(minLabel)}</li>
          <li>${numberFormat(maxLabel)}</li>
        </ul>
        <Input
          type={type}
          step={step}
          value={value}
          onChange={onChange}
          type="range"
          id={sliderId}
          min={min}
          max={max}
          className="slider"
        />
      </SliderRange>
    </SliderWrapper>
  );
};

export default ResponsiveSliderBlock;
const SliderWrapper = styled.div`
  @media ${device.phone} {
    width: 100%;
    margin: 5px auto;
  }
  @media ${device.xs} {
    width: 100%;
  }
`;
const StlyedSliderInput = styled.div`
  width: 98%;
  background: #fdcae1;
  color: #f9398f;
  display: inline-block;
  position: relative;
  letter-spacing: 2px;
  border-radius: 25px;
  border: 2px solid #adcae6;
  margin-bottom: 20px;
  @media ${device.phone} {
    font-size: 1.2rem;
  }
  input {
    width: 10rem;
    text-align: center;
    letter-spacing: 5.6px;
    border-radius: 8px;
    border: 2px solid black;
    margin: 0 0.8rem;
    height: calc(1.6em + 0.75rem + 2px);
    background: #fdcae1;
    @media () &::placeholder {
      color: #f9398f;
    }
    p {
    }
  }
`;
const SliderRange = styled.div`
  ul {
    display: flex;
  }

  li:last-child {
    margin-left: auto;
  }

  .slider {
    width: 98%;

    height: 15px;
    border-radius: 5px;
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    margin-bottom: 1rem;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
  }
`;
