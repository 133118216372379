import React from "react";
import styled from "styled-components";
import LocationSearchingIcon from "@mui/icons-material/LocationSearching";
const ClickMap = ({
  children,
  isSelected,
  value,
  disabled,
  onClick,
  label,
  span,
  btnClass,
  icon,
  onMouseEnter,
  onMouseLeave,
}) => {
  return (
    <StyledClickMap
      value={value}
      isselected={isSelected}
      disabled={disabled}
      onClick={onClick}
      className={btnClass}
      icon={icon}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <span className={span}>{label}</span>
      <LocationSearchingIcon
        style={{ marginLeft: "10px", verticalAlign: "middle" }}
      />
      {children}
    </StyledClickMap>
  );
};

export default ClickMap;

const StyledClickMap = styled.button`
  width: 300px;
  height: 40px;
  color: #212529 !important;
  display: inline-block;
  font-weight: 400;
  background-color: #f8f9fa !important;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1 px solid transparent;
  padding: 0.375 rem 0.75 rem;
  font-size: 14px;
  line-height: 1.6;
  border-radius: 0.25 rem;
  margin-top: 30px;
  &:hover {
    background-color: #f8f9fa !important;
  }
  img {
    vertical-align: middle;
    width: 20px;
    height: 20px;
    margin-left: 10px;
  }
`;
