import React from "react";
import styled from "styled-components";
const BlockRow = ({ children, mt, mb, ml, mr }) => {
  return (
    <StyledBlockRow mt={mt} mb={mb} ml={ml} mr={mr}>
      {children}
    </StyledBlockRow>
  );
};
export default BlockRow;

const StyledBlockRow = styled.div`
  margin-top: ${(props) => props.mt}px;
  margin-bottom: ${(props) => props.mb}px;
  display: flex;
`;
