import React from "react";
import styled, { css } from "styled-components";
import { device } from "../../../utils/mediaQueries";

const ResponsiveButtonBlockDropDown = ({ ml, width, children }) => {
  return (
    <StyledButtonBlock width={width} ml={ml}>
      {children}
    </StyledButtonBlock>
  );
};

export default ResponsiveButtonBlockDropDown;

const StyledButtonBlock = styled.div`
  @media ${device.phone} {
    max-width: 300px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: flex-start;
    flex-direction: row;
  }
  @media ${device.xs} {
    max-width: 100%;
    flex-direction: row;
    justify-content: center;
  }
`;
