import React from "react";
import styled, { css } from "styled-components";
import { device } from "../../../utils/mediaQueries";

const SelectItem = ({ ml, width, children }) => {
  return (
    <StyledSelectItem width={width} ml={ml}>
      {children}
    </StyledSelectItem>
  );
};

export default SelectItem;

const StyledSelectItem = styled.div`
  width: 400px;

  display: flex;
  flex-direction: row;
  align-items: center;
  border: 2px solid #0052ff;
  border-radius: 0.8rem;
  margin: 4rem 0.8rem;

  input {
    width: 12rem !important;
    color: $black !important;
  }

  @include media("<=tablet") {
    width: 16rem;
    margin: 0.8rem 0;
  }
`;
