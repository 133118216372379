import React, { useContext } from "react";
import styled, { css } from "styled-components";
import AnimationContext from "../../../context/AnimationContext";

const Button = ({
  children,
  isSelected,
  value,
  disabled,
  onClick,
  label,
  span,
  btnClass,
  icon,
  onMouseEnter,
  onMouseLeave,
  variant
}) => {
  const { route } = useContext(AnimationContext);

  return (
    <StyledButton
      route={route}
      variant={variant}
      value={value}
      isselected={isSelected}
      disabled={disabled}
      onClick={onClick}
      className={btnClass}
      icon={icon}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <span className={span}>{label}</span>
      <img src={icon} />
      {children}
    </StyledButton>
  );
};

export default Button;

const StyledButton = styled.button`
  width: 22.5rem;
  ${props =>
    props.route === "isBuy" &&
    css`
      color: white;
      background: #0052ff;
    `}
  ${props =>
    props.route === "isRent" &&
    css`
      color: black;
      background: #ffe817;
    `}
    ${props =>
      props.route === "isSell" &&
      css`
        color: white;

        background: #ff0800;
      `}
      ${props =>
        props.route === "isInvest" &&
        css`
          color: white;
          background: #2bb43f;
        `}
  margin: 10px auto 0px auto;
  border-radius: 0.3rem;
  min-width: 8rem;
  padding: 0.8rem 0;
  height: 3.2rem;
  margin: 1rem;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
    Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 500;
  // &:hover {
  //   background: #b67df3;
  // }
  &:disabled {
    background: lightslategrey !important;
  }
}
`;
