import * as React from "react";
import { Wizard, useWizard } from "react-use-wizard";
import { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";

import AnimationContext from "../../../context/AnimationContext";
import SellContext from "../../../context/SellContext";
import styled from "styled-components";
import {
  ResponsiveLongRadioButton,
  ResponsiveButtonBlock,
  LongButtonBlock,
  BottomBar,
  FinalQuestionBlock,
  LongRadioButton,
  AnimationQuestionBlock,
  ButtonBlock,
  QuestionBlock,
  ResultsLoader,
  Button,
  Input,
  Label,
  Delay,
  AnimationIn,
  AnimationOut,
} from "../../common/index";

//final svgs
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import PersonIcon from "@mui/icons-material/Person";

import {
  Slider,
  Input as MUIInput,
  FormControl,
  InputAdornment,
} from "@mui/material";

// contenxt
import { db, firestore } from "../../../utils/firebase";
import { onValue, ref } from "firebase/database";
import {
  collection,
  addDoc,
  arrayUnion,
  updateDoc,
  doc,
} from "firebase/firestore";
import { PatternFormat } from "react-number-format";

import PlacesAutocomplete from "react-places-autocomplete";

const Header = () => {
  const { activeStep, stepCount } = useWizard();
  const { sellData } = useContext(SellContext);

  const {
    animationOut,
    animation,
    isVisible,
    animationIn,
    windowWidth,
    setWindowWidth,
    windowHeight,
    setWindowHeight,
    route,
    setRoute,
    delayIn,
    delayOut,
  } = useContext(AnimationContext);
  return (
    <div
      style={{
        fontSize: "16px",
        position: "fixed",
        display: "none",
      }}
    >
      ANIMATION: {JSON.stringify(animation)}
      <br />
      animationin: {JSON.stringify(animationIn)}
      <br />
      animationOut: {JSON.stringify(animationOut)}
      <br />
      Window Width: {JSON.stringify(windowWidth)}
      <br />
      Window Height: {JSON.stringify(windowHeight)}
      <br />
      Current Step: {activeStep + 1}
      <br />
      Type: {JSON.stringify(sellData)}
    </div>
  );
};

const Footer = ({ route }) => {
  const { activeStep, stepCount } = useWizard();
  const percent = (activeStep / stepCount) * 100;

  return (
    <BottomBar
      route="isSell"
      percent={percent}
      activeStep={activeStep}
      stepCount={stepCount}
    />
  );
};

const Wrapper = ({ children, route }) => {
  const {
    isLoading,
    isLastStep,
    isFirstStep,
    activeStep,
    stepCount,
    previousStep,
    nextStep,
    goToStep,
    handleStep,
  } = useWizard();
  const {
    animationOut,
    animation,
    isVisible,
    animationIn,
    windowWidth,
    setWindowWidth,
    windowHeight,
    setWindowHeight,
    setAnimationOut,
    setAnimationIn,
    delayIn,
    delayOut,
  } = useContext(AnimationContext);

  // Attach an optional handler
  handleStep(() => {
    setAnimationOut(true);
    setAnimationIn(false);
  });
  if (isFirstStep) {
    if (!animation) {
      return <div className="">{children}</div>;
    }
    if (animation) {
      return (
        <div className="animation__question__wrapper">
          <div className="theSun">
            <div className="ray_box">
              <div className="ray ray1"></div>
              <div className="ray ray2"></div>
              <div className="ray ray3"></div>
              <div className="ray ray4"></div>
              <div className="ray ray5"></div>
              <div className="ray ray6"></div>
              <div className="ray ray7"></div>
              <div className="ray ray8"></div>
              <div className="ray ray9"></div>
              <div className="ray ray10"></div>
            </div>
          </div>
          <div className="background_overlay_rent"></div>
          <div>
            {animationIn ? (
              <AnimationIn delay={delayIn} />
            ) : (
              <AnimationQuestionBlock animation={true}>
                <div>{children}</div>
              </AnimationQuestionBlock>
            )}
          </div>
        </div>
      );
    }
  }
  if (isLastStep) {
    return <div>{children}</div>;
  }
  if (!animation) {
    return <div className="">{children}</div>;
  }
  if (animation) {
    return (
      <>
        <div className="animation__question__wrapper">
          <div className="theSun">
            <div className="ray_box">
              <div className="ray ray1"></div>
              <div className="ray ray2"></div>
              <div className="ray ray3"></div>
              <div className="ray ray4"></div>
              <div className="ray ray5"></div>
              <div className="ray ray6"></div>
              <div className="ray ray7"></div>
              <div className="ray ray8"></div>
              <div className="ray ray9"></div>
              <div className="ray ray10"></div>
            </div>
          </div>
          <div className="background_overlay"></div>
          {/* <div className="cloud-intro"></div> */}

          {/* <div className="x1">
            <div className="cloud"></div>
          </div>
          <div className="x2">
            <div className="cloud"></div>
          </div>
          <div className="x3">
            <div className="cloud"></div>
          </div> */}
          <div>
            {animationOut ? (
              <AnimationOut delay={delayOut} />
            ) : (
              <AnimationQuestionBlock animation={true}>
                <div>{children}</div>
              </AnimationQuestionBlock>
            )}
          </div>
        </div>
      </>
    );
  }
};

const Sell = () => {
  const { animation, setAnimation, animationOut, setAnimationOut } =
    useContext(AnimationContext);
  const [sellQuestion, setSellQuestion] = useState();

  useEffect(() => {
    const query = ref(db, "sell_questions");
    return onValue(query, (snapshot) => {
      const data = snapshot.val();
      setSellQuestion(data);
    });
  }, []);
  return (
    <Wizard
      startIndex={0}
      header={<Header />}
      footer={<Footer />}
      wrapper={<Wrapper />}
    >
      <Step1
        sellQuestion={sellQuestion}
        animation={animation}
        setAnimationOut={setAnimationOut}
        animationOut={animationOut}
      />
      <Step2
        sellQuestion={sellQuestion}
        animation={animation}
        setAnimationOut={setAnimationOut}
        animationOut={animationOut}
      />
      <Step3
        sellQuestion={sellQuestion}
        animation={animation}
        setAnimationOut={setAnimationOut}
        animationOut={animationOut}
      />
      <Step4
        sellQuestion={sellQuestion}
        animation={animation}
        setAnimationOut={setAnimationOut}
        animationOut={animationOut}
      />
      <Step5
        sellQuestion={sellQuestion}
        animation={animation}
        setAnimationOut={setAnimationOut}
        animationOut={animationOut}
      />
      <Step6
        sellQuestion={sellQuestion}
        animation={animation}
        setAnimationOut={setAnimationOut}
        animationOut={animationOut}
      />
    </Wizard>
  );
};

const Step1 = ({
  sellQuestion,
  animation,
  setAnimationOut,
  route,
  animationOut,
}) => {
  const {
    isLoading,
    isLastStep,
    isFirstStep,
    activeStep,
    stepCount,
    previousStep,
    nextStep,
    goToStep,
    handleStep,
  } = useWizard();

  const [isActive, setActive] = useState(false);
  const [loading, setLoading] = useState(true);
  // keyboard

  const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);

  const handleFocus = () => {
    setIsKeyboardOpen(true);
    console.log(isKeyboardOpen);
  };

  const handleBlur = () => {
    setIsKeyboardOpen(false);
    console.log(isKeyboardOpen);
  };

  const { setPropertyAddress, propertyAddress, setSellDocId, sellDocId } =
    useContext(SellContext);

  useEffect(async () => {
    if (sellQuestion) {
      setLoading(false);
    }
  }, [sellQuestion]);

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const setSell = async (event) => {
    try {
      const docRef = await addDoc(collection(firestore, "sell_inquiries"), {
        created_at: Date.now(),
        propertyAddress,
        status: "Submitted",
      });
      console.log("Document written with ID: ", docRef.id);
      setSellDocId(docRef.id);
    } catch (err) {
      console.error("Error adding document: ", err.message);
    }
  };

  if (loading) return "Loading...";
  if (sellQuestion) {
    if (animation) {
      return (
        <>
          <QuestionHeader style={{ transform: "translateY(-70px)" }}>
            {sellQuestion.step_1.title}
          </QuestionHeader>
          <ButtonBlock>
            {sellQuestion.step_1.options.map((x, i) => {
              return (
                <React.Fragment key={x.id}>
                  <PlacesAutocomplete
                    value={propertyAddress}
                    onChange={setPropertyAddress}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div>
                        <AddressContainer
                          {...getInputProps({
                            placeholder:
                              "123 Sold St. San Antonio, TX 78000...",
                          })}
                        />
                        {suggestions.length > 0 && (
                          <SuggestionsContainer>
                            {loading ? <div>Loading...</div> : null}
                            {suggestions.map((suggestion, index) => (
                              <Suggestion
                                key={index}
                                {...getSuggestionItemProps(suggestion, {
                                  active: suggestion.active,
                                })}
                              >
                                {suggestion.description}
                              </Suggestion>
                            ))}
                          </SuggestionsContainer>
                        )}
                      </div>
                    )}
                  </PlacesAutocomplete>
                </React.Fragment>
              );
            })}
          </ButtonBlock>
          {!propertyAddress ? (
            <Button
              color="#ffed4a"
              disabled
              onClick={() => nextStep()}
              label="Next"
            />
          ) : (
            <Button
              color="#ffed4a"
              onClick={() => {
                nextStep();
                setPropertyAddress(propertyAddress);
                setSell();
              }}
              label="Next"
            />
          )}
        </>
      );
    }
    const background = sellQuestion.step_1.background_url;
    return (
      <>
        <QuestionBlock
          isKeyboardOpen={isKeyboardOpen}
          backgroundImage={background}
          headerText={sellQuestion.step_1.title}
        >
          <ResponsiveButtonBlock>
            {sellQuestion.step_1.options.map((x, i) => {
              return (
                <React.Fragment key={x.id}>
                  <PlacesAutocomplete
                    value={propertyAddress}
                    onChange={setPropertyAddress}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div>
                        <AddressContainer
                          {...getInputProps({
                            placeholder:
                              "123 Sold St. San Antonio, TX 78000...",
                            onFocus: handleFocus,
                            onBlur: handleBlur,
                          })}
                        />
                        {suggestions.length > 0 && (
                          <SuggestionsContainer>
                            {loading ? <div>Loading...</div> : null}
                            {suggestions.map((suggestion, index) => (
                              <Suggestion
                                key={index}
                                {...getSuggestionItemProps(suggestion, {
                                  active: suggestion.active,
                                })}
                              >
                                {suggestion.description}
                              </Suggestion>
                            ))}
                          </SuggestionsContainer>
                        )}
                      </div>
                    )}
                  </PlacesAutocomplete>
                </React.Fragment>
              );
            })}
          </ResponsiveButtonBlock>

          {!propertyAddress ? (
            <Button disabled onClick={() => nextStep()} label="Next" />
          ) : (
            <Button
              onClick={() => {
                nextStep();
                setPropertyAddress(propertyAddress);
                setSell();
              }}
              label="Next"
            />
          )}
        </QuestionBlock>
      </>
    );
  }
};

const Step2 = ({ route, animation, sellQuestion }) => {
  const { handleStep, previousStep, nextStep } = useWizard();
  const marks = [
    {
      value: 0,
      label: "$0",
    },
    {
      value: 1000000,
      label: "$1,000,000",
    },
    {
      value: 500000,
      label: "$500,000",
    },
  ];

  const [values, setValues] = useState([250000, 750000]);
  const { setWorthMin, setWorthMax } = useContext(SellContext);
  const handleMinChange = (event) => {
    let value =
      event.target.value === ""
        ? ""
        : Number(event.target.value.replace(/[$,]+/g, ""));
    if (isNaN(value)) {
      value = 0;
    }
    if (value < 0) {
      value = 0;
    }
    if (value > 1000000) {
      value = 1000000;
    }
    if (value > values[1]) {
      value = values[1];
    }
    setValues([value, values[1]]);
  };

  const handleMaxChange = (event) => {
    let value =
      event.target.value === ""
        ? ""
        : Number(event.target.value.replace(/[$,]+/g, ""));
    if (isNaN(value)) {
      value = 0;
    }
    if (value < 0) {
      value = 0;
    }

    if (value > 1000000) {
      value = 1000000;
    }
    if (value < values[0]) {
      value = values[0];
    }
    setValues([values[0], value]);
  };

  const handleSliderChange = (event, newValue) => {
    setValues(newValue);
  };

  if (sellQuestion) {
    if (animation) {
      return (
        <>
          <Delay delay="0">
            <QuestionHeader
              style={{ transform: "translateY(-60px)", width: 300 }}
            >
              {sellQuestion.step_2.title}
            </QuestionHeader>
            <ButtonBlock>
              <SliderWrapper>
                <StlyedSliderInput>
                  Min
                  <FormControl>
                    <StyledNumber
                      type="text"
                      value={
                        values[0] === 0
                          ? "$0"
                          : `$${values[0].toLocaleString()}`
                      }
                      onChange={handleMinChange}
                      startAdornment={
                        <InputAdornment position="start">Min</InputAdornment>
                      }
                    />
                  </FormControl>
                </StlyedSliderInput>
                <StlyedSliderInput>
                  <FormControl>
                    Max
                    <StyledNumber
                      type="text"
                      value={
                        values[1] === 0
                          ? "$0"
                          : `$${values[1].toLocaleString()}`
                      }
                      placeholder="$0"
                      onChange={handleMaxChange}
                      startAdornment={
                        <InputAdornment position="start">Max</InputAdornment>
                      }
                    />
                  </FormControl>
                </StlyedSliderInput>

                <StyledSlider
                  valueLabelDisplay="auto"
                  value={values}
                  min={0}
                  max={1000000}
                  onChange={handleSliderChange}
                  aria-labelledby="range-slider"
                  disableSwap
                  marks={marks}
                  step={5000}
                />
              </SliderWrapper>
            </ButtonBlock>
            <ButtonWrapper>
              <div> You can type or slide your answer</div>
              <Button
                onClick={() => {
                  nextStep();
                  setWorthMax(values[1]);
                  setWorthMin(values[0]);
                }}
                label="Next"
              />
            </ButtonWrapper>
            <div
              className="skip-question"
              onClick={() => {
                nextStep();
                setWorthMax(0);
                setWorthMin(0);
              }}
            >
              I'm Not Sure
            </div>
          </Delay>
        </>
      );
    }
    const background = sellQuestion.step_2.background_url;

    return (
      <>
        <QuestionBlock
          backgroundImage={background}
          headerText={sellQuestion.step_2.title}
        >
          <SliderWrapper>
            <StlyedSliderInput>
              Min
              <FormControl>
                <StyledNumber
                  type="text"
                  value={
                    values[0] === 0 ? "$0" : `$${values[0].toLocaleString()}`
                  }
                  onChange={handleMinChange}
                  startAdornment={
                    <InputAdornment position="start">Min</InputAdornment>
                  }
                />
              </FormControl>
            </StlyedSliderInput>
            <StlyedSliderInput>
              <FormControl>
                Max
                <StyledNumber
                  type="text"
                  value={
                    values[1] === 0 ? "$0" : `$${values[1].toLocaleString()}`
                  }
                  onChange={handleMaxChange}
                  startAdornment={
                    <InputAdornment position="start">Max</InputAdornment>
                  }
                />
              </FormControl>
            </StlyedSliderInput>

            <StyledSlider
              valueLabelDisplay="auto"
              value={values}
              min={0}
              max={1000000}
              step={5000}
              onChange={handleSliderChange}
              aria-labelledby="range-slider"
              disableSwap
              marks={marks}
            />
          </SliderWrapper>

          <ButtonWrapper>
            <div> You can type or slide your answer</div>
            <Button
              onClick={() => {
                nextStep();
                setWorthMax(values[1]);
                setWorthMin(values[0]);
              }}
              label="Next"
            />
          </ButtonWrapper>
          <div
            className="skip-question"
            onClick={() => {
              nextStep();
              setWorthMax(0);
              setWorthMin(0);
            }}
          >
            I'm Not Sure
          </div>
        </QuestionBlock>
      </>
    );
  }
};

const Step3 = ({ route, animation, sellQuestion }) => {
  // checkbox
  const { handleStep, previousStep, nextStep } = useWizard();

  const [checked, setChecked] = useState(true);
  const checkState = () => {
    setChecked(true);
  };
  // Multi Select
  const [selected, setSelected] = useState([]);
  const handleOnClick = (e) => {
    setSelected(e.target.value);
  };
  const { setCondition } = useContext(SellContext);
  if (sellQuestion) {
    if (animation) {
      return (
        <>
          <Delay delay="0">
            <QuestionHeader style={{ transform: "translateY(-40px)" }}>
              {sellQuestion.step_3.title}
            </QuestionHeader>
            <LongButtonBlock>
              {sellQuestion.step_3.options.map((x, i) => {
                return (
                  <React.Fragment key={x.id}>
                    <ResponsiveLongRadioButton
                      id={x.id}
                      icon={null}
                      icon_text={x.icon}
                      onClick={(e) => handleOnClick(e)}
                      label={x.label}
                      value={x.label}
                      route={route}
                      name="select"
                      type="radio"
                    />
                  </React.Fragment>
                );
              })}
            </LongButtonBlock>
            <ButtonWrapper>
              {selected.length === 0 ? (
                <Button disabled onClick={() => nextStep()} label="Next" />
              ) : (
                <Button
                  onClick={() => {
                    nextStep();
                    setCondition(selected);
                  }}
                  label="Next"
                />
              )}
            </ButtonWrapper>
          </Delay>
        </>
      );
    }
    const background = sellQuestion.step_3.background_url;

    return (
      <>
        <QuestionBlock
          backgroundImage={background}
          headerText={sellQuestion.step_3.title}
        >
          <ResponsiveButtonBlock>
            {sellQuestion.step_3.options.map((x, i) => {
              return (
                <React.Fragment key={x.id}>
                  <LongRadioButton
                    id={x.id}
                    icon={null}
                    icon_text={x.icon}
                    onClick={(e) => handleOnClick(e)}
                    label={x.label}
                    value={x.label}
                    route={route}
                    name="select"
                    type="radio"
                  />
                </React.Fragment>
              );
            })}
          </ResponsiveButtonBlock>
          <ButtonWrapper>
            {selected.length === 0 ? (
              <Button disabled onClick={() => nextStep()} label="Next" />
            ) : (
              <Button
                onClick={() => {
                  nextStep();
                  setCondition(selected);
                }}
                label="Next"
              />
            )}
          </ButtonWrapper>
        </QuestionBlock>
      </>
    );
  }
};

const Step4 = ({ sellQuestion, route, animation }) => {
  const { handleStep, previousStep, nextStep } = useWizard();

  const [values, setValues] = useState([0, 150000]);
  const { setMinRepair, setMaxRepair } = useContext(SellContext);
  const handleMinChange = (event) => {
    let value =
      event.target.value === ""
        ? ""
        : Number(event.target.value.replace(/[$,]+/g, ""));
    if (isNaN(value)) {
      value = 0;
    }
    if (value < 0) {
      value = 0;
    }
    if (value > 150000) {
      value = 150000;
    }
    if (value > values[1]) {
      value = values[1];
    }
    setValues([value, values[1]]);
  };

  const handleMaxChange = (event) => {
    let value =
      event.target.value === ""
        ? ""
        : Number(event.target.value.replace(/[$,]+/g, ""));
    if (isNaN(value)) {
      value = 0;
    }
    if (value < 0) {
      value = 0;
    }

    if (value > 150000) {
      value = 150000;
    }
    if (value < values[0]) {
      value = values[0];
    }
    setValues([values[0], value]);
  };

  const handleSliderChange = (event, newValue) => {
    setValues(newValue);
  };

  if (sellQuestion) {
    if (animation) {
      return (
        <>
          <Delay delay="0">
            <QuestionHeader
              style={{ transform: "translateY(-60px)", width: 300 }}
            >
              {sellQuestion.step_4.title}
            </QuestionHeader>
            <ButtonBlock>
              <SliderWrapper>
                <StlyedSliderInput>
                  Min
                  <FormControl>
                    <StyledNumber
                      type="text"
                      value={
                        values[0] === 0
                          ? "$0"
                          : `$${values[0].toLocaleString()}`
                      }
                      onChange={handleMinChange}
                      startAdornment={
                        <InputAdornment position="start">Min</InputAdornment>
                      }
                    />
                  </FormControl>
                </StlyedSliderInput>
                <StlyedSliderInput>
                  <FormControl>
                    Max
                    <StyledNumber
                      type="text"
                      value={
                        values[1] === 0
                          ? "$0"
                          : `$${values[1].toLocaleString()}`
                      }
                      onChange={handleMaxChange}
                      startAdornment={
                        <InputAdornment position="start">Max</InputAdornment>
                      }
                    />
                  </FormControl>
                </StlyedSliderInput>

                <StyledSlider
                  value={values}
                  min={0}
                  max={150000}
                  onChange={handleSliderChange}
                  aria-labelledby="range-slider"
                  disableSwap
                  step={500}
                />
              </SliderWrapper>
            </ButtonBlock>
            <div> You can type or slide your answer</div>

            <ButtonWrapper>
              <Button
                onClick={() => {
                  setMaxRepair(values[1]);
                  setMinRepair(values[0]);
                  nextStep();
                }}
                label="Next"
              />
            </ButtonWrapper>
            <div
              className="skip-question"
              onClick={() => {
                setMaxRepair(0);
                setMinRepair(0);
                nextStep();
              }}
            >
              I'm Not Sure
            </div>
          </Delay>
        </>
      );
    }
    const background = sellQuestion.step_4.background_url;

    return (
      <>
        <QuestionBlock
          backgroundImage={background}
          headerText={sellQuestion.step_4.title}
        >
          <SliderWrapper>
            <StlyedSliderInput>
              Min
              <FormControl>
                <StyledNumber
                  type="text"
                  value={
                    values[0] === 0 ? "$0" : `$${values[0].toLocaleString()}`
                  }
                  onChange={handleMinChange}
                  startAdornment={
                    <InputAdornment position="start">Min</InputAdornment>
                  }
                />
              </FormControl>
            </StlyedSliderInput>
            <StlyedSliderInput>
              <FormControl>
                Max
                <StyledNumber
                  type="text"
                  value={
                    values[1] === 0 ? "$0" : `$${values[1].toLocaleString()}`
                  }
                  onChange={handleMaxChange}
                  startAdornment={
                    <InputAdornment position="start">Max</InputAdornment>
                  }
                />
              </FormControl>
            </StlyedSliderInput>

            <StyledSlider
              value={values}
              min={0}
              max={150000}
              step={500}
              onChange={handleSliderChange}
              aria-labelledby="range-slider"
              disableSwap
            />
          </SliderWrapper>

          <ButtonWrapper>
            <div> You can type or slide your answer</div>
            <Button
              onClick={() => {
                setMaxRepair(values[1]);
                setMinRepair(values[0]);
                nextStep();
              }}
              label="Next"
            />
          </ButtonWrapper>
          <div
            className="skip-question"
            onClick={() => {
              setMaxRepair(0);
              setMinRepair(0);
              nextStep();
            }}
          >
            I'm Not Sure
          </div>
        </QuestionBlock>
      </>
    );
  }
};

const Step5 = ({ sellQuestion, route, animation }) => {
  // checkbox
  const { handleStep, previousStep, nextStep } = useWizard();

  const [checked, setChecked] = useState(true);
  const checkState = () => {
    setChecked(true);
  };
  // Multi Select
  const [selected, setSelected] = useState([]);
  const handleOnClick = (e) => {
    setSelected(e.target.value);
  };
  const { setPriority } = useContext(SellContext);
  if (sellQuestion) {
    if (animation) {
      return (
        <>
          <Delay delay="0">
            <QuestionHeader style={{ transform: "translateY(-30px)" }}>
              {sellQuestion.step_5.title}
            </QuestionHeader>
            <LongButtonBlock>
              {sellQuestion.step_5.options.map((x, i) => {
                return (
                  <React.Fragment key={x.id}>
                    <ResponsiveLongRadioButton
                      id={x.id}
                      icon={null}
                      icon_text={x.icon}
                      onClick={(e) => handleOnClick(e)}
                      label={x.label}
                      value={x.label}
                      route={route}
                      name="select"
                      type="radio"
                    />
                  </React.Fragment>
                );
              })}
            </LongButtonBlock>
            <ButtonWrapper>
              {selected.length === 0 ? (
                <Button disabled onClick={() => nextStep()} label="Next" />
              ) : (
                <Button
                  onClick={() => {
                    nextStep();
                    setPriority(selected);
                  }}
                  label="Next"
                />
              )}
            </ButtonWrapper>
          </Delay>
        </>
      );
    }
    const background = sellQuestion.step_5.background_url;

    return (
      <>
        <QuestionBlock
          backgroundImage={background}
          headerText={sellQuestion.step_5.title}
        >
          <ResponsiveButtonBlock>
            {sellQuestion.step_5.options.map((x, i) => {
              return (
                <React.Fragment key={x.id}>
                  <LongRadioButton
                    id={x.id}
                    icon={null}
                    icon_text={x.icon}
                    onClick={(e) => handleOnClick(e)}
                    label={x.label}
                    value={x.label}
                    route={route}
                    name="select"
                    type="radio"
                  />
                </React.Fragment>
              );
            })}
          </ResponsiveButtonBlock>
          <ButtonWrapper>
            {selected.length === 0 ? (
              <Button disabled onClick={() => nextStep()} label="Next" />
            ) : (
              <Button
                onClick={() => {
                  nextStep();
                  setPriority(selected);
                }}
                label="Next"
              />
            )}
          </ButtonWrapper>
        </QuestionBlock>
      </>
    );
  }
};
const Step6 = ({ sellQuestion, route, animation }) => {
  const { handleStep, previousStep, nextStep } = useWizard();

  // set Animation
  const { toggleAnimation } = useContext(AnimationContext);
  const [animationOut, setAnimationOut] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  const {
    propertyAddress,
    worthMin,
    worthMax,
    condition,
    minRepair,
    maxRepair,
    priority,
    sellDocId,
  } = useContext(SellContext);
  let history = useHistory();

  const { loader } = useContext(AnimationContext);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [formattedPhone, setFormattedPhone] = useState("");
  const [phone, setPhone] = useState("");

  const data = {
    name: name,
    email: email,
    phone: phone,
    type: "Sell",
    propertyAddress,
    worthMin,
    worthMax,
    condition,
    minRepair,
    maxRepair,
    priority,
  };

  const isButtonDisabled = () => {
    return !(
      name &&
      (email || (formattedPhone && isValidPhoneNumber(formattedPhone)))
    );
  };

  const isValidPhoneNumber = (phone) => {
    // A full phone number typically has 10 digits in it
    return phone.length >= 10;
  };

  function removeNonNumeric(phoneNumber) {
    // Using regular expression to match any non-numeric characters and replace them with an empty string
    return phoneNumber.replace(/\D/g, "");
  }

  const handlePhoneChange = (event) => {
    const stringPhone = removeNonNumeric(event.target.value);
    setFormattedPhone(stringPhone);
  };
  const addSell = async (event) => {
    event.preventDefault();

    try {
      const docRef = await updateDoc(
        doc(firestore, "sell_inquiries", sellDocId),
        {
          name: name,
          email: email,
          created_at: Date.now(),
          propertyAddress: propertyAddress,
          phone_number: formattedPhone,
          worth: {
            min: worthMin,
            max: worthMax,
          },
          condition,
          repair: {
            min: minRepair,
            max: maxRepair,
          },
          maxRepair,
          priority,
          status: "Submitted",
        }
      );
      history.push("/success-sell");
    } catch (err) {
      console.error("Error adding document: ", err.message);
    }
  };

  const background =
    "https://firebasestorage.googleapis.com/v0/b/thehousehawk-b7691.appspot.com/o/public%2Fbackgrounds%2Fpublic%2Fbackgrounds%2Fresized%2Ffinal_bg_1920x1080.webp?alt=media&token=d3dcd4aa-fcd8-4bb5-a333-9dfdefea3d07";

  if (loader) {
    return <ResultsLoader delay="4800" />;
  }
  if (!loader) {
    return (
      <>
        <FinalQuestionBlock backgroundImage={background}>
          <p>
            Enter your information to receive an estimate for your home and a
            quick-close cash offer delivered right to your inbox.
          </p>
          <form onSubmit={addSell} className="final-form-sell">
            <Label htmlFor="name">
              <span className="image_space">
                <PersonIcon />
              </span>
              <Input
                placeholder="Name"
                type="name"
                id="name"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Label>
            <Label htmlFor="Email">
              <span className="image_space">
                <EmailIcon />
              </span>
              <Input
                placeholder="Email"
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Label>
            <p
              style={{
                textAlign: "center",
                marginBottom: 10,
              }}
            >
              And/Or
            </p>
            <Label htmlFor="phone">
              <span className="image_space">
                <LocalPhoneIcon />
              </span>
              <PatternFormat
                placeholder="Phone"
                type="phone"
                id="phone"
                name="phone"
                value={phone}
                onChange={handlePhoneChange}
                format="(###)-###-####"
                mask="_"
              />
            </Label>
            <Button
              disabled={isButtonDisabled()}
              type="submit"
              label="See My Offer"
              btnClass="submit-button"
            />
            <FooterText>
              You agree to The House Hawk LLC's Terms of Use & Privacy Policy
              and to be contacted by us or third parties. By registering, you
              give us your express written consent to deliver automated emails
              to you. Consent is not a condition of purchase. Your registration
              acts as your binding electronic signature.
            </FooterText>
          </form>
        </FinalQuestionBlock>
      </>
    );
  }
};

export default Sell;
const FooterText = styled.p`
  font-size: 14px;
`;
const ButtonWrapper = styled.div`
  display: block;
  margin: 0 auto;
`;
const QuestionHeader = styled.div`
  text-align: center;
  font-size: 1.8rem;
  margin: 0 auto;
  width: 420px;
  font-weight: bold;
  text-shadow: 2px 2px #000;
`;
const Seperator = styled.div`
  width: 100%;
  padding-top: 4px;
  padding-bottom: 4px;
`;
const Content = styled.div`
  font-size: 16px;
  margin: 0px auto;
  margin-top: 16px;
  margin-bottom: 16px;
  font-weight: bold;
  text-shadow: 1px 1px black;
`;
const SliderWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;
const AddressContainer = styled.input`
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  padding: 20px;
  background-color: #f2f2f2;
  border-radius: 4px;
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 320px) {
    width: 256px;
  }

  @media (max-width: 425px) {
    width: 256px;
  }
`;

const SuggestionsContainer = styled.div`
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #ddd;
  padding: 10px;
  max-height: 200px;
  overflow-y: auto;
  position: absolute;
  width: 320px;
  z-index: 1;

  @media (max-width: 320px) {
    width: 280px;
  }

  @media (max-width: 425px) {
    width: 256px;
  }
`;

const Suggestion = styled.div`
  font-size: 14px;
  padding: 10px;
  color: black;
  background-color: ${(props) => (props.active ? "#FF0801" : "#fff")};
  &:hover {
    background-color: #ff0801;
    color: white;
  }
`;
const StyledNumber = styled(Input)`
  width: 60%;
  margin: 0px auto;
  text-align: center;
  letter-spacing: 5.6px;
  border-radius: 8px;
  border: 2px solid black;
  height: calc(1.6em + 0.75rem + 2px);
  background: #fdcae1;
  padding: 5px;
  @media () &::placeholder {
    color: #f9398f;
  }
`;
const StlyedSliderInput = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 200px;
  margin: 10px;
  background: #fdcae1;
  color: #f9398f;
  letter-spacing: 2px;
  border-radius: 25px;
  border: 2px solid #adcae6;
  margin-bottom: 40px;
`;

const StyledSlider = styled(Slider)`
  touch-action: pan-x !important;
  max-width: 80%;
  padding-left: 10px;
  .MuiSlider-thumb {
    height: 30px;
    width: 30px;
    pointer-events: none !important;
  }
`;
