import React, { useEffect, useState } from "react";
import { device } from "../../../utils/mediaQueries";
import styled, { css } from "styled-components";
import { PulseLoader } from "react-spinners";

const Loader = (props) => {
  return (
    <div
      style={{
        display: "flex",
        backgroundSize: "cover",
        width: "100vw",
        height: "100vh",
        backgroundPosition: "center",
      }}
    >
      <StyledQuestionBlock>
        <PulseLoader color="#0052FF90" size={34} />
      </StyledQuestionBlock>
    </div>
  );
};

export default Loader;

const StyledQuestionBlock = styled.div`
  @media ${device.phone} {
    width: 320px;
    height: 500px;
    flex-wrap: wrap;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0 auto;
    background-color: white;
  }
  @media ${device.xs} {
    width: 576px;
  }
`;
