import React from "react";
import styled, { css } from "styled-components";
import { device } from "../../../utils/mediaQueries";

const CheckBoxBlock = ({ height, ml, fontColor, bgColor, width, children }) => {
  return (
    <StyledCheckBoxBlock
      width={width}
      height={height}
      ml={ml}
      fontColor={fontColor}
      bgColor={bgColor}
    >
      {children}
    </StyledCheckBoxBlock>
  );
};

export default CheckBoxBlock;

const StyledCheckBoxBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  background-color: ${(props) => props.bgColor};
  color: ${(props) => props.fontColor};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  overflow-y: scroll;
  margin-left: ${(props) => props.ml};
  margin-bottom: ${(props) => props.mb};
  &.when_media {
    @media (${device.phone}) {
      flex-wrap: wrap;
    }
  }
`;
