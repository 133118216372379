import React, { useState, useEffect, useContext, createRef } from "react";
import styled from "styled-components";
import { Map, TileLayer, FeatureGroup } from "react-leaflet";
import MapContext from "../../../context/MapContext";
import { EditControl } from "react-leaflet-draw";
import { useRef } from "react";
import { geosearch } from "esri-leaflet-geocoder";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import "leaflet-fullscreen/dist/Leaflet.fullscreen.js";
import "leaflet-fullscreen/dist/leaflet.fullscreen.css";
import useGeoLocation from "./useGeoLocation";
import { Button } from ".";

const GeoCoder = ({ goToNextStep }) => {
  const GEOCODE_URL =
    "https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/reverseGeocode?f=pjson&langCode=EN&location=";
  const [center, setCenter] = useState({ lat: 0, lng: 0 });
  const [mapLayers, setMapLayers] = useState([]);
  const ZOOM_LEVEL = 0;
  const mapRef = useRef();
  const searchRef = createRef();
  const {
    setAutoLocation,
    autoLocation,
    address,
    setAddress,
    latlng,
    setLatLng,
  } = useContext(MapContext);
  const [getLocation, setGetLocation] = useState("");
  useEffect(() => {
    const { current = {} } = mapRef;
    const { leafletElement: map } = current;
    if (!map) return;
    const control = geosearch();
    control.addTo(map);
    control.on("results", handleOnSearchResuts);
    const searchContainer = control.onAdd(map);
    searchRef.current.appendChild(searchContainer);
    return () => {
      control.off("results", handleOnSearchResuts);
    };
  }, [mapRef]);

  /**
   * handleOnSearchResuts
   * @param {object} data Results object from esri-leaflet-geocoder
   */

  function handleOnSearchResuts(data) {
    setAddress(data.text);
    setLatLng(data.latlng);
  }
  const location = useGeoLocation();

  const getAddressFromLocation = async () => {
    if (location.loaded && !location.error) {
      const data = await (
        await fetch(
          GEOCODE_URL +
            `${location.coordinates.lng},${location.coordinates.lat}`
        )
      ).json();
      const stringAddress = data.address.City + data.address.Region;
      setAddress(data.address.City + ", " + data.address.Region);
      setAutoLocation([location.coordinates.lat, location.coordinates.lng]);
      localStorage.setItem(
        "autoLocation",
        JSON.stringify([location.coordinates.lat, location.coordinates.lng])
      );
    } else {
      setAddress("Please enable location");
    }
  };
  useEffect(() => {
    getAddressFromLocation();
  }, [location]);

  const createLayer = (e) => {
    const { layerType, layer } = e;
    if (layerType === "polygon") {
      const { _leaflet_id } = layer;

      setMapLayers((layers) => [
        ...layers,
        { id: _leaflet_id, latlngs: layer.getLatLngs()[0] },
      ]);
    }
  };

  const editLayer = (e) => {
    const {
      layers: { _layers },
    } = e;

    Object.values(_layers).map(({ _leaflet_id, editing }) => {
      setMapLayers((layers) =>
        layers.map((l) =>
          l.id === _leaflet_id
            ? { ...l, latlngs: { ...editing.latlngs[0] } }
            : l
        )
      );
    });
  };

  const deleteLayer = (e) => {
    const {
      layers: { _layers },
    } = e;

    Object.values(_layers).map(({ _leaflet_id }) => {
      setMapLayers((layers) => layers.filter((l) => l.id !== _leaflet_id));
    });
  };

  return (
    <Wrapper>
      <Col>
        <Container>
          <div
            style={{
              textAlign: "center",
              height: "100%",
              width: "100%",
            }}
          >
            <Map
              style={{ height: 0, width: "100%", display: "none" }}
              center={center}
              zoom={ZOOM_LEVEL}
              ref={mapRef}
              fullscreenControl={true}
            >
              <FeatureGroup></FeatureGroup>
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              />
            </Map>
            {address && (
              <GeoRow>
                <AddressBox>
                  <Col
                    onClick={() => {
                      setAddress("");
                    }}
                  >
                    {address}
                  </Col>
                </AddressBox>
                <Col></Col>
              </GeoRow>
            )}
            {/* {getLocation && (
              <GeoRow>
                <AddressBox ref={searchRef}>
                  <Col>{getLocation}</Col>
                  <Col>
                    <ClearButton onClick={() => setGetLocation("")}>
                      X
                    </ClearButton>
                  </Col>
                </AddressBox>
                <Col></Col>
              </GeoRow>
            )} */}
            <SearchContainer ref={searchRef} />
          </div>
        </Container>
      </Col>
    </Wrapper>
  );
};

export default GeoCoder;
const AddressBox = styled.div`
  width: 280px;
  max-width: 90%; // or whatever maximum you desire
  font-size: 18px;
  text-align: left;
  overflow-y: auto;
  padding: 10px 10px 4px 10px;
  margin: auto auto auto 1.6rem;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
`;

const ClearButton = styled.div`
  padding: 4px;
  width: 12px;
  height: 12px;
  font-size: 12px;
  text-align: center;
  cursor: pointer;
  margin-left: 20px;
`;
const Wrapper = styled.div`
  z-index: 1;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
`;

const Col = styled.div`
  display: inline-block;
`;
export const Row = styled.div`
  flex-wrap: wrap;
  display: flex;

  p {
    text-align: left;
  }
`;

export const GeoRow = styled.div`
  flex-wrap: wrap;
  display: flex;
  position: absolute;
  width: 265px;
  background-color: white;
  padding: 1px;
  vertical-align: middle;
  margin-left: 36px;
`;
const Container = styled.div`
  margin: 0px auto;
`;

const SearchContainer = styled.div`
  .geocoder-control-input {
    position: inherit !important;
    background-color: white;
    background-image: none;
    border: none;
    border-radius: 3px 0 0 3px;
    text-indent: 6px;
    font-size: 18px;
    line-height: normal;
    height: 4rem;
    width: 30rem;
    cursor: pointer;
    box-shadow: none;
    background-position: 16px;
    cursor: pointer;
    box-sizing: border-box;
    background-image: url("../../../assets/map.svg");
    background-size: 1.6rem 1.6rem;
  }
  .geocoder-control-input-disabled {
    background-color: #f4f4f4;
  }
  .geocoder-control {
    width: 310px;
    height: 250px;
  }
  .geocoder-control-expanded,
  .leaflet-touch .geocoder-control-expanded {
    width: 310px;
  }
  .geocoder-control-input.geocoder-control-loading {
    background-size: 26px;
  }
  @media only screen and (min--moz-device-pixel-ratio: 2),
    only screen and (-o-min-device-pixel-ratio: 2 / 1),
    only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (min-device-pixel-ratio: 2) {
    .geocoder-control-input {
    }
    .geocoder-control-input-disabled {
    }
    .geocoder-control-input.geocoder-control-loading {
    }
  }
  .geocoder-control-input:focus {
    outline: none;
    cursor: text;
  }
  .geocoder-control-input::-ms-clear {
    display: none;
  }
  .geocoder-control-suggestions {
    width: 100%;
    position: inherit !important;
    top: 26px;
    left: 0;
    margin-top: 10px;
    overflow: auto;
    display: none;
  }
  .geocoder-control-list + .geocoder-control-header {
    border-top: 1px solid #d5d5d5;
  }
  .geocoder-control-header {
    font-size: 1.125rem;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    color: #444;
    background: #f2f2f2;
    border-bottom: 1px solid #d5d5d5;
    display: block;
    padding: 0.5em;
  }
  .leaflet-bar {
    padding-left: 32px;
  }

  .geocoder-control-list {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .geocoder-control-suggestions .geocoder-control-suggestion {
    font-size: 18px;
    padding: 7px;
    background: white;
    border-top: 1px solid #f1f1f1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
  }
  .geocoder-control-suggestions .geocoder-control-suggestion:first-child {
    border: none;
  }
  .geocoder-control-suggestions
    .geocoder-control-suggestion.geocoder-control-selected,
  .geocoder-control-suggestions .geocoder-control-suggestion:hover {
    background: #7fdfff;
    border-color: #7fdfff;
  }
  .leaflet-right .geocoder-control-suggestions {
    left: auto;
    right: 0;
  }
  .leaflet-right .geocoder-control-input {
    left: auto;
    right: 0;
  }
  .leaflet-bottom .geocoder-control-suggestions {
    margin-top: 0;
    top: 0;
  }
  .leaflet-touch .geocoder-control {
    width: 34px;
  }
  .leaflet-touch .geocoder-control.geocoder-control-expanded {
    width: 275px;
  }
  .leaflet-touch .geocoder-control-input {
    height: 34px;
    line-height: 30px;
    background-size: 30px;
  }
  .leaflet-touch .geocoder-control-suggestions {
    top: 30px;
    width: 271px;
  }
  .leaflet-oldie .geocoder-control-input {
    width: 28px;
    height: 28px;
  }
  .leaflet-oldie .geocoder-control-expanded .geocoder-control-input {
    width: auto;
  }
  .leaflet-oldie .geocoder-control-input,
  .leaflet-oldie .geocoder-control-suggestions {
    border: 1px solid #999;
  }
`;
