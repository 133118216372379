import React, { createContext, useState } from "react";

const RentContext = createContext();

export const RentProvider = props => {
  // Step 1
  const [rental, setRental] = useState("");

  // Step 2
  const [bedrooms, setBedrooms] = useState("");

  // Step 3
  const [budget, setBudget] = useState("");

  // Step 4 gets from map Context

  // Step 5
  const [amenities, setAmenities] = useState("");

  // Step 6
  const [pets, setPets] = useState("");

  // Step 7
  const [floors, setFloors] = useState("");

  // Step 8
  const [features, setFeatures] = useState("");

  // Step 9
  const [laundry, setLaundry] = useState("");

  // Step 10
  const [matters, setMatters] = useState("");

  // Step 11
  const [priority, setPriority] = useState("");

  // Step 12
  const [months, setMonths] = useState("");

  // Step 13
  const [history, setHistory] = useState("");

  return (
    <RentContext.Provider
      value={{
        rental,
        setRental,
        bedrooms,
        setBedrooms,
        budget,
        setBudget,
        amenities,
        setAmenities,
        pets,
        setPets,
        floors,
        setFloors,
        features,
        setFeatures,
        laundry,
        setLaundry,
        matters,
        setMatters,
        priority,
        setPriority,
        months,
        setMonths,
        history,
        setHistory
      }}
    >
      {props.children}
    </RentContext.Provider>
  );
};

export default RentContext;
