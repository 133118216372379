import React from "react";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <div className="header">
      <Link to="/">
        <img src="/assets/Bird_logo.jpeg" alt="The House Hawk website logo" />
      </Link>
      <nav>
        <Link to="/about">ABOUT US</Link>
        {/* <Link to="/test">Test</Link> */}

        {/* <Link to="/about">JOIN OUR TEAM</Link>
        <Link to="/Login">Login</Link> */}
      </nav>
    </div>
  );
};

export default Header;
