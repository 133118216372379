import * as React from "react";
import { Wizard, useWizard } from "react-use-wizard";
import { useState, useContext, useEffect } from "react";
import AnimationContext from "../../../context/AnimationContext";
import InvestContext from "../../../context/InvestContext";

import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { db, firestore } from "../../../utils/firebase";
import { onValue, ref } from "firebase/database";
import { collection, addDoc, arrayUnion } from "firebase/firestore";
import { PatternFormat } from "react-number-format";
import { Slider, Typography, InputAdornment } from "@mui/material";
import { NumericFormat } from "react-number-format";
import {
  ResponsiveOtherInput,
  OtherInput,
  ResponsiveNewMap,
  ResponsiveMapQuestionBox,
  GeoCoderNewMap,
  ResponsiveInputButtonBlock,
  InputButtonBlock,
  MultipleInputs,
  LaundryButtonBlock,
  MattersButtonBlock,
  MattersLongRadioButton,
  ResponsiveButtonBlockDropDown,
  ResponsiveDualSliderBlock,
  Background,
  StepProgressBar,
  ResponsiveLongRadioButton,
  ResponsiveSelectRadioButton,
  ResponsiveSliderBlock,
  ResponsiveDropdown,
  SelectResponsiveDropdown,
  ResponsiveRadioButton,
  ResponsiveButtonBlock,
  SelectButtonBlock,
  SelectRadioButton,
  LongButtonBlock,
  SelectItem,
  MapQuestionBlock,
  BottomBar,
  FinalQuestionBlock,
  LongRadioButton,
  CheckBoxBlock,
  SliderBlock,
  BlockRow,
  AnimationQuestionBlock,
  ButtonRow,
  ButtonBlock,
  QuestionBlock,
  ProgressBar,
  ResultsLoader,
  Map,
  AnimationLoading,
  DropdownComponent,
  RadioButton,
  SelectDropdownComponent,
  Button,
  Input,
  Label,
  CheckBox,
  Expire,
  TheSun,
  Delay,
  AnimationIn,
  AnimationOut,
  NewMap,
} from "../../common/index";
//final svgs
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import PersonIcon from "@mui/icons-material/Person";

// contenxt
import MapContext from "../../../context/MapContext";

const Header = () => {
  const { investData } = useContext(InvestContext);
  const { activeStep, stepCount } = useWizard();

  const {
    animationOut,
    animation,
    isVisible,
    animationIn,
    windowWidth,
    setWindowWidth,
    windowHeight,
    setWindowHeight,
    route,
    setRoute,
  } = useContext(AnimationContext);
  return (
    <div
      style={{
        fontSize: "16px",
        position: "fixed",
        display: "none",
      }}
    >
      ANIMATION: {JSON.stringify(animation)}
      <br />
      animationin: {JSON.stringify(animationIn)}
      <br />
      animationOut: {JSON.stringify(animationOut)}
      <br />
      Window Width: {JSON.stringify(windowWidth)}
      <br />
      Window Height: {JSON.stringify(windowHeight)}
      <br />
      Current Step: {activeStep + 1}
      <br />
      Type: {JSON.stringify(investData)}
    </div>
  );
};

const Footer = ({ route }) => {
  const { activeStep, stepCount } = useWizard();
  const percent = (activeStep / stepCount) * 100;

  return (
    <BottomBar
      route="isInvest"
      percent={percent}
      activeStep={activeStep}
      stepCount={stepCount}
    />
  );
};

const Wrapper = ({ children, route }) => {
  const {
    isLoading,
    isLastStep,
    isFirstStep,
    activeStep,
    stepCount,
    previousStep,
    nextStep,
    goToStep,
    handleStep,
  } = useWizard();
  const {
    animationOut,
    animation,
    isVisible,
    animationIn,
    windowWidth,
    setWindowWidth,
    windowHeight,
    setWindowHeight,
    setAnimationOut,
    setAnimationIn,
    delayIn,
    delayOut,
  } = useContext(AnimationContext);

  // Attach an optional handler
  handleStep(() => {
    setAnimationOut(true);
    setAnimationIn(false);
    console.log(animation);
    console.log(animationOut);
  });
  if (isFirstStep) {
    if (!animation) {
      return <div className="">{children}</div>;
    }
    if (animation) {
      return (
        <div className="animation__question__wrapper">
          <div className="theSun">
            <div className="ray_box">
              <div className="ray ray1"></div>
              <div className="ray ray2"></div>
              <div className="ray ray3"></div>
              <div className="ray ray4"></div>
              <div className="ray ray5"></div>
              <div className="ray ray6"></div>
              <div className="ray ray7"></div>
              <div className="ray ray8"></div>
              <div className="ray ray9"></div>
              <div className="ray ray10"></div>
            </div>
          </div>
          <div className="background_overlay"></div>
          <div>
            {animationIn ? (
              <AnimationIn delay={delayIn} />
            ) : (
              <AnimationQuestionBlock animation={true}>
                <div>{children}</div>
              </AnimationQuestionBlock>
            )}
          </div>
        </div>
      );
    }
  }
  if (isLastStep) {
    return <div>{children}</div>;
  }
  if (!animation) {
    return <div className="">{children}</div>;
  }
  if (animation) {
    return (
      <>
        <div className="animation__question__wrapper">
          <div className="theSun">
            <div className="ray_box">
              <div className="ray ray1"></div>
              <div className="ray ray2"></div>
              <div className="ray ray3"></div>
              <div className="ray ray4"></div>
              <div className="ray ray5"></div>
              <div className="ray ray6"></div>
              <div className="ray ray7"></div>
              <div className="ray ray8"></div>
              <div className="ray ray9"></div>
              <div className="ray ray10"></div>
            </div>
          </div>
          <div className="background_overlay"></div>

          <div>
            {animationOut ? (
              <AnimationOut delay={delayOut} />
            ) : (
              <AnimationQuestionBlock animation={true}>
                <div>{children}</div>
              </AnimationQuestionBlock>
            )}
          </div>
        </div>
      </>
    );
  }
};

const Invest = () => {
  const { animation, setAnimation, animationOut, setAnimationOut } =
    useContext(AnimationContext);
  const [investQuestion, setInvestQuestion] = useState();

  useEffect(() => {
    const query = ref(db, "invest_questions");
    return onValue(query, (snapshot) => {
      const data = snapshot.val();
      setInvestQuestion(data);
    });
  }, []);
  return (
    <Wizard
      startIndex={0}
      header={<Header />}
      footer={<Footer />}
      wrapper={<Wrapper />}
    >
      <Step1
        animation={animation}
        setAnimationOut={setAnimationOut}
        animationOut={animationOut}
        investQuestion={investQuestion}
      />
      <Step2
        animation={animation}
        setAnimationOut={setAnimationOut}
        animationOut={animationOut}
        investQuestion={investQuestion}
      />
      <Step3
        animation={animation}
        setAnimationOut={setAnimationOut}
        animationOut={animationOut}
        investQuestion={investQuestion}
      />
      <Step4
        animation={animation}
        setAnimationOut={setAnimationOut}
        animationOut={animationOut}
        investQuestion={investQuestion}
      />
      <Step5
        animation={animation}
        setAnimationOut={setAnimationOut}
        animationOut={animationOut}
        investQuestion={investQuestion}
      />
      <Step6
        animation={animation}
        setAnimationOut={setAnimationOut}
        animationOut={animationOut}
        investQuestion={investQuestion}
      />
      <Step7
        animation={animation}
        setAnimationOut={setAnimationOut}
        animationOut={animationOut}
        investQuestion={investQuestion}
      />
      <Step8
        animation={animation}
        setAnimationOut={setAnimationOut}
        animationOut={animationOut}
        investQuestion={investQuestion}
      />
      <Step9
        animation={animation}
        setAnimationOut={setAnimationOut}
        animationOut={animationOut}
        investQuestion={investQuestion}
      />
      <Step10
        animation={animation}
        setAnimationOut={setAnimationOut}
        animationOut={animationOut}
        investQuestion={investQuestion}
      />
      <Step11
        animation={animation}
        setAnimationOut={setAnimationOut}
        animationOut={animationOut}
        investQuestion={investQuestion}
      />
    </Wizard>
  );
};

const Step1 = ({
  investQuestion,
  animation,
  setAnimationOut,
  route,
  animationOut,
}) => {
  const {
    isLoading,
    isLastStep,
    isFirstStep,
    activeStep,
    stepCount,
    previousStep,
    nextStep,
    goToStep,
    handleStep,
  } = useWizard();

  // checkbox
  const [checked, setChecked] = useState(true);
  const checkState = () => {
    setChecked(true);
  };
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const setLoadingFalse = async () => {
      if (investQuestion) {
        setLoading(false);
      }
    };

    setLoadingFalse();
  }, [investQuestion]);

  // Multi Select
  const [selected, setSelected] = useState([]);
  const handleOnClick = (e) => {
    const item = e.target.value;
    var index = selected.indexOf(item);
    const newList = selected;
    const filtered = newList.filter((select) => select !== item);
    if (index == -1) {
      setSelected([...selected, item]);
    } else {
      setSelected([...filtered]);
    }
  };
  const { setPropertyType } = useContext(InvestContext);

  if (loading) return "Loading...";
  if (investQuestion) {
    if (animation) {
      return (
        <>
          <Delay delay="">
            <QuestionHeader
              style={{ transform: "translateY(-80px)", width: "300px" }}
            >
              {investQuestion.step_1.title}
            </QuestionHeader>
            <ButtonBlock>
              {investQuestion.step_1.options.map((x, i) => {
                return (
                  <React.Fragment key={x.id}>
                    <RadioButton
                      route={route}
                      id={x.id}
                      icon={null}
                      icon_text={x.icon}
                      onClick={(e) => handleOnClick(e)}
                      label={x.label}
                      value={x.label}
                      minWidth="54px"
                      minHeight="80px"
                      type="checked"
                    />
                  </React.Fragment>
                );
              })}
            </ButtonBlock>
            {selected.length === 0 ? (
              <Button disabled onClick={() => nextStep()} label="Next" />
            ) : (
              <Button
                onClick={() => {
                  nextStep();
                  setPropertyType(selected);
                }}
                label="Next"
              />
            )}
          </Delay>
        </>
      );
    }
    const background = investQuestion.step_1.background_url;
    return (
      <>
        <QuestionBlock
          backgroundImage={background}
          headerText={investQuestion.step_1.title}
        >
          <ResponsiveButtonBlock>
            {investQuestion.step_1.options.map((x, i) => {
              return (
                <React.Fragment key={x.id}>
                  <ResponsiveRadioButton
                    route={route}
                    id={x.id}
                    icon={null}
                    icon_text={x.icon}
                    onClick={(e) => handleOnClick(e)}
                    label={x.label}
                    value={x.label}
                    type="checked"
                  />
                </React.Fragment>
              );
            })}
          </ResponsiveButtonBlock>
          {selected.length === 0 ? (
            <Button disabled onClick={() => nextStep()} label="Next" />
          ) : (
            <Button
              onClick={() => {
                nextStep();
                setPropertyType(selected);
              }}
              label="Next"
            />
          )}
        </QuestionBlock>
      </>
    );
  }
};

const Step2 = ({ route, animation, investQuestion }) => {
  const { handleStep, previousStep, nextStep } = useWizard();
  const [bedroomSelected, setBedroomSelected] = useState("");
  const [bathRoomSelected, setBathRoomSelected] = useState("");

  // Select Dropdown
  const handleChangeBedRoom = (newValue, actionMeta) => {
    setBedroomSelected(newValue.label);
  };

  const handleChangeBathRoom = (newValue, actionMeta) => {
    setBathRoomSelected(newValue.label);
  };
  const { setBedrooms } = useContext(InvestContext);
  const { setBathrooms } = useContext(InvestContext);

  if (investQuestion) {
    if (animation) {
      const bedRoomData = investQuestion.step_2.options[0];
      const bathRoomData = investQuestion.step_2.options[1];
      return (
        <>
          <Delay delay="0">
            <QuestionHeader style={{ transform: "translateY(-70px)" }}>
              {investQuestion.step_2.title}
            </QuestionHeader>
            <ButtonBlock>
              <DropdownComponent
                onChange={handleChangeBedRoom}
                options={bedRoomData.bedrooms}
                placeholder="Select # Of Bedrooms"
                icon="https://firebasestorage.googleapis.com/v0/b/thehousehawk-b7691.appspot.com/o/public%2Ficons%2Fbedroom.png?alt=media&token=f0afd388-4182-4c10-86e4-209dfc7e3a50"
              />
              <DropdownComponent
                onChange={handleChangeBathRoom}
                options={bathRoomData.bathrooms}
                placeholder="Select # Of Bathrooms"
                icon="https://firebasestorage.googleapis.com/v0/b/thehousehawk-b7691.appspot.com/o/public%2Ficons%2Fbathroom.png?alt=media&token=0cc63611-b0dc-4087-afa7-6068879a2bf2"
              />
            </ButtonBlock>
            <ButtonWrapper>
              <Button
                onClick={() => {
                  nextStep();
                  setBathrooms(JSON.stringify(bathRoomSelected));
                  setBedrooms(JSON.stringify(bedroomSelected));
                }}
                label="Next"
              />
            </ButtonWrapper>
          </Delay>
        </>
      );
    }
    const background = investQuestion.step_2.background_url;
    const bedRoomData = investQuestion.step_2.options[0];
    const bathRoomData = investQuestion.step_2.options[1];
    return (
      <>
        <QuestionBlock
          backgroundImage={background}
          headerText={investQuestion.step_2.title}
        >
          <ResponsiveButtonBlockDropDown>
            <ResponsiveDropdown
              onChange={handleChangeBedRoom}
              options={bedRoomData.bedrooms}
              placeholder="Select # of Bedrooms"
              icon="https://firebasestorage.googleapis.com/v0/b/thehousehawk-b7691.appspot.com/o/public%2Ficons%2Fbedroom.png?alt=media&token=f0afd388-4182-4c10-86e4-209dfc7e3a50"
            />
            <ResponsiveDropdown
              onChange={handleChangeBathRoom}
              options={bathRoomData.bathrooms}
              placeholder="Select # of Bathrooms"
              icon="https://firebasestorage.googleapis.com/v0/b/thehousehawk-b7691.appspot.com/o/public%2Ficons%2Fbathroom.png?alt=media&token=0cc63611-b0dc-4087-afa7-6068879a2bf2"
            />
            <ButtonWrapper>
              <Button
                onClick={() => {
                  nextStep();
                  setBathrooms(bathRoomSelected);
                  setBedrooms(bedroomSelected);
                }}
                label="Next"
              />
            </ButtonWrapper>
          </ResponsiveButtonBlockDropDown>
        </QuestionBlock>
      </>
    );
  }
};

const Step3 = ({ route, animation, investQuestion }) => {
  const { handleStep, previousStep, nextStep } = useWizard();
  const [value, setValue] = React.useState([0, 10]);
  const { setMaxAcreage, maxAcreage, setMinAcreage, minAcreage } =
    useContext(InvestContext);

  const handleMinAcreageChange = (event) => {
    const newValue = event.target.value.trim();
    const newMinAcreage = newValue === "" ? undefined : parseFloat(newValue);
    if (!Number.isNaN(newMinAcreage)) {
      setMinAcreage(newMinAcreage);
      setValue([newMinAcreage ?? 0, maxAcreage ?? 10]);
    }
  };

  const handleMaxAcreageChange = (event) => {
    const newValue = event.target.value.trim();
    const newMaxAcreage = newValue === "" ? undefined : parseFloat(newValue);
    if (!Number.isNaN(newMaxAcreage)) {
      setMaxAcreage(newMaxAcreage);
      setValue([minAcreage ?? 0, newMaxAcreage ?? 10]);
    }
  };

  const handleSliderChange = (event, newValue) => {
    const newMinAcreage = newValue[0] ?? 0;
    const newMaxAcreage = newValue[1] ?? 10;
    setMinAcreage(newMinAcreage);
    setMaxAcreage(newMaxAcreage);
    setValue([newMinAcreage, newMaxAcreage]);
  };

  const marks = [
    {
      value: 0,
      label: "0 Acres",
    },
    {
      value: 10,
      label: "10+ Acres",
    },
  ];
  const minDistance = 0.05;

  if (investQuestion) {
    if (animation) {
      const min = investQuestion.step_3.options[0].sliders[0];
      const max = investQuestion.step_3.options[0].sliders[1];
      return (
        <>
          <Delay delay="0">
            <QuestionHeader style={{ transform: "translateY(-50px)" }}>
              {investQuestion.step_3.title}
            </QuestionHeader>
            <ButtonBlock>
              <SliderWrapper>
                <StlyedSliderInput>
                  Min Acreage
                  <StyledNumber
                    value={minAcreage}
                    margin="dense"
                    onChange={handleMinAcreageChange}
                    inputProps={{
                      step: 0.05,
                      min: 1900,
                      max: 2023,
                      type: "number",
                      "aria-labelledby": "range-slider",
                    }}
                  />
                </StlyedSliderInput>
                <StlyedSliderInput>
                  Max Acreage
                  <StyledNumber
                    value={maxAcreage}
                    margin="dense"
                    onChange={handleMaxAcreageChange}
                    inputProps={{
                      step: 0.05,
                      min: 0,
                      max: 10,
                      type: "number",
                      "aria-labelledby": "range-slider",
                    }}
                  />
                </StlyedSliderInput>
                <StyledSlider
                  value={[minAcreage, maxAcreage]}
                  onChange={handleSliderChange}
                  aria-labelledby="range-slider"
                  min={0}
                  max={10}
                  step={0.05}
                  marks={marks}
                  disableSwap
                />
              </SliderWrapper>
            </ButtonBlock>
            <p>You can type or slide your answer</p>
            {!maxAcreage ? (
              <Button disabled onClick={() => nextStep()} label="Next" />
            ) : (
              <Button
                onClick={() => {
                  nextStep();
                }}
                label="Next"
              />
            )}
            <div className="skip-question" onClick={() => nextStep()}>
              Skip this Question
            </div>
          </Delay>
        </>
      );
    }
    const background = investQuestion.step_3.background_url;
    const min = investQuestion.step_3.options[0].sliders[0];
    const max = investQuestion.step_3.options[0].sliders[1];

    return (
      <>
        <QuestionBlock
          backgroundImage={background}
          headerText={investQuestion.step_3.title}
        >
          <ResponsiveButtonBlock>
            <SliderWrapper>
              <StlyedSliderInput>
                Min Acreage
                <StyledNumber
                  value={minAcreage}
                  margin="dense"
                  onChange={handleMinAcreageChange}
                  inputProps={{
                    step: 0.05,
                    min: 0,
                    max: 10,
                    type: "number",
                    "aria-labelledby": "range-slider",
                  }}
                />
              </StlyedSliderInput>
              <StlyedSliderInput>
                Max Acreage
                <StyledNumber
                  value={maxAcreage}
                  margin="dense"
                  onChange={handleMaxAcreageChange}
                  inputProps={{
                    step: 0.05,
                    min: 0,
                    max: 10,
                    type: "number",
                    "aria-labelledby": "range-slider",
                  }}
                />
              </StlyedSliderInput>
              <StyledSlider
                value={[minAcreage, maxAcreage]}
                onChange={handleSliderChange}
                aria-labelledby="range-slider"
                min={0}
                max={10}
                step={0.05}
                marks={marks}
                disableSwap
              />
            </SliderWrapper>
          </ResponsiveButtonBlock>
          <p>You can type or slide your answer</p>

          {!minAcreage ? (
            <Button disabled onClick={() => nextStep()} label="Next" />
          ) : (
            <Button
              onClick={() => {
                nextStep();
              }}
              label="Next"
            />
          )}
          <div className="skip-question" onClick={() => nextStep()}>
            Skip this Question
          </div>
        </QuestionBlock>
      </>
    );
  }
};

const Step4 = ({ route, animation, investQuestion }) => {
  const { nextStep } = useWizard();
  // checkbox
  const [checked, setChecked] = useState(true);
  const checkState = () => {
    setChecked(true);
  };
  // Multi Select
  const [selected, setSelected] = useState([]);
  const handleOnClick = (e) => {
    const item = e.target.value;
    console.log(e.target.value);
    var index = selected.indexOf(item);
    const newList = selected;
    const filtered = newList.filter((select) => select !== item);
    if (index == -1) {
      setSelected([...selected, item]);
      console.log(item, selected);
    } else {
      setSelected([...filtered]);

      console.log(item, selected);
    }
  };
  // Other Input
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const handleOther = (e) => {
    setInputVisible(!inputVisible);
  };

  const handleInputValue = (e) => {
    var index = selected.indexOf(inputValue);
    const newList = selected;
    const filtered = newList.filter((select) => select !== inputValue);
    if (index == -1) {
      setSelected([...selected, inputValue]);
      console.log(selected);
    } else {
      setSelected([...filtered]);
      console.log(selected);
    }
  };
  const { setCriteria } = useContext(InvestContext);

  if (investQuestion) {
    if (animation) {
      return (
        <>
          <Delay delay="0">
            <QuestionHeader style={{ transform: "translateY(-50px)" }}>
              {investQuestion.step_4.title}
            </QuestionHeader>
            <SelectButtonBlock>
              {investQuestion.step_4.options.map((x, i) => {
                return (
                  <React.Fragment key={x.id}>
                    <SelectRadioButton
                      id={x.id}
                      icon={x.icon}
                      icon_text={x.icon_text}
                      onClick={(e) => handleOnClick(e)}
                      label={x.label}
                      value={x.label}
                      route={route}
                    />
                  </React.Fragment>
                );
              })}
              <OtherInput
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onBlur={handleInputValue}
                placeholder="Other - Type Here"
                type="text"
              />
            </SelectButtonBlock>

            <Button
              onClick={() => {
                nextStep();
                setCriteria(selected);
              }}
              label="Next"
            />
          </Delay>
        </>
      );
    }
    const background = investQuestion.step_4.background_url;
    return (
      <>
        <QuestionBlock
          backgroundImage={background}
          headerText={investQuestion.step_4.title}
        >
          <ResponsiveButtonBlock>
            {investQuestion.step_4.options.map((x, i) => {
              return (
                <React.Fragment key={x.id}>
                  <ResponsiveSelectRadioButton
                    id={x.id}
                    icon={x.icon}
                    icon_text={x.icon_text}
                    onClick={(e) => handleOnClick(e)}
                    label={x.label}
                    value={x.label}
                    route={route}
                  />
                </React.Fragment>
              );
            })}
            <div>
              <ResponsiveOtherInput
                onChange={(e) => setInputValue(e.target.value)}
                onBlur={handleInputValue}
                placeholder="Other - Type Here"
                type="text"
                value={inputValue}
              />
            </div>
          </ResponsiveButtonBlock>

          <Button
            onClick={() => {
              nextStep();
              setCriteria(selected);
            }}
            label="Next"
          />
        </QuestionBlock>
      </>
    );
  }
};

const Step5 = ({ route, animation, investQuestion }) => {
  const { handleStep, previousStep, nextStep } = useWizard();
  const { setBudget, budget } = useContext(InvestContext);
  const handleAmountInput = (event) => {
    setBudget(event.target.value);
  };

  if (investQuestion) {
    if (animation) {
      const x = investQuestion.step_5.options[0].sliders[0];
      return (
        <>
          <Delay delay="0">
            <QuestionHeader style={{ transform: "translateY(-50px)" }}>
              {investQuestion.step_5.title}
            </QuestionHeader>
            <ButtonBlock>
              <SliderBlock
                min={x.min}
                max={x.max}
                placeholder={budget ? budget : "XXX,XXX"}
                onChange={handleAmountInput}
                value={budget}
                minLabel={x.min}
                maxLabel={x.max}
                sliderId="budget"
                step={x.step}
                type="number"
                inputLabelLeft={x.name}
              />
            </ButtonBlock>
            <p>You can type or slide your answer</p>

            <ButtonWrapper>
              {!budget ? (
                <Button disabled onClick={() => nextStep()} label="Next" />
              ) : (
                <Button
                  onClick={() => {
                    nextStep();
                    setBudget(budget);
                  }}
                  label="Next"
                />
              )}
            </ButtonWrapper>
          </Delay>
        </>
      );
    }
    const x = investQuestion.step_5.options[0].sliders[0];
    const background = investQuestion.step_5.background_url;
    return (
      <>
        <QuestionBlock
          backgroundImage={background}
          headerText={investQuestion.step_5.title}
        >
          <ResponsiveButtonBlock>
            <ResponsiveSliderBlock
              min={x.min}
              max={x.max}
              placeholder={budget ? budget : "XXX,XXX"}
              onChange={handleAmountInput}
              value={budget}
              minLabel={x.min}
              maxLabel={x.max}
              sliderId="budget"
              step={x.step}
              type="number"
              inputLabelLeft={x.name}
            />
          </ResponsiveButtonBlock>
          <p>You can type or slide your answer</p>

          <ButtonWrapper>
            {!budget ? (
              <Button disabled onClick={() => nextStep()} label="Next" />
            ) : (
              <Button
                onClick={() => {
                  nextStep();
                  setBudget(budget);
                }}
                label="Next"
              />
            )}
          </ButtonWrapper>
        </QuestionBlock>
      </>
    );
  }
};
const Step6 = ({ route, animation, investQuestion }) => {
  const { handleStep, previousStep, nextStep } = useWizard();

  const {
    setMinCapRate,
    minCapRate,
    minROI,
    setMinROI,
    minCash,
    setMinCash,
    minARV,
    setMinARV,
    minProfit,
    setMinProfit,
    setMinRent,
    minRent,
  } = useContext(InvestContext);

  const handleminCapRate = (event) => {
    setMinCapRate(event.target.value);
  };
  const handleminCash = (event) => {
    setMinCash(event.target.value);
  };
  const handleminARV = (event) => {
    setMinARV(event.target.value);
  };
  const handleminROI = (event) => {
    setMinROI(event.target.value);
  };

  const handleminProfit = (event) => {
    setMinProfit(event.target.value);
  };
  const handleminRent = (event) => {
    setMinRent(event.target.value);
  };

  if (investQuestion) {
    if (animation) {
      const a = investQuestion.step_6.options[0];
      const b = investQuestion.step_6.options[1];
      const c = investQuestion.step_6.options[2];
      const d = investQuestion.step_6.options[3];
      const e = investQuestion.step_6.options[4];
      const f = investQuestion.step_6.options[5];

      return (
        <>
          <Delay delay="0">
            <QuestionHeader style={{ transform: "translateY(-60px)" }}>
              {investQuestion.step_6.title}
            </QuestionHeader>
            <InputButtonBlock>
              <MultipleInputs
                min={a.min}
                max={a.max}
                placeholder={minCapRate ? minCapRate : "0"}
                onChange={handleminCapRate}
                value={minCapRate}
                minLabel={a.minLabel}
                maxLabel={a.maxLabel}
                sliderId="budget"
                step={a.step}
                type="number"
                inputLabelLeft={a.label}
                inputLabelRight="%"
              />

              <MultipleInputs
                min={b.min}
                max={b.max}
                placeholder={minCash ? minCash : "0"}
                onChange={handleminCash}
                value={minCash}
                minLabel={b.minLabel}
                maxLabel={b.maxLabel}
                sliderId="minCash"
                step={b.step}
                type="number"
                inputLabelLeft={b.label}
                inputLabelRight="%"
              />
              <MultipleInputs
                min={c.min}
                max={c.max}
                placeholder={minARV ? minARV : "0"}
                onChange={handleminARV}
                value={minARV}
                minLabel={c.minLabel}
                maxLabel={c.maxLabel}
                sliderId="minARV"
                step={c.step}
                type="number"
                inputLabelLeft={c.label}
                inputLabelRight="%"
              />
              <MultipleInputs
                min={d.min}
                max={d.max}
                placeholder={minROI ? minROI : "0"}
                onChange={handleminROI}
                value={minROI}
                minLabel={d.minLabel}
                maxLabel={d.maxLabel}
                sliderId="budget"
                step={d.step}
                type="number"
                inputLabelLeft={d.label}
                inputLabelRight="%"
              />
              <MultipleInputs
                min={e.min}
                max={e.max}
                placeholder={minProfit ? minProfit : "0"}
                onChange={handleminProfit}
                value={minProfit}
                minLabel={e.minLabel}
                maxLabel={e.maxLabel}
                sliderId="minProfit"
                step={e.step}
                type="number"
                money={true}
                inputLabelLeft={e.label}
                inputSign="$"
              />

              <MultipleInputs
                min={f.min}
                max={f.max}
                placeholder={minRent ? minRent : "0"}
                onChange={handleminRent}
                value={minRent}
                minLabel={f.minLabel}
                maxLabel={f.maxLabel}
                sliderId="minRent"
                step={f.step}
                type="number"
                inputLabelLeft={f.label}
                inputLabelRight="%"
              />
            </InputButtonBlock>

            <ButtonWrapper>
              <Button
                onClick={() => {
                  nextStep();
                }}
                label="Next"
              />
            </ButtonWrapper>
          </Delay>
        </>
      );
    }
    const background = investQuestion.step_6.background_url;
    const a = investQuestion.step_6.options[0];
    const b = investQuestion.step_6.options[1];
    const c = investQuestion.step_6.options[2];
    const d = investQuestion.step_6.options[3];
    const e = investQuestion.step_6.options[4];
    const f = investQuestion.step_6.options[5];
    return (
      <>
        <QuestionBlock
          backgroundImage={background}
          headerText={investQuestion.step_6.title}
        >
          <ResponsiveInputButtonBlock>
            <MultipleInputs
              min={a.min}
              max={a.max}
              placeholder={minCapRate ? minCapRate : "0"}
              onChange={handleminCapRate}
              value={minCapRate}
              minLabel={a.label}
              maxLabel={a.label}
              sliderId="budget"
              step={a.step}
              type="number"
              inputLabelLeft={a.label}
              inputLabelRight="%"
            />

            <MultipleInputs
              min={b.min}
              max={b.max}
              placeholder={minCash ? minCash : "0"}
              onChange={handleminCash}
              value={minCash}
              minLabel={b.minLabel}
              maxLabel={b.maxLabel}
              sliderId="minCash"
              step={b.step}
              type="number"
              inputLabelLeft={b.label}
              inputLabelRight="%"
            />
            <MultipleInputs
              min={c.min}
              max={c.max}
              placeholder={minARV ? minARV : "0"}
              onChange={handleminARV}
              value={minARV}
              minLabel={c.minLabel}
              maxLabel={c.maxLabel}
              sliderId="minARV"
              step={c.step}
              type="number"
              inputLabelLeft={c.label}
              inputLabelRight="%"
            />
            <MultipleInputs
              min={d.min}
              max={d.max}
              placeholder={minROI ? minROI : "0"}
              onChange={handleminROI}
              value={minROI}
              minLabel={d.minLabel}
              maxLabel={d.maxLabel}
              sliderId="budget"
              step={d.step}
              type="number"
              inputLabelLeft={d.label}
              inputLabelRight="%"
            />
            <MultipleInputs
              min={e.min}
              max={e.max}
              placeholder={minProfit ? minProfit : "0"}
              onChange={handleminProfit}
              value={minProfit}
              minLabel={e.minLabel}
              maxLabel={e.maxLabel}
              sliderId="minProfit"
              step={e.step}
              type="number"
              inputLabelLeft={e.label}
              inputSign="$"
            />

            <MultipleInputs
              min={f.min}
              max={f.max}
              placeholder={minRent ? minRent : "0"}
              onChange={handleminRent}
              value={minRent}
              minLabel={f.minLabel}
              maxLabel={f.maxLabel}
              sliderId="minRent"
              step={f.step}
              type="number"
              inputLabelLeft={f.label}
              inputLabelRight="%"
            />
          </ResponsiveInputButtonBlock>

          <Button
            onClick={() => {
              nextStep();
            }}
            label="Next"
          />
        </QuestionBlock>
      </>
    );
  }
};
const Step7 = ({ route, animation, investQuestion }) => {
  const { handleStep, previousStep, nextStep } = useWizard();
  const { setLocation, location } = useContext(InvestContext);

  // toggle Map
  const [showModal, setShowModal] = useState(false);
  const [map, setMap] = useState(false);
  // set Animation
  const { toggleAnimation } = useContext(AnimationContext);
  const [animationOut, setAnimationOut] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  if (investQuestion) {
    if (animation) {
      return (
        <>
          <Delay delay="0">
            <NewMap
              animationOut={animationOut}
              setAnimationOut={setAnimationOut}
              nextStep={nextStep}
              setIsVisible={setIsVisible}
              isVisible={isVisible}
              headerText={investQuestion.step_7.title}
            />
          </Delay>
        </>
      );
    }
    const background = investQuestion.step_7.background_url;
    return (
      <>
        <ResponsiveMapQuestionBox
          backgroundImage={background}
          animation={false}
        >
          <ResponsiveNewMap
            setIsVisible={setIsVisible}
            isVisible={isVisible}
            nextStep={nextStep}
            headerText={investQuestion.step_7.title}
          />
        </ResponsiveMapQuestionBox>
      </>
    );
  }
};

const Step8 = ({ route, animation, investQuestion }) => {
  const { handleStep, previousStep, nextStep } = useWizard();
  const { setMinSF, minSF, setMaxSF, maxSF } = useContext(InvestContext);
  const [value, setValue] = React.useState([0, 100000]);

  const handleAmountMaxInput = (event) => {
    setMaxSF(event.target.value);
  };
  const handleAmountMinInput = (event) => {
    setMinSF(event.target.value);
  };

  const handleMinSFChange = (event) => {
    const newValue = event.target.value.trim();
    const newMinSF = newValue === "" ? undefined : parseFloat(newValue);
    if (!Number.isNaN(newMinSF)) {
      setMinSF(newMinSF);
      setValue([newMinSF ?? 0, maxSF ?? 10000]);
    }
  };

  const handleMaxSFChange = (event) => {
    const newValue = event.target.value.trim();
    const newMaxSF = newValue === "" ? undefined : parseFloat(newValue);
    if (!Number.isNaN(newMaxSF)) {
      setMaxSF(newMaxSF);
      setValue([minSF ?? 0, newMaxSF ?? 10000]);
    }
  };

  const handleSliderChange = (event, newValue) => {
    const newMinSF = newValue[0] ?? 0;
    const newMaxSF = newValue[1] ?? 10000;
    setMinSF(newMinSF);
    setMaxSF(newMaxSF);
    setValue([newMinSF, newMaxSF]);
  };

  const marks = [
    {
      value: 0,
      label: "0 SQ. FT",
    },
    {
      value: 10000,
      label: "10,000 SQ. FT ",
    },
  ];
  const minDistance = 1;

  if (investQuestion) {
    if (animation) {
      return (
        <>
          <Delay delay="0">
            <QuestionHeader style={{ transform: "translateY(-50px)" }}>
              {investQuestion.step_8.title}
            </QuestionHeader>
            <ButtonBlock>
              <SliderWrapper>
                <StlyedSliderInput>
                  Min SF
                  <StyledNumber
                    value={minSF}
                    margin="dense"
                    onChange={handleMinSFChange}
                    inputProps={{
                      step: 1,
                      min: 0,
                      max: 10000,
                      type: "number",
                      "aria-labelledby": "range-slider",
                    }}
                  />
                </StlyedSliderInput>
                <StlyedSliderInput>
                  Max SF
                  <StyledNumber
                    value={maxSF}
                    margin="dense"
                    onChange={handleMaxSFChange}
                    inputProps={{
                      step: 1,
                      min: 0,
                      max: 10000,
                      type: "number",
                      "aria-labelledby": "range-slider",
                    }}
                  />
                </StlyedSliderInput>
                <StyledSlider
                  value={[minSF, maxSF]}
                  onChange={handleSliderChange}
                  aria-labelledby="range-slider"
                  min={0}
                  max={10000}
                  step={10}
                  marks={marks}
                  disableSwap
                />
              </SliderWrapper>
            </ButtonBlock>
            <p>You can type or slide your answer</p>

            {!maxSF ? (
              <Button disabled onClick={() => nextStep()} label="Next" />
            ) : (
              <Button
                onClick={() => {
                  nextStep();
                }}
                label="Next"
              />
            )}
            <div
              className="skip-question"
              onClick={() => {
                nextStep();
              }}
            >
              Skip this Question
            </div>
          </Delay>
        </>
      );
    }
    const background = investQuestion.step_8.background_url;

    return (
      <>
        <QuestionBlock
          backgroundImage={background}
          headerText={investQuestion.step_8.title}
        >
          <ResponsiveButtonBlock>
            <SliderWrapper>
              <StlyedSliderInput>
                Min SF
                <StyledNumber
                  value={minSF}
                  margin="dense"
                  onChange={handleMinSFChange}
                  inputProps={{
                    step: 10,
                    min: 0,
                    max: 10000,
                    type: "number",
                    "aria-labelledby": "range-slider",
                  }}
                />
              </StlyedSliderInput>
              <StlyedSliderInput>
                Max SF
                <StyledNumber
                  value={maxSF}
                  margin="dense"
                  onChange={handleMaxSFChange}
                  inputProps={{
                    step: 10,
                    min: 0,
                    max: 10000,
                    type: "number",
                    "aria-labelledby": "range-slider",
                  }}
                />
              </StlyedSliderInput>
              <StyledSlider
                value={[minSF, maxSF]}
                onChange={handleSliderChange}
                aria-labelledby="range-slider"
                min={0}
                max={10000}
                step={10}
                marks={marks}
                disableSwap
              />
            </SliderWrapper>
          </ResponsiveButtonBlock>
          <p>You can type or slide your answer</p>

          {!maxSF ? (
            <Button disabled onClick={() => nextStep()} label="Next" />
          ) : (
            <Button
              onClick={() => {
                nextStep();
              }}
              label="Next"
            />
          )}
          <div
            className="skip-question"
            onClick={() => {
              nextStep();
            }}
          >
            Skip this Question
          </div>
        </QuestionBlock>
      </>
    );
  }
};

const Step9 = ({ route, animation, investQuestion }) => {
  const { handleStep, previousStep, nextStep } = useWizard();
  const [minYearSelected, setMinYearSelected] = useState("");
  const [maxYearSelected, setMaxYearSelected] = useState("");

  const { setMinYear, minYear } = useContext(InvestContext);
  const { setMaxYear, maxYear } = useContext(InvestContext);

  const [value, setValue] = React.useState([1900, 2023]);

  const handleMinYearChange = (event) => {
    setMinYear(event.target.value === "" ? "" : Number(event.target.value));
  };

  const handleMaxYearChange = (event) => {
    setMaxYear(event.target.value === "" ? "" : Number(event.target.value));
  };

  const handleSliderChange = (event, newValue) => {
    setMinYear(newValue[0]);
    setMaxYear(newValue[1]);
  };

  const marks = [
    {
      value: 1900,
      label: "1900",
    },
    {
      value: 2023,
      label: "2023",
    },
  ];
  const minDistance = 1;

  if (investQuestion) {
    if (animation) {
      const min = investQuestion.step_9.options[0].sliders[0];
      const max = investQuestion.step_9.options[0].sliders[1];
      return (
        <>
          <Delay delay="0">
            <QuestionHeader style={{ transform: "translateY(-50px)" }}>
              {investQuestion.step_9.title}
            </QuestionHeader>
            <ButtonBlock>
              <SliderWrapper>
                <StlyedSliderInput>
                  Min Year
                  <StyledNumber
                    value={minYear}
                    margin="dense"
                    onChange={handleMinYearChange}
                    inputProps={{
                      step: 1,
                      min: 1900,
                      max: 2023,
                      type: "number",
                      "aria-labelledby": "range-slider",
                    }}
                  />
                </StlyedSliderInput>
                <StlyedSliderInput>
                  Max Year
                  <StyledNumber
                    value={maxYear}
                    margin="dense"
                    onChange={handleMaxYearChange}
                    inputProps={{
                      step: 1,
                      min: 1900,
                      max: 2023,
                      type: "number",
                      "aria-labelledby": "range-slider",
                    }}
                  />
                </StlyedSliderInput>
                <StyledSlider
                  value={[minYear, maxYear]}
                  onChange={handleSliderChange}
                  aria-labelledby="range-slider"
                  min={1900}
                  max={2023}
                  step={1}
                  marks={marks}
                  disableSwap
                />
              </SliderWrapper>
            </ButtonBlock>
            <p>You can type or slide your answer</p>
            {!maxYear ? (
              <Button disabled onClick={() => nextStep()} label="Next" />
            ) : (
              <Button
                onClick={() => {
                  nextStep();
                }}
                label="Next"
              />
            )}
            <div
              className="skip-question"
              onClick={() => {
                nextStep();
              }}
            >
              Skip this Question
            </div>
          </Delay>
        </>
      );
    }
    const background = investQuestion.step_9.background_url;
    const min = investQuestion.step_9.options[0].sliders[0];
    const max = investQuestion.step_9.options[0].sliders[1];

    return (
      <>
        <QuestionBlock
          backgroundImage={background}
          headerText={investQuestion.step_9.title}
        >
          <ResponsiveButtonBlock>
            <SliderWrapper>
              <StlyedSliderInput>
                Min Year
                <StyledNumber
                  value={minYear}
                  margin="dense"
                  onChange={handleMinYearChange}
                  inputProps={{
                    step: 1,
                    min: 1900,
                    max: 2023,
                    type: "number",
                    "aria-labelledby": "range-slider",
                  }}
                />
              </StlyedSliderInput>
              <StlyedSliderInput>
                Max Year
                <StyledNumber
                  value={maxYear}
                  margin="dense"
                  onChange={handleMaxYearChange}
                  inputProps={{
                    step: 1,
                    min: 1900,
                    max: 2023,
                    type: "number",
                    "aria-labelledby": "range-slider",
                  }}
                />
              </StlyedSliderInput>
              <StyledSlider
                value={[minYear, maxYear]}
                onChange={handleSliderChange}
                aria-labelledby="range-slider"
                min={1900}
                max={2023}
                step={1}
                marks={marks}
                disableSwap
              />
            </SliderWrapper>
          </ResponsiveButtonBlock>
          <p>You can type or slide your answer</p>

          {!maxYear ? (
            <Button disabled onClick={() => nextStep()} label="Next" />
          ) : (
            <Button
              onClick={() => {
                nextStep();
              }}
              label="Next"
            />
          )}
          <div
            className="skip-question"
            onClick={() => {
              nextStep();
            }}
          >
            Skip this Question
          </div>
        </QuestionBlock>
      </>
    );
  }
};
const Step10 = ({ route, animation, investQuestion }) => {
  const { handleStep, previousStep, nextStep } = useWizard();
  // checkbox
  const [checked, setChecked] = useState(true);
  const checkState = () => {
    setChecked(true);
  };
  // Multi Select
  const [selected, setSelected] = useState([]);
  const handleOnClick = (e) => {
    const item = e.target.value;
    var index = selected.indexOf(item);
    const newList = selected;
    const filtered = newList.filter((select) => select !== item);
    if (index == -1) {
      setSelected([...selected, item]);
    } else {
      setSelected([...filtered]);
    }
  };
  // Other Input
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const handleOther = (e) => {
    setInputVisible(!inputVisible);
  };

  const handleInputValue = (e) => {
    var index = selected.indexOf(inputValue);
    const newList = selected;
    const filtered = newList.filter((select) => select !== inputValue);

    if (index == -1) {
      setSelected([...selected, inputValue]);
    } else {
      setSelected([...filtered]);
    }
  };

  const { setAvoid } = useContext(InvestContext);

  if (investQuestion) {
    if (animation) {
      return (
        <>
          <Delay delay="0">
            <QuestionHeader style={{ transform: "translateY(-60px)" }}>
              {investQuestion.step_10.title}
            </QuestionHeader>
            <SelectButtonBlock>
              {investQuestion.step_10.options.map((x, i) => {
                return (
                  <React.Fragment key={x.id}>
                    <SelectRadioButton
                      id={x.id}
                      icon={x.icon}
                      icon_text={x.icon_text}
                      onClick={(e) => handleOnClick(e)}
                      label={x.label}
                      value={x.label}
                    />
                  </React.Fragment>
                );
              })}
              <OtherInput
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onBlur={handleInputValue}
                placeholder="Other - Type Here"
                type="text"
              />
            </SelectButtonBlock>

            <Button
              onClick={() => {
                nextStep();
                setAvoid(selected);
              }}
              label="Next"
            />
          </Delay>
        </>
      );
    }
    const background = investQuestion.step_10.background_url;
    return (
      <>
        <QuestionBlock
          backgroundImage={background}
          headerText={investQuestion.step_10.title}
        >
          <ResponsiveButtonBlock>
            {investQuestion.step_10.options.map((x, i) => {
              return (
                <React.Fragment key={x.id}>
                  <ResponsiveSelectRadioButton
                    id={x.id}
                    icon={x.icon}
                    icon_text={x.icon_text}
                    onClick={(e) => handleOnClick(e)}
                    label={x.label}
                    value={x.label}
                  />
                </React.Fragment>
              );
            })}
            <div>
              <ResponsiveOtherInput
                onChange={(e) => setInputValue(e.target.value)}
                onBlur={handleInputValue}
                placeholder="Other - Type Here"
                type="text"
                value={inputValue}
              />
            </div>
          </ResponsiveButtonBlock>

          <Button
            onClick={() => {
              nextStep();
              setAvoid(selected);
            }}
            label="Next"
          />
        </QuestionBlock>
      </>
    );
  }
};
const Step11 = ({ route, animation, investQuestion }) => {
  const { handleStep, previousStep, nextStep } = useWizard();
  const {
    propertyType,
    bedrooms,
    bathrooms,
    minAcreage,
    maxAcreage,
    criteria,
    budget,
    minCapRate,
    minCash,
    minARV,
    minROI,
    minProfit,
    minRent,
    minSF,
    maxSF,
    minYear,
    maxYear,
  } = useContext(InvestContext);
  const { loader } = useContext(AnimationContext);
  let history = useHistory();

  const { address, boundaries } = useContext(MapContext);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [formattedPhone, setFormattedPhone] = useState("");
  const [phone, setPhone] = useState("");

  const isButtonDisabled = () => {
    return !(
      name &&
      (email || (formattedPhone && isValidPhoneNumber(formattedPhone)))
    );
  };

  const isValidPhoneNumber = (phone) => {
    // A full phone number typically has 10 digits in it
    return phone.length >= 10;
  };

  function removeNonNumeric(phoneNumber) {
    // Using regular expression to match any non-numeric characters and replace them with an empty string
    return phoneNumber.replace(/\D/g, "");
  }

  const handlePhoneChange = (event) => {
    const stringPhone = removeNonNumeric(event.target.value);
    setFormattedPhone(stringPhone);
  };

  const addInvest = async (event) => {
    event.preventDefault();

    try {
      const docRef = await addDoc(collection(firestore, "invest_inquiries"), {
        email: email,
        name: name,
        phone_number: formattedPhone,
        propertyType: propertyType,
        bedrooms: bedrooms,
        bathrooms: bathrooms,
        minAcreage: minAcreage,
        maxAcreage: maxAcreage,
        criteria: criteria,
        budget: budget,
        minCapRate: minCapRate,
        minCash: minCash,
        minARV: minARV,
        minROI: minROI,
        minProfit: minProfit,
        minRent: minRent,
        minSF: minSF,
        maxSF: maxSF,
        minYear: minYear,
        maxYear: maxYear,
        location: address,
        boundaries: boundaries,
        created_at: Date.now(),
        boundaries: JSON.stringify(boundaries),

        status: "Submitted",
      });
      console.log("Document written with ID: ", docRef.id);
      history.push("/success");
    } catch (err) {
      console.error("Error adding document: ", err.message);
    }
  };

  const background =
    "https://firebasestorage.googleapis.com/v0/b/thehousehawk-b7691.appspot.com/o/public%2Fbackgrounds%2Fpublic%2Fbackgrounds%2Fresized%2Ffinal_bg_1920x1080.webp?alt=media&token=d3dcd4aa-fcd8-4bb5-a333-9dfdefea3d07";

  if (loader) {
    return <ResultsLoader delay="4800" />;
  }
  if (!loader) {
    return (
      <>
        <ResultsLoader delay="4800" />
        <FinalQuestionBlock backgroundImage={background}>
          <p>
            Enter your information to see your results and get real-time updates
            delivered right to your inbox.
          </p>
          <form onSubmit={addInvest} className="final-form-buy">
            <Label htmlFor="name">
              <span className="image_space">
                <PersonIcon />
              </span>
              <Input
                placeholder="Name"
                type="name"
                id="name"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Label>
            <Label htmlFor="Email">
              <span className="image_space">
                <EmailIcon />
              </span>
              <Input
                placeholder="Email"
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Label>
            <p
              style={{
                textAlign: "center",
                marginBottom: 10,
              }}
            >
              And/Or
            </p>
            <Label htmlFor="phone">
              <span className="image_space">
                <LocalPhoneIcon />
              </span>
              <PatternFormat
                placeholder="Phone"
                type="phone"
                id="phone"
                name="phone"
                value={phone}
                onChange={handlePhoneChange}
                format="(###)-###-####"
                mask="_"
              />

              {/* <Input
                placeholder="Phone"
                type="phone"
                id="phone"
                name="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              /> */}
            </Label>
            <Button
              disabled={isButtonDisabled()}
              type="submit"
              label="See My Matches"
              btnClass="submit-button"
            />
            <FooterText>
              You agree to The House Hawk LLC's Terms of Use & Privacy Policy
              and to be contacted by us or third parties. By registering, you
              give us your express written consent to deliver automated emails
              to you. Consent is not a condition of purchase. Your registration
              acts as your binding electronic signature.
            </FooterText>
          </form>
        </FinalQuestionBlock>
      </>
    );
  }
};

export default Invest;
const ButtonWrapper = styled.div`
  display: block;
  margin: 0 auto;
`;
const QuestionHeader = styled.div`
  text-align: center;
  font-size: 1.8rem;
  margin: 0 auto;
  width: 420px;
  font-weight: bold;
  text-shadow: 2px 2px #000;
`;
const Seperator = styled.div`
  width: 100%;
  padding-top: 4px;
  padding-bottom: 4px;
`;
const Content = styled.div`
  font-size: 16px;
  margin: 0px auto;
  margin-top: 16px;
  margin-bottom: 16px;
`;
const SliderWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

const StyledNumber = styled(Input)`
  width: 60%;
  margin: 0px auto;
  text-align: center;
  letter-spacing: 5.6px;
  border-radius: 8px;
  border: 2px solid black;
  height: 18px;
  background: #fdcae1;
  padding: 5px;
  @media () &::placeholder {
    color: #f9398f;
  }
`;
const StlyedSliderInput = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 240px;
  margin: 10px;
  background: #fdcae1;
  color: #f9398f;
  letter-spacing: 2px;
  border-radius: 25px;
  border: 2px solid #adcae6;
  margin-bottom: 10px;
`;

const StyledSliderStep3 = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 80%;
  margin: 10px;
  background: #fdcae1;
  color: #f9398f;
  letter-spacing: 2px;
  border-radius: 25px;
  border: 2px solid #adcae6;
`;

const StyledSlider = styled(Slider)`
  touch-action: pan-x !important;
  max-width: 80%;
  padding-left: 10px;
  .MuiSlider-thumb {
    height: 30px;
    width: 30px;
    pointer-events: none !important;
  }
`;

const FooterText = styled.p`
  font-size: 14px;
`;
