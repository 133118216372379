import React, { useEffect, useState, useContext } from "react";
import { QuestionBlock, ResponsiveButtonBlock } from ".";
import AnimationContext from "../../../context/AnimationContext";
import { PulseLoader } from "react-spinners";

const ResultsLoader = (props) => {
  const { loader, setLoader } = useContext(AnimationContext);

  const [visible, setVisible] = useState(true);
  console.log("visble", visible);
  useEffect(() => {
    setTimeout(() => {
      setVisible(false);
      setLoader(false);
    }, props.delay);
  }, [props.delay, visible, setLoader]);

  return visible ? (
    <QuestionBlock>
      <ResponsiveButtonBlock>
        <div className="Question__Content">
          <h1>Thank you!</h1>
          <h3>Your matches are on the way.</h3>
          <PulseLoader color="#0052FF90" size={24} />
        </div>
      </ResponsiveButtonBlock>
    </QuestionBlock>
  ) : (
    <div />
  );
};

export default ResultsLoader;
