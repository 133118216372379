import React, { useContext } from "react";
import styled, { css } from "styled-components";
import AnimationContext from "../../../context/AnimationContext";
import { device } from "../../../utils/mediaQueries";
import ResizeIcon from "../../../assets/resize.png";
import { transformation } from "leaflet";
const AnimationQuestionBlock = ({ headerText, animation, children }) => {
  const { route, animationOut, windowWidth, setWindowWidth } =
    useContext(AnimationContext);

  return (
    <>
      <QuestionBlockWrapper route={route} animationOut={animationOut}>
        <StyledQuestionBlock animation={animation}>
          <QuestionHeader>
            <p>{headerText} </p>
          </QuestionHeader>
          <Content animation={animation}>{children}</Content>
        </StyledQuestionBlock>
      </QuestionBlockWrapper>
    </>
  );
};

export default AnimationQuestionBlock;

const QuestionBlockWrapper = styled.div`
  background-position: center;
  animation-duration: 1s;
  //  animation-name: zoomIn;
  transition: opacity 2s ease-in-out;

  @media (max-width: 1280px) {
    width: 1280px;
    height: 800px;
  }

  @media (max-width: 3440px) {
    height: 100vh;
    background-repeat: no-repeat;
  }

  ${(props) =>
    props.animation === true ||
    (props.route === "isRent" &&
      css`
        background-image: url("https://firebasestorage.googleapis.com/v0/b/thehousehawk-b7691.appspot.com/o/public%2Fanimations%2Frent_bg_ultra_37a16bf5db.png?alt=media&token=21a8b234-380e-4c5a-bf9b-e1ae01e66d09");
      `)}
  ${(props) =>
    props.animation === true ||
    (props.route === "isBuy" &&
      css`
        background-image: url("https://firebasestorage.googleapis.com/v0/b/thehousehawk-b7691.appspot.com/o/public%2Fanimations%2Fbuy_bg_ultra_2f8df96589.png?alt=media&token=3a8a0c2c-6ce3-4571-bc51-6afdb005fc6c");
      `)}
  ${(props) =>
    props.animation === true ||
    (props.route === "isSell" &&
      css`
        background-image: url("https://firebasestorage.googleapis.com/v0/b/thehousehawk-b7691.appspot.com/o/public%2Fanimations%2Fsell_bg_ultra_4f1634b711.png?alt=media&token=7c360401-53a1-458d-a0d6-d4b345b4a6e3");
      `)}
        ${(props) =>
    props.animation === true ||
    (props.route === "isInvest" &&
      css`
        background-image: url("https://firebasestorage.googleapis.com/v0/b/thehousehawk-b7691.appspot.com/o/public%2Fanimations%2Finvest_bg_ultra_79b3b75c14.png?alt=media&token=46940fda-2c6b-4e22-8ce1-fd3f6a06ef6e");
      `)}
`;
const QuestionHeader = styled.div`
  text-align: center;
  font-size: 1.6rem;
  margin: 0 auto;
  width: 360px;
  transform: translateY(-50px);
`;
const StyledQuestionBlock = styled.div`
  @media (max-width: 1280px) {
    width: 540px;
    display: block;
    justify-content: center;
    align-items: center;
    color: white;
    position: fixed;
    top: 50%;
    left: 50%;
    margin-top: 50px;
    margin-left: -255px;
  }
  @media (max-width: 3440px) {
    width: 540px;
    display: block;
    justify-content: center;
    align-items: center;
    color: white;
    position: fixed;
    top: 50%;
    left: 50%;
    margin-top: 50px;
    margin-left: -280px;
  }
  // @media (min-width: 1920px) {
  //   display: block;
  //   justify-content: center;
  //   align-items: center;
  //   color: white;
  //   width: 640px;
  //   position: fixed;
  //   top: 50%;
  //   left: 50%;
  //   margin-top: 50px;
  //   margin-left: -300px;
  // }
`;

const Content = styled.div`
  @media (max-width: 1440px) {
    height: 260px;
    display: flex;
    flex-direction: column;
    width: 540px;
    justify-content: center;
    border: 0px;
    align-items: center;
    text-align: center;
    margin: 0 auto;
    font-size: 1.6rem;
  }

  @media (max-width: 3440px) {
    height: 300px;
    display: flex;
    flex-direction: column;
    width: 600px;
    justify-content: center;
    border: 0px;
    align-items: center;
    text-align: center;
    margin: 0 auto;
    font-size: 1.6rem;
  }
`;
