import React, { useContext, useState } from "react";

import { Route, Switch } from "react-router";
import "./App.css";
import { Header } from "./components/common";
import { Footer } from "./components/common";
import { Landing, Rent, Buy, Invest, Sell, About } from "./components/pages";

import { AnimationProvider } from "./context/AnimationContext";
import { RentProvider } from "./context/RentContext";
import { BuyProvider } from "./context/BuyContext.jsx";
import { InvestProvider } from "./context/InvestContext";
import { SellProvider } from "./context/SellContext";
import { NewMap, PrintMap } from "./components/common/types";
import { MapProvider } from "./context/MapContext";
import { RentInquiries } from "./components/pages/Rent";
import { SellInquiries } from "./components/pages/Sell";
import { UserProvider } from "./context/UserContext";
import { TheSun } from "./components/common";
import { UserContext } from "./context/UserContext";
import AnimationContext from "./context/AnimationContext";
import SuccessPage from "./components/pages/SuccessPage";
import SuccessPageSell from "./components/pages/SuccessPageSell";
// theme
import ThemeProvider from "./theme";

function App() {
  return (
    <div className="App">
      <UserProvider>
        <MapProvider>
          <SellProvider>
            <BuyProvider>
              <InvestProvider>
                <RentProvider>
                  <AnimationProvider>
                    <Switch>
                      <Route exact path="/">
                        <Header />
                        <Landing />
                        <Footer />
                      </Route>
                      <Route path="/NewMap">
                        <NewMap />
                      </Route>
                      <Route path="/success">
                        <SuccessPage />
                      </Route>
                      <Route path="/success-sell">
                        <SuccessPageSell />
                      </Route>
                      <Route exact path="/Rent/">
                        <Rent />
                      </Route>
                      <Route exact path="/Buy/" route="isBuy">
                        <Buy />
                      </Route>

                      <Route exact path="/Invest/">
                        <Invest />
                      </Route>
                      <Route exact path="/Sell/">
                        <Sell />
                      </Route>

                      <Route path="/PrintMap/">
                        <PrintMap />
                      </Route>

                      <ThemeProvider>
                        <Route path="/About">
                          <Header />
                          <About />
                          <Footer />
                        </Route>
                      </ThemeProvider>
                    </Switch>
                  </AnimationProvider>
                </RentProvider>
              </InvestProvider>
            </BuyProvider>
          </SellProvider>
        </MapProvider>
      </UserProvider>
    </div>
  );
}

export default App;
