import React, { createContext, useState } from "react";

const SellContext = createContext();

export const SellProvider = (props) => {
  // STep 1
  const [propertyAddress, setPropertyAddress] = useState("");
  // Step 2
  const [worthMin, setWorthMin] = useState("");
  const [worthMax, setWorthMax] = useState("");
  // Step 3
  const [condition, setCondition] = useState("");
  // Step 4
  const [minRepair, setMinRepair] = useState("");
  const [maxRepair, setMaxRepair] = useState("");

  // Step 5
  const [priority, setPriority] = useState("");
  // Step 11
  const [deed, setDeed] = useState("");

  const sellData = {
    propertyAddress,
    worthMin,
    worthMax,
    condition,
    minRepair,
    maxRepair,
    priority,
    sellDocId,
  };

  const [sellDocId, setSellDocId] = useState("");
  return (
    <SellContext.Provider
      value={{
        sellDocId,
        setSellDocId,
        worthMin,
        setWorthMin,
        worthMax,
        setWorthMax,
        condition,
        setCondition,
        propertyAddress,
        setPropertyAddress,
        minRepair,
        setMinRepair,
        maxRepair,
        setMaxRepair,
        priority,
        setPriority,
        sellData,
      }}
    >
      {props.children}
    </SellContext.Provider>
  );
};

export default SellContext;
