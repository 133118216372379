import React, { useEffect, useState, useContext } from "react";
import styled, { css, keyframes } from "styled-components";
import { Label } from ".";
import { device } from "../../../utils/mediaQueries";
import AnimationContext from "../../../context/AnimationContext";

const ResponsiveSelectRadioButton = ({
  onClick,
  id,
  icon,
  icon_text,
  label,
  value,
  inpClass,
  lblClass,
  iconClass,
  outerClass,
  span,
  animationIcon,
  part,
  popOut,
  isOther,
  isEmpty,
}) => {
  const { route } = useContext(AnimationContext);

  console.log("RADIO", route);
  return (
    <>
      <StyledRadioButton>
        <StyledInput
          onClick={onClick}
          type="checkbox"
          id={id}
          value={value}
          name="check"
          route={route}
        />

        <StyledRadioLabel isEmpty={isEmpty} route={route} htmlFor={id}>
          <StyledIcon>
            {icon === null ? (
              <StyledSpanNoLogo
                isEmpty={isEmpty}
                route={route}
                className={span}
              >
                {icon_text}
              </StyledSpanNoLogo>
            ) : (
              <StyledSpan icon={icon} route={route}>
                <img
                  class="icon"
                  alt="icon"
                  src={
                    icon
                      ? icon
                      : "https://househawkbucket.s3.amazonaws.com/other_956418866f.png"
                  }
                />
              </StyledSpan>
            )}
            {isOther && (
              <StyledSpan route={route} className={part}>
                <img
                  alt="icon"
                  src="https://househawkbucket.s3.amazonaws.com/other_956418866f.png"
                />
              </StyledSpan>
            )}
            <p> {label}</p>
          </StyledIcon>
        </StyledRadioLabel>
      </StyledRadioButton>
    </>
  );
};

export default ResponsiveSelectRadioButton;

const StyledRadioButton = styled.div`
  @media ${device.phone} {
    width: 29rem;
    margin: 6px;
  }
  @media ${device.xs} {
    width: 250px;
    margin: 6px;
  }
`;
const bounce = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.08);
  }
  100% {
    transform: scale(1);
  }

`;
const StyledRadioLabel = styled.label`
  z-index: -1;

  @media ${device.phone} {
    justify-content: center;
    text-align: center;
    display: flex;
    align-items: center;
    background: #ffffff;
    color: black;
    border-radius: 5px;
    padding: 7px 10px;
    border: 2px solid #ffffff;
    cursor: pointer;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease-in-out;
    font-size: 16 px;
    font-weight: 400;
  }

  ${(props) =>
    props.isEmpty === true &&
    css`
      background-color: transparent;
      border: none;
      box-shadow: none;
      font-size: 16px;
      margin-left: -25px;
    `}
`;

const StyledInput = styled.input`
  display: none;
  position: absolute;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  &:checked + ${StyledRadioLabel} {
    -webkit-animation: ${bounce} 0.5s alternate;
    -moz-animation: ${bounce} 0.5s alternate;
    animation: ${bounce} 0.5s alternate;
    background: #ffffff;

    ${(props) =>
      props.route === "isBuy" &&
      css`
        border-color: #0052ff;
        background-color: #5bbaff;
        color: white;
      `}

    ${(props) =>
      props.route === "isRent" &&
      css`
        border-color: #fdcf00;
      `}
      ${(props) =>
      props.route === "isSell" &&
      css`
        border-color: #ff0800;
        background-color: #ff9c99;
        color: white;
      `}
        ${(props) =>
      props.route === "isInvest" &&
      css`
        border-color: #00b050;
        background-color: #55d668;
        color: white;
      `}
  }
`;
const StyledIcon = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  ${(props) =>
    props.route === "isBuy" &&
    css`
      color: #0052ff;
    `}
  ${(props) =>
    props.route === "isRent" &&
    css`
      color: #fdcf00;
    `}
    ${(props) =>
    props.route === "isSell" &&
    css`
      color: #ff0800;
    `}
      ${(props) =>
    props.route === "isInvest" &&
    css`
      color: #00b050;
    `}
  p {
    margin-left: 16px;
  }
`;
const StyledSpan = styled.span`
  width: 28px;
  height: 28px;
  font-size: 16px;
  text-align: center;
  line-height: 24px;
  font-weight: bold;

  img {
    height: 24px;
    width: 24px;
    padding: 0;
    border-radius: 0;
    background: none;

    ${(props) => props.route === "isBuy" && css``}
    ${(props) => props.route === "isRent" && css``}
      ${(props) => props.route === "isSell" && css``}
        ${(props) => props.route === "isInvest" && css``}
  }
`;
const StyledSpanNoLogo = styled(StyledSpan)`
  border-radius: 50%;
  ${(props) =>
    props.isEmpty === true &&
    css`
      display: none;
    `}
  ${(props) =>
    props.route === "isBuy" &&
    css`
      background: #0052ff;
    `}
      ${(props) =>
    props.route === "isRent" &&
    css`
      background: #fdcf00;
    `}
      ${(props) =>
    props.route === "isSell" &&
    css`
      background: #ff0800;
    `}
        ${(props) =>
    props.route === "isInvest" &&
    css`
      color: white;

      background: #00b050;
    `}
`;
