import React, { createContext, useState, useEffect } from "react";

const MapContext = createContext()

export const MapProvider = (props) => {

    const [location, setLocation] = useState('')
    const [boundaries, setBoundaries] = useState([]);
    const [address, setAddress] = useState('')
    const [latlng, setLatLng] = useState([]);
    const [autoLocation, setAutoLocation] = useState([])

    return (
        <MapContext.Provider value={{ autoLocation, setAutoLocation, latlng, setLatLng, address, setAddress, boundaries, setBoundaries, setLocation, location }}>
            {props.children}
        </MapContext.Provider>
    )
}

export default MapContext