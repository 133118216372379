import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ListItem, Link } from "@mui/material";
import { db, firestore } from "../../utils/firebase";
import { onValue, ref } from "firebase/database";
import "./style.css"; // Import the CSS file
export default function AccordionAboutUs() {
  const [aboutQuestionsData, setAboutQuestionsData] = React.useState("");
  React.useEffect(() => {
    const query = ref(db, "about_us_questions");
    return onValue(query, (snapshot) => {
      const data = snapshot.val();
      setAboutQuestionsData(data);
      console.log(data);
    });
  }, []);
  const dataListOne = [
    {
      id: 1,
      title: "Is HouseHawk.com free?",
      content:
        "Buyers, Sellers, Investors, Renters… The Answer is YES! Our Partner Agents have dues that must be paid that cover our operations.",
    },
    {
      id: 2,
      title: "How does TheHouseHawk.com work?",
      content:
        "To put it simply, we partner you with a Designated HouseHawk Agent in your area that utilizes our technologies made available to them to ensure both your success and your agent's success in achieving your Real Esatate Goals. We strive to make sure the Agent you work with has the very best tools in the industry to be successful and create a win-win for everybody.",
    },
    {
      id: 3,
      title: "Will my information remain confidential?",
      content:
        "Your information as a consumer will only be shared with our Partner Agent. We will not shop it around or sell it.",
    },
    {
      id: 4,
      title: "Is there any commitment to use TheHouseHawk.com Services?",
      content:
        "The only commitment we ask is that you tell your friends about us if you have a great experience and tell us if you don’t so we can fix it.",
    },
  ];
  if (aboutQuestionsData) {
    return (
      <>
        {/* FAQ 1 */}
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="house-first"
          >
            <Typography variant="h3">
              {aboutQuestionsData.question_1.title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div
              class="content"
              dangerouslySetInnerHTML={{
                __html: aboutQuestionsData.question_1.content,
              }}
            />
          </AccordionDetails>
        </Accordion>

        {/* FAQ 2*/}
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="house-first"
          >
            <Typography variant="h3">
              {aboutQuestionsData.question_2.title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div
              class="content"
              dangerouslySetInnerHTML={{
                __html: aboutQuestionsData.question_2.content,
              }}
            />
          </AccordionDetails>
        </Accordion>

        {/* FAQ 3*/}
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="house-first"
          >
            <Typography variant="h3">
              {aboutQuestionsData.question_3.title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div
              class="content"
              dangerouslySetInnerHTML={{
                __html: aboutQuestionsData.question_3.content,
              }}
            />
          </AccordionDetails>
        </Accordion>

        {/* FAQ 4*/}
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="house-first"
          >
            <Typography variant="h3">
              {aboutQuestionsData.question_4.title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div
              class="content"
              dangerouslySetInnerHTML={{
                __html: aboutQuestionsData.question_4.content,
              }}
            />
          </AccordionDetails>
        </Accordion>

        {/* FAQ 5*/}
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="house-first"
          >
            <Typography variant="h3">
              {aboutQuestionsData.question_6.title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div
              class="content"
              dangerouslySetInnerHTML={{
                __html: aboutQuestionsData.question_6.content,
              }}
            />
          </AccordionDetails>
        </Accordion>

        {/* FAQ 6*/}
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="house-first"
          >
            <Typography variant="h3">
              {aboutQuestionsData.question_6.title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div
              class="content"
              dangerouslySetInnerHTML={{
                __html: aboutQuestionsData.question_6.content,
              }}
            />
          </AccordionDetails>
        </Accordion>

        {/* FAQ 7*/}
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="house-first"
          >
            <Typography variant="h3">
              {aboutQuestionsData.question_7.title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div
              class="content"
              dangerouslySetInnerHTML={{
                __html: aboutQuestionsData.question_7.content,
              }}
            />
          </AccordionDetails>
        </Accordion>

        {/* FAQ 8*/}
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="house-first"
          >
            <Typography variant="h3">
              {aboutQuestionsData.question_8.title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div
              class="content"
              dangerouslySetInnerHTML={{
                __html: aboutQuestionsData.question_8.content,
              }}
            />
          </AccordionDetails>
        </Accordion>

        {/* FAQ 9*/}
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="house-first"
          >
            <Typography variant="h3">
              {aboutQuestionsData.question_9.title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div
              class="content"
              dangerouslySetInnerHTML={{
                __html: aboutQuestionsData.question_9.content,
              }}
            />
          </AccordionDetails>
        </Accordion>
      </>
    );
  }
  return (
    <div>
      <h1>Loading...</h1>
    </div>
  );
}
