import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import {
  Map,
  TileLayer,
  Marker,
  Popup,
  FeatureGroup,
  withLeaflet,
  Polygon,
} from "react-leaflet";
import Button from "./Button";

import MapContext from "../../../context/MapContext";
import { EditControl } from "react-leaflet-draw";
import { useRef } from "react";
import { geosearch } from "esri-leaflet-geocoder";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import "esri-leaflet-geocoder/dist/esri-leaflet-geocoder.css";
import "leaflet-fullscreen/dist/Leaflet.fullscreen.js";
import "leaflet-fullscreen/dist/leaflet.fullscreen.css";
import PrintControlDefault from "react-leaflet-easyprint";
import { Input } from ".";
const PrintControl = withLeaflet(PrintControlDefault);

const PrintMap = ({ goToNextStep, boundaries }) => {
  const [mapLayers, setMapLayers] = useState();
  const ZOOM_LEVEL = 10;
  const mapRef = useRef();
  const printControlRef = useRef();

  useEffect(() => {
    const { current = {} } = mapRef;
    const { leafletElement: map } = current;

    if (!map) return;

    const control = geosearch();

    control.addTo(map);

    control.on("results", handleOnSearchResuts);

    return () => {
      control.off("results", handleOnSearchResuts);
    };
  }, [mapRef]);

  /**
   * handleOnSearchResuts
   * @param {object} data Results object from esri-leaflet-geocoder
   */

  function handleOnSearchResuts(data) {}

  const createLayer = (e) => {
    const { layerType, layer } = e;
    if (layerType === "polygon") {
      const { _leaflet_id } = layer;

      setMapLayers((layers) => [
        ...layers,
        { id: _leaflet_id, latlngs: layer.getLatLngs()[0] },
      ]);
    }
  };

  const editLayer = (e) => {
    const {
      layers: { _layers },
    } = e;

    Object.values(_layers).map(({ _leaflet_id, editing }) => {
      setMapLayers((layers) =>
        layers.map((l) =>
          l.id === _leaflet_id
            ? { ...l, latlngs: { ...editing.latlngs[0] } }
            : l
        )
      );
    });
  };

  const deleteLayer = (e) => {
    const {
      layers: { _layers },
    } = e;

    Object.values(_layers).map(({ _leaflet_id }) => {
      setMapLayers((layers) => layers.filter((l) => l.id !== _leaflet_id));
    });
  };
  if (boundaries.boundaries === null) {
    return null;
  }
  // get first array to center map position
  const getLatLngs = boundaries.boundaries[0].latlngs;
  const center = getLatLngs[0];
  return (
    <Wrapper>
      <Col>
        <Container>
          <div
            style={{
              textAlign: "center",
              height: "100%",
              width: "100%",
            }}
          >
            <Map
              style={{ height: "100%", width: "100%" }}
              center={center}
              zoom={ZOOM_LEVEL}
              ref={mapRef}
              fullscreenControl={true}
            >
              <FeatureGroup></FeatureGroup>
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              />
              {boundaries.boundaries.map((newLayer, i) => {
                console.log(newLayer);
                return (
                  <>
                    <Polygon positions={newLayer.latlngs}></Polygon>
                  </>
                );
              })}
              <PrintControl
                ref={printControlRef}
                position="topleft"
                sizeModes={["Current", "A4Portrait", "A4Landscape"]}
                hideControlContainer={false}
              />
              <PrintControl
                position="topleft"
                sizeModes={["Current", "A4Portrait", "A4Landscape"]}
                hideControlContainer={false}
                title="Export as PNG"
                exportOnly
              />
            </Map>
          </div>
        </Container>
      </Col>
    </Wrapper>
  );
};

export default PrintMap;

const Wrapper = styled.div`
  z-index: 1;
  background-color: white;
  width: 600px;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
`;

const Col = styled.div`
  @media (min-width: 320px) {
    flex: 1;
    flex-wrap: wrap;
  }
`;
export const Row = styled.div`
  margin-bottom: 3px;
  flex-wrap: wrap;
  display: flex;

  p {
    text-align: left;
  }
`;

const Container = styled.div`
  height: 300px;
  margin: 0px auto;
`;
