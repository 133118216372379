import React from "react";
import styled from "styled-components";
import Input from "./Input";
import { device } from "../../../utils/mediaQueries";
const MultipleInputs = ({
  value,
  min,
  max,
  budget,
  onChange,
  minLabel,
  maxLabel,
  sliderId,
  ml,
  width,
  inputLabelLeft,
  inputLabelRight,
  step,
  placeholder,
  type,
  money,
  inputSign,
}) => {
  return (
    <SliderWrapper width={width} ml={ml}>
      <StlyedSliderInput>
        <InputLabelLeft> {inputLabelLeft}</InputLabelLeft>
        {!money ? null : <InputLabelRight> {inputLabelRight}</InputLabelRight>}
        {!inputSign ? null : <InputLabelRight> {inputSign}</InputLabelRight>}
        <Input
          min={min}
          max={max}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          className="input__field"
          type={type}
          step={step}
        />
        {!money ? (
          <InputLabelRight> {inputLabelRight}</InputLabelRight>
        ) : (
          <InputLabelRight> </InputLabelRight>
        )}
      </StlyedSliderInput>
    </SliderWrapper>
  );
};

export default MultipleInputs;
const SliderWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  flex-direction: row;
`;

const InputLabelLeft = styled.div`
  padding: 5px;
  flex: 2;
`;
const InputLabelRight = styled.div`
  width: 30px;
  margin-right: 5px;
`;
const StlyedSliderInput = styled.div`
  align-items: center;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  background: #fdcae1;
  color: #f9398f;
  letter-spacing: 2px;
  border-radius: 25px;
  border: 2px solid #adcae6;
  font-size: 1.4rem;
  input {
    width: 120px;
    text-align: center;
    letter-spacing: 5.6px;
    border-radius: 8px;
    border: 2px solid black;
    height: calc(1.6em + 0.75rem + 2px);
    background: #fdcae1;
    @media () &::placeholder {
      color: #f9398f;
    }
  }
`;
