import React from "react";
import styled from "styled-components";
import { device } from "../../../utils/mediaQueries";
import "react-step-progress-bar/styles.css";
import { ProgressBar as ProgressBarComponent } from "react-step-progress-bar";
const ProgressBar = ({ color, completed, height }) => {
  return (
    <ProgressBarComponent
      percent={completed}
      filledBackground="linear-gradient(to right, #fefb72, #f0bb31)"
    />
  );
};

export default ProgressBar;

const Container = styled.div`
  height: 3.2rem;
  background-color: grey;
  border-radius: 40px;
  @media ${device.phone} {
    width: 300px;
  }
  @media ${device.xs} {
    width: 450px;
  }
  @media ${device.sm} {
    width: 500px;
  }
  @media ${device.lg} {
    width: 400px;
  }
`;

const FillLayer = styled.div`
  display: flex;
  height: 100%;
  width: ${(props) => props.completed}%;
  background-color: ${(props) => props.color};
  border-radius: inherit;
  text-align: right;
  transition: width 1s ease-in-out;
`;

const Label = styled.span`
  padding: 8px 5px 5px 16px;
  color: white;
  font-weight: bold;
  font-size: 1.6rem;
`;
