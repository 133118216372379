import * as React from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import styled from "styled-components";
import Input from "./Input";

function valuetext(value) {
  return `${value}°C`;
}

const minDistance = 1;

export default function MinimumDistanceSlider() {
  const [minYearSelected, setMinYearSelected] = React.useState("");
  const [maxYearSelected, setMaxYearSelected] = React.useState("");

  const handleAmountMaxInput = (event) => {
    setMaxYearSelected(event.target.value);
    handleChange(event, [value[0], maxYearSelected]);
  };
  const handleAmountMinInput = (event) => {
    setMinYearSelected(event.target.value);
    handleChange(event, [minYearSelected, value[1]]);
  };

  const [value, setValue] = React.useState([1990, 2023]);

  const handleChange = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setValue([Math.min(newValue[0], value[1] - minDistance), value[1]]);
    } else {
      setValue([value[0], Math.max(newValue[1], value[0] + minDistance)]);
    }
  };

  return (
    <SliderWrapper>
      <StlyedSliderInput>
        <StyledNumber
          type="number"
          step="1"
          value={value[0]}
          placeholder="Min Year"
          disabled
        />
      </StlyedSliderInput>
      <StlyedSliderInput>
        <StyledNumber
          type="number"
          step="1"
          value={value[1]}
          placeholder="Max Year"
          disabled
        />
      </StlyedSliderInput>
      <Slider
        getAriaLabel={() => "Minimum distance"}
        value={value}
        onChange={handleChange}
        valueLabelDisplay="auto"
        getAriaValueText={valuetext}
        disableSwap
        min={1990}
        max={2023}
      />
      <SliderRange></SliderRange>
      <DateRangeLeft>1990</DateRangeLeft>
      <DateRangeRight>2023</DateRangeRight>
    </SliderWrapper>
  );
}

const DateRangeLeft = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: left;
  transform: translate(8px, -12px);
  font-size: 14px;
  color: black;
`;
const DateRangeRight = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  text-align: right;
  font-size: 14px;
  color: black;
  transform: translate(-4px, -12px);
`;
const SliderWrapper = styled.div`
  width: 500px;
  margin: 0px auto;
  text-align: center;
  position: relative;
  display: flex;
  flex-wrap: flex;
  overflow: hidden;
  justify-content: space-evenly;
`;

const StyledRange = styled.input.attrs({
  type: "range",
})`
  width: 100%;
  height: 20px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  color: black;
  margin-bottom: 1rem;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  position: absolute;
  bottom: 0;
  left: 0;
  &:focus {
    outline: none;
  }
  -webkit-appearance: none;

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 20px;
    cursor: pointer;
    animate: 0.2s;
    background: #d3d3d3;
    border-radius: 5px;
    box-shadow: none;
    border: 2px solid #adcae6;
  }
  &:focus::-ms-fill-lower {
    background: #b67df3;
  }
  &:focus::-ms-fill-upper {
    background: #b67df3;
  }
  &::-webkit-slider-thumb {
    z-index: 2;
    position: relative;
    margin-top: -5px;
    box-shadow: 0px 0px 0px #000;
    border: 1px solid #b67df3;
    height: 25px;
    width: 25px;
    border-radius: 25px;
    background: #8233d4;
    cursor: pointer;
    -webkit-appearance: none;
  }
`;

const StyledNumber = styled.input.attrs({
  type: "number",
})`
  width: 60%;
  margin: 0px auto;
  text-align: center;
  letter-spacing: 5.6px;
  border-radius: 8px;
  border: 2px solid black;
  height: calc(1.6em + 0.75rem + 2px);
  background: #fdcae1;
  padding: 5px;
  @media () &::placeholder {
    color: #f9398f;
  }
`;
const StlyedSliderInput = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 240px;
  margin: 10px;
  background: #fdcae1;
  color: #f9398f;
  letter-spacing: 2px;
  border-radius: 25px;
  border: 2px solid #adcae6;
  margin-bottom: 40px;
`;
const SliderRange = styled.div`
  ul {
    display: flex;
    width: 100%;
  }

  li:last-child {
    margin-left: auto;
  }
`;
