import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { db, firestore } from "../../utils/firebase";
import { onValue, ref } from "firebase/database";
import "./style.css"; // Import the CSS file
import { ListItem, Link, Box } from "@mui/material";

export default function AccordianInvest() {
  const [aboutQuestionsData, setAboutQuestionsData] = React.useState("");
  React.useEffect(() => {
    const query = ref(db, "investor_questions");
    return onValue(query, (snapshot) => {
      const data = snapshot.val();
      setAboutQuestionsData(data);
      console.log(data);
    });
  }, []);

  if (aboutQuestionsData) {
    return (
      <>
        {/* FAQ 1 */}
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="house-first"
          >
            <Typography variant="h3">
              {aboutQuestionsData.question_1.title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div
              class="content"
              dangerouslySetInnerHTML={{
                __html: aboutQuestionsData.question_1.content,
              }}
            />
          </AccordionDetails>
        </Accordion>

        {/* FAQ 2*/}
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="house-first"
          >
            <Typography variant="h3">
              {aboutQuestionsData.question_2.title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div
              class="content"
              dangerouslySetInnerHTML={{
                __html: aboutQuestionsData.question_2.content,
              }}
            />
          </AccordionDetails>
        </Accordion>

        {/* FAQ 3*/}
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="house-first"
          >
            <Typography variant="h3">
              {aboutQuestionsData.question_3.title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div
              class="content"
              dangerouslySetInnerHTML={{
                __html: aboutQuestionsData.question_3.content,
              }}
            />
          </AccordionDetails>
        </Accordion>

        {/* FAQ 4*/}
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="house-first"
          >
            <Typography variant="h3">
              {aboutQuestionsData.question_4.title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div
              class="content"
              dangerouslySetInnerHTML={{
                __html: aboutQuestionsData.question_4.content,
              }}
            />
          </AccordionDetails>
        </Accordion>
      </>
    );
  }
  return (
    <div>
      <h1>Loading...</h1>
    </div>
  );
}
