import * as React from "react";
import { Wizard, useWizard } from "react-use-wizard";
import { useState, useContext, useEffect } from "react";
import AnimationContext from "../../../context/AnimationContext";
import RentContext from "../../../context/RentContext";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import {
  ResponsiveOtherInput,
  OtherInput,
  ResponsiveNewMap,
  ResponsiveMapQuestionBox,
  GeoCoderNewMap,
  ResponsiveInputButtonBlock,
  InputButtonBlock,
  MultipleInputs,
  LaundryButtonBlock,
  MattersButtonBlock,
  MattersLongRadioButton,
  ResponsiveButtonBlockDropDown,
  ResponsiveDualSliderBlock,
  Background,
  StepProgressBar,
  ResponsiveLongRadioButton,
  ResponsiveSelectRadioButton,
  ResponsiveSliderBlock,
  ResponsiveDropdown,
  SelectResponsiveDropdown,
  ResponsiveRadioButton,
  ResponsiveButtonBlock,
  SelectButtonBlock,
  SelectRadioButton,
  LongButtonBlock,
  SelectItem,
  MapQuestionBlock,
  BottomBar,
  FinalQuestionBlock,
  LongRadioButton,
  CheckBoxBlock,
  SliderBlock,
  BlockRow,
  AnimationQuestionBlock,
  ButtonRow,
  ButtonBlock,
  QuestionBlock,
  ProgressBar,
  ResultsLoader,
  Map,
  AnimationLoading,
  DropdownComponent,
  RadioButton,
  SelectDropdownComponent,
  Button,
  Input,
  Label,
  CheckBox,
  Expire,
  TheSun,
  Delay,
  AnimationIn,
  AnimationOut,
  NewMap,
} from "../../common/index";
//final svgs

import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import PersonIcon from "@mui/icons-material/Person";

// contenxt
import MapContext from "../../../context/MapContext";

const Header = () => {
  const { activeStep, stepCount } = useWizard();

  const {
    animationOut,
    animation,
    isVisible,
    animationIn,
    windowWidth,
    setWindowWidth,
    windowHeight,
    setWindowHeight,
    route,
    setRoute,
  } = useContext(AnimationContext);
  return (
    <div
      style={{
        fontSize: "16px",
        position: "fixed",
      }}
    >
      ANIMATION: {JSON.stringify(animation)}
      <br />
      animationin: {JSON.stringify(animationIn)}
      <br />
      animationOut: {JSON.stringify(animationOut)}
      <br />
      Window Width: {JSON.stringify(windowWidth)}
      <br />
      Window Height: {JSON.stringify(windowHeight)}
      <br />
      Current Step: {activeStep + 1}
    </div>
  );
};

const Footer = ({ route }) => {
  const { activeStep, stepCount } = useWizard();
  const percent = (activeStep / stepCount) * 100;

  return (
    <BottomBar
      route="isRent"
      percent={percent}
      activeStep={activeStep}
      stepCount={stepCount}
    />
  );
};

const Wrapper = ({ children, route }) => {
  const {
    isLoading,
    isLastStep,
    isFirstStep,
    activeStep,
    stepCount,
    previousStep,
    nextStep,
    goToStep,
    handleStep,
  } = useWizard();
  const {
    animationOut,
    animation,
    isVisible,
    animationIn,
    windowWidth,
    setWindowWidth,
    windowHeight,
    setWindowHeight,
    setAnimationOut,
    setAnimationIn,
    delayIn,
    delayOut,
  } = useContext(AnimationContext);

  // Attach an optional handler
  handleStep(() => {
    setAnimationOut(true);
    setAnimationIn(false);
    console.log(animation);
    console.log(animationOut);
  });
  if (isFirstStep) {
    if (!animation) {
      return <div>{children}</div>;
    }
    if (animation) {
      return (
        <div className="animation__question__wrapper">
          <div className="theSun">
            <div className="ray_box">
              <div className="ray ray1"></div>
              <div className="ray ray2"></div>
              <div className="ray ray3"></div>
              <div className="ray ray4"></div>
              <div className="ray ray5"></div>
              <div className="ray ray6"></div>
              <div className="ray ray7"></div>
              <div className="ray ray8"></div>
              <div className="ray ray9"></div>
              <div className="ray ray10"></div>
            </div>
          </div>
          <div className="background_overlay"></div>
          <div>
            {animationIn ? (
              <AnimationIn delay={delayIn} />
            ) : (
              <AnimationQuestionBlock animation={true}>
                <div>{children}</div>
              </AnimationQuestionBlock>
            )}
          </div>
        </div>
      );
    }
  }
  if (isLastStep) {
    return <div>{children}</div>;
  }
  if (!animation) {
    return <div className="">{children}</div>;
  }
  if (animation) {
    return (
      <>
        <div className="animation__question__wrapper">
          <div className="theSun">
            <div className="ray_box">
              <div className="ray ray1"></div>
              <div className="ray ray2"></div>
              <div className="ray ray3"></div>
              <div className="ray ray4"></div>
              <div className="ray ray5"></div>
              <div className="ray ray6"></div>
              <div className="ray ray7"></div>
              <div className="ray ray8"></div>
              <div className="ray ray9"></div>
              <div className="ray ray10"></div>
            </div>
          </div>
          <div className="background_overlay"></div>
          {/* <div className="cloud-intro"></div> */}

          {/* <div className="x1">
            <div className="cloud"></div>
          </div>
          <div className="x2">
            <div className="cloud"></div>
          </div>
          <div className="x3">
            <div className="cloud"></div>
          </div> */}
          <div>
            {animationOut ? (
              <AnimationOut delay={delayOut} />
            ) : (
              <AnimationQuestionBlock animation={true}>
                <div>{children}</div>
              </AnimationQuestionBlock>
            )}
          </div>
        </div>
      </>
    );
  }
};

const Rent = () => {
  const { animation, setAnimation, animationOut, setAnimationOut } =
    useContext(AnimationContext);

  return (
    <Wizard
      startIndex={0}
      header={<Header />}
      footer={<Footer />}
      wrapper={<Wrapper />}
    >
      <Step1
        animation={animation}
        setAnimationOut={setAnimationOut}
        animationOut={animationOut}
      />
      <Step2
        animation={animation}
        setAnimationOut={setAnimationOut}
        animationOut={animationOut}
      />
      <Step3
        animation={animation}
        setAnimationOut={setAnimationOut}
        animationOut={animationOut}
      />
      <Step4
        animation={animation}
        setAnimationOut={setAnimationOut}
        animationOut={animationOut}
      />
      <Step5
        animation={animation}
        setAnimationOut={setAnimationOut}
        animationOut={animationOut}
      />
      <Step6
        animation={animation}
        setAnimationOut={setAnimationOut}
        animationOut={animationOut}
      />
      <Step7
        animation={animation}
        setAnimationOut={setAnimationOut}
        animationOut={animationOut}
      />
      <Step8
        animation={animation}
        setAnimationOut={setAnimationOut}
        animationOut={animationOut}
      />
      <Step9
        animation={animation}
        setAnimationOut={setAnimationOut}
        animationOut={animationOut}
      />
      <Step10
        animation={animation}
        setAnimationOut={setAnimationOut}
        animationOut={animationOut}
      />
      <Step11
        animation={animation}
        setAnimationOut={setAnimationOut}
        animationOut={animationOut}
      />
      <Step12
        animation={animation}
        setAnimationOut={setAnimationOut}
        animationOut={animationOut}
      />
      <Step13
        animation={animation}
        setAnimationOut={setAnimationOut}
        animationOut={animationOut}
      />
      <Step14
        animation={animation}
        setAnimationOut={setAnimationOut}
        animationOut={animationOut}
      />
    </Wizard>
  );
};

const Step1 = ({ animation, setAnimationOut, route, animationOut }) => {
  const {
    isLoading,
    isLastStep,
    isFirstStep,
    activeStep,
    stepCount,
    previousStep,
    nextStep,
    goToStep,
    handleStep,
  } = useWizard();
  // checkbox
  const [checked, setChecked] = useState(true);
  const checkState = () => {
    setChecked(true);
  };
  const [selected, setSelected] = useState([]);
  const handleOnClick = (e) => {
    const item = e.target.value;
    var index = selected.indexOf(item);
    const newList = selected;
    const filtered = newList.filter((select) => select !== item);
    if (index == -1) {
      setSelected([...selected, item]);
    } else {
      setSelected([...filtered]);
    }
  };

  // Multi Select
  const { setRental } = useContext(RentContext);
  // QUERY
  const GET_RENT_STEP_1 = gql`
    query {
      rentQuestion(id: "1") {
        title
        slug
        id
        content
        background_image {
          url
          alternativeText
        }
        ...radiobuttons
      }
    }
    fragment radiobuttons on RentQuestions {
      questions {
        ... on ComponentElementsRadioButton {
          __typename
          id
          label
          value
          icon {
            url
            alternativeText
          }
          icon_text
        }
      }
    }
  `;

  const { loading, error, data } = useQuery(GET_RENT_STEP_1);
  if (loading) return <AnimationLoading />;
  if (error) return "Error!";
  if (data) {
    const background = data.rentQuestion.background_image.url;
    if (animation) {
      return (
        <>
          <Delay delay="0">
            <QuestionHeader style={{ transform: "translateY(-50px)" }}>
              {data.rentQuestion.content}
            </QuestionHeader>
            <ButtonBlock>
              {data.rentQuestion.questions.map((x, i) => {
                return (
                  <React.Fragment key={x.id}>
                    {x.__typename === "ComponentElementsRadioButton" ? (
                      <RadioButton
                        route={route}
                        id={x.id}
                        icon={null}
                        icon_text={x.icon_text}
                        onClick={(e) => handleOnClick(e)}
                        label={x.label}
                        value={x.value}
                      />
                    ) : null}
                  </React.Fragment>
                );
              })}
            </ButtonBlock>
            {selected.length === 0 ? (
              <Button
                color="#ffed4a"
                disabled
                onClick={() => nextStep()}
                label="Next"
              />
            ) : (
              <Button
                color="#ffed4a"
                onClick={() => {
                  nextStep();
                  setRental(selected);
                }}
                label="Next"
              />
            )}
          </Delay>
        </>
      );
    }
    return (
      <>
        <QuestionBlock
          backgroundImage={background}
          headerText={data.rentQuestion.content}
        >
          <ResponsiveButtonBlock>
            {data.rentQuestion.questions.map((x, i) => {
              return (
                <React.Fragment key={x.id}>
                  {x.__typename === "ComponentElementsRadioButton" ? (
                    <ResponsiveRadioButton
                      id={x.id}
                      icon={null}
                      icon_text={x.icon_text}
                      onClick={(e) => handleOnClick(e)}
                      label={x.label}
                      value={x.value}
                      type="checked"
                    />
                  ) : null}
                </React.Fragment>
              );
            })}
          </ResponsiveButtonBlock>
          {selected.length === 0 ? (
            <Button disabled onClick={() => nextStep()} label="Next" />
          ) : (
            <Button
              onClick={() => {
                nextStep();
                setRental(selected);
              }}
              label="Next"
            />
          )}
        </QuestionBlock>
      </>
    );
  }
};

const Step2 = ({ route, animation }) => {
  const { handleStep, previousStep, nextStep } = useWizard();
  // checkbox
  const [checked, setChecked] = useState(true);
  const checkState = () => {
    setChecked(true);
  };
  // Multi Select
  const [selected, setSelected] = useState([]);
  const handleOnClick = (e) => {
    const item = e.target.value;
    var index = selected.indexOf(item);
    const newList = selected;
    const filtered = newList.filter((select) => select !== item);
    if (index == -1) {
      setSelected([...selected, item]);
    } else {
      setSelected([...filtered]);
    }
  };

  const { setBedrooms } = useContext(RentContext);
  // QUERY
  const GET_RENT_STEP_2 = gql`
    query {
      rentQuestion(id: "2") {
        title
        slug
        id
        content
        background_image {
          url
          alternativeText
        }
        ...radiobuttons
      }
    }
    fragment radiobuttons on RentQuestions {
      questions {
        ... on ComponentElementsRadioButton {
          __typename
          id
          label
          value
          icon {
            url
            alternativeText
          }
          icon_text
        }
      }
    }
  `;

  const { loading, error, data } = useQuery(GET_RENT_STEP_2);
  if (loading) return <AnimationLoading />;
  if (error) return "Error!";
  if (data) {
    if (animation) {
      return (
        <>
          <Delay delay="0">
            <QuestionHeader style={{ transform: "translateY(-50px)" }}>
              {data.rentQuestion.content}
            </QuestionHeader>
            <ButtonBlock>
              {data.rentQuestion.questions.map((x, i) => {
                return (
                  <React.Fragment key={x.id}>
                    {x.__typename === "ComponentElementsRadioButton" ? (
                      <RadioButton
                        id={x.id}
                        icon={null}
                        icon_text={x.icon_text}
                        onClick={(e) => handleOnClick(e)}
                        label={x.label}
                        value={x.value}
                      />
                    ) : null}
                  </React.Fragment>
                );
              })}
            </ButtonBlock>
            {selected.length === 0 ? (
              <Button disabled label="Next" />
            ) : (
              <Button
                onClick={() => {
                  nextStep();
                  setBedrooms(selected);
                }}
                label="Next"
              />
            )}
          </Delay>
        </>
      );
    }
    const background = data.rentQuestion.background_image.url;
    return (
      <>
        <QuestionBlock
          backgroundImage={background}
          headerText={data.rentQuestion.content}
        >
          <ResponsiveButtonBlock>
            {data.rentQuestion.questions.map((x, i) => {
              return (
                <React.Fragment key={x.id}>
                  {x.__typename === "ComponentElementsRadioButton" ? (
                    <ResponsiveRadioButton
                      id={x.id}
                      icon={null}
                      icon_text={x.icon_text}
                      onClick={(e) => handleOnClick(e)}
                      label={x.label}
                      value={x.value}
                      type="checked"
                    />
                  ) : null}
                </React.Fragment>
              );
            })}
          </ResponsiveButtonBlock>
          {selected.length === 0 ? (
            <Button disabled label="Next" />
          ) : (
            <Button
              onClick={() => {
                nextStep();
                setBedrooms(selected);
              }}
              label="Next"
            />
          )}
        </QuestionBlock>
      </>
    );
  }
};

const Step3 = ({ route, animation }) => {
  const { handleStep, previousStep, nextStep } = useWizard();
  const { budget, setBudget } = useContext(RentContext);
  // QUERY
  const GET_RENT_STEP_3 = gql`
    query {
      rentQuestion(id: "3") {
        title
        slug
        id
        content
        background_image {
          url
          alternativeText
        }
        ...elements
      }
    }
    fragment elements on RentQuestions {
      questions {
        ... on ComponentElementsRadioButton {
          __typename
          id
          label
          value
          icon {
            url
            alternativeText
          }
          icon_text
        }
        ... on ComponentElementsInput {
          __typename
          id
          min
          max
          htmlFor
          step
          type
          minLabel
          maxLabel
          sliderId
          value
          inputLabelLeft
          inputLabelRight
        }
      }
    }
  `;
  const { loading, error, data } = useQuery(GET_RENT_STEP_3);
  if (loading) return <AnimationLoading />;
  if (error) return "Error!";
  if (data) {
    if (animation) {
      return (
        <>
          <Delay delay="0">
            <QuestionHeader style={{ transform: "translateY(-50px)" }}>
              {data.rentQuestion.content}
            </QuestionHeader>
            <ButtonBlock>
              {data.rentQuestion.questions.map((x, i) => {
                return (
                  <React.Fragment key={x.ic}>
                    {x.__typename === "ComponentElementsInput" ? (
                      <SliderBlock
                        ml={10}
                        min={x.min}
                        max={x.max}
                        placeholder={budget ? budget : "X,XXX"}
                        onChange={(e) => setBudget(e.target.value)}
                        value={budget}
                        minLabel={x.minLabel}
                        maxLabel={x.maxLabel}
                        sliderId="budget"
                        step={x.step}
                        type="number"
                        inputLabelLeft={x.inputLabelLeft}
                        inputLabelRight={x.inputLabelRight}
                      />
                    ) : null}
                  </React.Fragment>
                );
              })}
            </ButtonBlock>
            <p>You can type or slide your answer</p>

            <ButtonWrapper>
              {!budget ? (
                <Button disabled onClick={() => nextStep()} label="Next" />
              ) : (
                <Button
                  onClick={() => {
                    nextStep();
                    setBudget(budget);
                  }}
                  label="Next"
                />
              )}
            </ButtonWrapper>
          </Delay>
        </>
      );
    }
    const background = data.rentQuestion.background_image.url;
    return (
      <>
        <QuestionBlock
          backgroundImage={background}
          headerText={data.rentQuestion.content}
        >
          <ResponsiveButtonBlock>
            {data.rentQuestion.questions.map((x, i) => {
              return (
                <React.Fragment key={x.id}>
                  {x.__typename === "ComponentElementsInput" ? (
                    <ResponsiveSliderBlock
                      min={x.min}
                      max={x.max}
                      placeholder={budget ? budget : "X,XXX"}
                      onChange={(e) => setBudget(e.target.value)}
                      value={budget}
                      minLabel={x.minLabel}
                      maxLabel={x.maxLabel}
                      sliderId="budget"
                      step={x.step}
                      type="number"
                      inputLabelLeft={x.inputLabelLeft}
                      inputLabelRight={x.inputLabelRight}
                    />
                  ) : null}
                </React.Fragment>
              );
            })}
          </ResponsiveButtonBlock>
          <p>You can type or slide your answer</p>
          <ButtonWrapper>
            {!budget ? (
              <Button disabled onClick={() => nextStep()} label="Next" />
            ) : (
              <Button
                onClick={() => {
                  nextStep();
                  setBudget(budget);
                }}
                label="Next"
              />
            )}
          </ButtonWrapper>
        </QuestionBlock>
      </>
    );
  }
};

const Step4 = ({ route, animation }) => {
  const { nextStep } = useWizard();
  const { setLocation, location } = useContext(RentContext);

  // toggle Map
  const [showModal, setShowModal] = useState(false);
  const [map, setMap] = useState(false);
  // set Animation
  const { toggleAnimation } = useContext(AnimationContext);
  const [animationOut, setAnimationOut] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  // QUERY
  const GET_RENT_STEP_4 = gql`
    query {
      rentQuestion(id: "4") {
        title
        slug
        id
        content
        background_image {
          url
          alternativeText
        }
        ...elements
      }
    }
    fragment elements on RentQuestions {
      questions {
        ... on ComponentElementsRadioButton {
          __typename
          id
          label
          value
          icon {
            url
            alternativeText
          }
          icon_text
        }
        ... on ComponentElementsInput {
          __typename
          id
          min
          max
          htmlFor
          step
          type
        }
      }
    }
  `;

  const { loading, error, data } = useQuery(GET_RENT_STEP_4);
  if (loading) return <AnimationLoading />;
  if (error) return "Error!";
  if (data) {
    if (animation) {
      return (
        <>
          {isVisible && (
            <Delay delay="0">
              <NewMap
                animationOut={animationOut}
                setAnimationOut={setAnimationOut}
                nextStep={nextStep}
                setIsVisible={setIsVisible}
                isVisible={isVisible}
                headerText={data.rentQuestion.content}
              />
            </Delay>
          )}
        </>
      );
    }
    const background = data.rentQuestion.background_image.url;

    return (
      <>
        <ResponsiveMapQuestionBox
          backgroundImage={background}
          animation={false}
        >
          <ResponsiveNewMap
            nextStep={nextStep}
            headerText={data.rentQuestion.content}
          />
        </ResponsiveMapQuestionBox>
      </>
    );
  }
};

const Step5 = ({ route, animation }) => {
  const { handleStep, previousStep, nextStep } = useWizard();
  // Multi Select
  const [selected, setSelected] = useState([]);
  const handleOnClick = (e) => {
    const item = e.target.value;
    var index = selected.indexOf(item);
    const newList = selected;
    const filtered = newList.filter((select) => select !== item);
    if (index == -1) {
      setSelected([...selected, item]);
    } else {
      setSelected([...filtered]);
    }
  };

  // Other Input
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const handleOther = (e) => {
    setInputVisible(!inputVisible);
  };

  const handleInputValue = (e) => {
    var index = selected.indexOf(inputValue);
    const newList = selected;
    const filtered = newList.filter((select) => select !== inputValue);
    if (index == -1) {
      setSelected([...selected, inputValue]);
    } else {
      setSelected([...filtered]);
    }
  };

  const { setAmenities } = useContext(RentContext);
  // QUERY
  const GET_RENT_STEP_5 = gql`
    query {
      rentQuestion(id: "5") {
        title
        slug
        id
        content
        background_image {
          url
          alternativeText
        }
        ...elements
      }
    }
    fragment elements on RentQuestions {
      questions {
        ... on ComponentElementsRadioButton {
          __typename
          id
          label
          value
          icon {
            url
            alternativeText
          }
          icon_text
        }
        ... on ComponentElementsInput {
          __typename
          id
          min
          max
          htmlFor
          step
          type
        }
        ... on ComponentElementsCheckbox {
          id
          icon {
            url
            alternativeText
          }
          label
          value
        }
      }
    }
  `;

  const { loading, error, data } = useQuery(GET_RENT_STEP_5);
  if (loading) return <AnimationLoading />;
  if (error) return "Error!";
  if (data) {
    if (animation) {
      return (
        <>
          <Delay delay="0">
            <QuestionHeader style={{ transform: "translateY(-50px)" }}>
              {data.rentQuestion.content}
            </QuestionHeader>
            <SelectButtonBlock>
              {data.rentQuestion.questions.map((x, i) => {
                return (
                  <React.Fragment key={x.id}>
                    {x.__typename === "ComponentElementsCheckbox" ? (
                      <SelectRadioButton
                        id={x.id}
                        icon={x.icon}
                        icon_text={x.icon_text}
                        onClick={(e) => handleOnClick(e)}
                        label={x.label}
                        value={x.value}
                      />
                    ) : null}
                  </React.Fragment>
                );
              })}
              <OtherInput
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onBlur={handleInputValue}
                placeholder="Other - Type Here"
                type="text"
              />
            </SelectButtonBlock>
            <Button
              onClick={() => {
                nextStep();
                setAmenities(selected);
              }}
              label="Next"
            />
          </Delay>
        </>
      );
    }
    const background = data.rentQuestion.background_image.url;
    return (
      <>
        <QuestionBlock
          backgroundImage={background}
          headerText={data.rentQuestion.content}
        >
          <ResponsiveButtonBlock>
            {data.rentQuestion.questions.map((x, i) => {
              return (
                <React.Fragment key={x.id}>
                  {x.__typename === "ComponentElementsCheckbox" ? (
                    <ResponsiveSelectRadioButton
                      id={x.id}
                      icon={x.icon}
                      icon_text={x.icon_text}
                      onClick={(e) => handleOnClick(e)}
                      label={x.label}
                      value={x.value}
                      width="190px"
                      height="50px"
                    />
                  ) : null}
                </React.Fragment>
              );
            })}
            <div>
              <ResponsiveOtherInput
                onChange={(e) => setInputValue(e.target.value)}
                onBlur={handleInputValue}
                placeholder="Other - Type Here"
                type="text"
                value={inputValue}
              />
            </div>
          </ResponsiveButtonBlock>

          <Button
            onClick={() => {
              nextStep();
              setAmenities(selected);
            }}
            label="Next"
          />
        </QuestionBlock>
      </>
    );
  }
};
const Step6 = ({ route, animation }) => {
  const { handleStep, previousStep, nextStep } = useWizard();
  // Multi Select
  const [selected, setSelected] = useState([]);
  const handleOnClick = (e) => {
    const item = e.target.value;
    var index = selected.indexOf(item);
    const newList = selected;
    const filtered = newList.filter((select) => select !== item);
    if (index == -1) {
      setSelected([...selected, item]);
    } else {
      setSelected([...filtered]);
    }
  };
  const { setPets } = useContext(RentContext);
  // QUERY
  const GET_RENT_STEP_6 = gql`
    query {
      rentQuestion(id: "6") {
        title
        slug
        id
        content
        background_image {
          url
          alternativeText
        }
        ...elements
      }
    }
    fragment elements on RentQuestions {
      questions {
        ... on ComponentElementsRadioButton {
          __typename
          id
          label
          value
          icon {
            url
            alternativeText
          }
          icon_text
        }
        ... on ComponentElementsInput {
          __typename
          id
          min
          max
          htmlFor
          step
          type
        }
      }
    }
  `;

  const { loading, error, data } = useQuery(GET_RENT_STEP_6);
  if (loading) return <AnimationLoading />;
  if (error) return "Error!";
  if (data) {
    if (animation) {
      return (
        <>
          <Delay delay="0">
            <QuestionHeader style={{ transform: "translateY(-50px)" }}>
              {data.rentQuestion.content}
            </QuestionHeader>
            <ButtonBlock>
              {data.rentQuestion.questions.map((x, i) => (
                <React.Fragment key={x.id}>
                  {x.__typename === "ComponentElementsRadioButton" ? (
                    <RadioButton
                      id={x.id}
                      icon={x.icon}
                      icon_text={x.icon_text}
                      onClick={(e) => handleOnClick(e)}
                      label={x.label}
                      value={x.value}
                    />
                  ) : null}
                </React.Fragment>
              ))}
            </ButtonBlock>

            <Button
              onClick={() => {
                nextStep();
                setPets(selected);
              }}
              label="Next"
            />
          </Delay>
        </>
      );
    }
    const background = data.rentQuestion.background_image.url;
    return (
      <>
        <QuestionBlock
          backgroundImage={background}
          headerText={data.rentQuestion.content}
        >
          <ResponsiveButtonBlock>
            {data.rentQuestion.questions.map((x, i) => (
              <React.Fragment key={x.id}>
                {x.__typename === "ComponentElementsRadioButton" ? (
                  <ResponsiveRadioButton
                    id={x.id}
                    icon={x.icon}
                    icon_text={x.icon_text}
                    onClick={(e) => handleOnClick(e)}
                    label={x.label}
                    value={x.value}
                  />
                ) : null}
              </React.Fragment>
            ))}
          </ResponsiveButtonBlock>
          <Button
            onClick={() => {
              nextStep();
              setPets(selected);
            }}
            label="Next"
          />
        </QuestionBlock>
      </>
    );
  }
};
const Step7 = ({ route, animation }) => {
  const { handleStep, previousStep, nextStep } = useWizard();
  // Multi Select
  const [selected, setSelected] = useState([]);
  const handleOnClick = (e) => {
    const item = e.target.value;
    var index = selected.indexOf(item);
    const newList = selected;
    const filtered = newList.filter((select) => select !== item);
    if (index === -1) {
      setSelected([...selected, item]);
    } else {
      setSelected([...filtered]);
    }
  };
  const { setFloors } = useContext(RentContext);
  // QUERY
  const GET_RENT_STEP_7 = gql`
    query {
      rentQuestion(id: "7") {
        title
        slug
        id
        content
        background_image {
          url
          alternativeText
        }
        ...elements
      }
    }
    fragment elements on RentQuestions {
      questions {
        ... on ComponentElementsRadioButton {
          __typename
          id
          label
          value
          icon {
            url
            alternativeText
          }
          icon_text
        }
        ... on ComponentElementsInput {
          __typename
          id
          min
          max
          htmlFor
          step
          type
        }
        ... on ComponentElementsCheckbox {
          id
          icon {
            url
            alternativeText
          }
          label
          value
        }
      }
    }
  `;

  const { loading, error, data } = useQuery(GET_RENT_STEP_7);
  if (loading) return <AnimationLoading />;
  if (error) return "Error!";
  if (data) {
    if (animation) {
      return (
        <>
          <Delay delay="0">
            <QuestionHeader style={{ transform: "translateY(-50px)" }}>
              {data.rentQuestion.content}
            </QuestionHeader>
            <ButtonBlock>
              {data.rentQuestion.questions.map((x, i) => (
                <React.Fragment key={x.id}>
                  {x.__typename === "ComponentElementsRadioButton" ? (
                    <RadioButton
                      id={x.id}
                      icon={x.icon}
                      icon_text={x.icon_text}
                      onClick={(e) => handleOnClick(e)}
                      label={x.label}
                      value={x.value}
                    />
                  ) : null}
                </React.Fragment>
              ))}
            </ButtonBlock>

            <Button
              onClick={() => {
                nextStep();
                setFloors(selected);
              }}
              label="Next"
            />
          </Delay>
        </>
      );
    }
    const background = data.rentQuestion.background_image.url;
    return (
      <>
        <QuestionBlock
          backgroundImage={background}
          headerText={data.rentQuestion.content}
        >
          <ResponsiveButtonBlock>
            {data.rentQuestion.questions.map((x, i) => (
              <React.Fragment key={x.id}>
                {x.__typename === "ComponentElementsRadioButton" ? (
                  <ResponsiveRadioButton
                    id={x.id}
                    icon={x.icon}
                    icon_text={x.icon_text}
                    onClick={(e) => handleOnClick(e)}
                    label={x.label}
                    value={x.value}
                  />
                ) : null}
              </React.Fragment>
            ))}
          </ResponsiveButtonBlock>
          <Button
            onClick={() => {
              nextStep();
              setFloors(selected);
            }}
            label="Next"
          />
        </QuestionBlock>
      </>
    );
  }
};

const Step8 = ({ route, animation }) => {
  const { handleStep, previousStep, nextStep } = useWizard();
  // Multi Select
  const [selected, setSelected] = useState([]);
  const handleOnClick = (e) => {
    const item = e.target.value;
    var index = selected.indexOf(item);
    const newList = selected;
    const filtered = newList.filter((select) => select !== item);

    if (index == -1) {
      setSelected([...selected, item]);
    } else {
      setSelected([...filtered]);
    }
  };

  // Other Input
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const handleOther = (e) => {
    setInputVisible(!inputVisible);
  };

  const handleInputValue = (e) => {
    var index = selected.indexOf(inputValue);
    const newList = selected;
    const filtered = newList.filter((select) => select !== inputValue);
    if (index == -1) {
      setSelected([...selected, inputValue]);
    } else {
      setSelected([...filtered]);
    }
  };
  const { setFeatures } = useContext(RentContext);
  // QUERY
  const GET_RENT_STEP_8 = gql`
    query {
      rentQuestion(id: "8") {
        title
        slug
        id
        content
        background_image {
          url
          alternativeText
        }
        ...elements
      }
    }
    fragment elements on RentQuestions {
      questions {
        ... on ComponentElementsRadioButton {
          __typename
          id
          label
          value
          icon {
            url
            alternativeText
          }
          icon_text
        }
        ... on ComponentElementsInput {
          __typename
          id
          min
          max
          htmlFor
          step
          type
        }
        ... on ComponentElementsCheckbox {
          id
          icon {
            url
            alternativeText
          }
          label
          value
        }
      }
    }
  `;

  const { loading, error, data } = useQuery(GET_RENT_STEP_8);
  if (loading) return <AnimationLoading />;
  if (error) return "Error!";
  if (data) {
    if (animation) {
      return (
        <>
          <Delay delay="0">
            <QuestionHeader style={{ transform: "translateY(-50px)" }}>
              {data.rentQuestion.content}
            </QuestionHeader>
            <SelectButtonBlock>
              {data.rentQuestion.questions.map((x, i) => {
                return (
                  <React.Fragment key={x.id}>
                    {x.__typename === "ComponentElementsCheckbox" ? (
                      <SelectRadioButton
                        id={x.id}
                        icon={x.icon}
                        icon_text={x.icon_text}
                        onClick={(e) => handleOnClick(e)}
                        label={x.label}
                        value={x.value}
                      />
                    ) : null}
                  </React.Fragment>
                );
              })}
              <OtherInput
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onBlur={handleInputValue}
                placeholder="Other - Type Here"
                type="text"
              />
            </SelectButtonBlock>

            <Button
              onClick={() => {
                nextStep();
                setFeatures(selected);
              }}
              label="Next"
            />
          </Delay>
        </>
      );
    }

    const background = data.rentQuestion.background_image.url;
    return (
      <>
        <QuestionBlock
          backgroundImage={background}
          headerText={data.rentQuestion.content}
        >
          <ResponsiveButtonBlock>
            {data.rentQuestion.questions.map((x, i) => {
              return (
                <React.Fragment key={x.id}>
                  {x.__typename === "ComponentElementsCheckbox" ? (
                    <ResponsiveSelectRadioButton
                      id={x.id}
                      icon={x.icon}
                      icon_text={x.icon_text}
                      onClick={(e) => handleOnClick(e)}
                      label={x.label}
                      value={x.value}
                      width="190px"
                      height="50px"
                    />
                  ) : null}
                </React.Fragment>
              );
            })}
            <div>
              <ResponsiveOtherInput
                onChange={(e) => setInputValue(e.target.value)}
                onBlur={handleInputValue}
                placeholder="Other - Type Here"
                type="text"
                value={inputValue}
              />
            </div>
          </ResponsiveButtonBlock>

          <Button
            onClick={() => {
              nextStep();
              setFeatures(selected);
            }}
            label="Next"
          />
        </QuestionBlock>
      </>
    );
  }
};

const Step9 = ({ route, animation }) => {
  const { handleStep, previousStep, nextStep } = useWizard();
  // Multi Select
  const [selected, setSelected] = useState([]);
  const handleOnClick = (e) => {
    const item = e.target.value;
    var index = selected.indexOf(item);
    const newList = selected;
    const filtered = newList.filter((select) => select !== item);
    if (index == -1) {
      setSelected([...selected, item]);
    } else {
      setSelected([...filtered]);
    }
  };
  const { setLaundry } = useContext(RentContext);
  // QUERY
  const GET_RENT_STEP_9 = gql`
    query {
      rentQuestion(id: "9") {
        title
        slug
        id
        content
        background_image {
          url
          alternativeText
        }
        ...elements
      }
    }
    fragment elements on RentQuestions {
      questions {
        ... on ComponentElementsRadioButton {
          __typename
          id
          label
          value
          icon {
            url
            alternativeText
          }
          icon_text
        }
        ... on ComponentElementsInput {
          __typename
          id
          min
          max
          htmlFor
          step
          type
        }
        ... on ComponentElementsCheckbox {
          id
          icon {
            url
            alternativeText
          }
          label
          value
        }
      }
    }
  `;

  const { loading, error, data } = useQuery(GET_RENT_STEP_9);
  if (loading) return <AnimationLoading />;
  if (error) return "Error!";
  if (data) {
    if (animation) {
      return (
        <>
          <Delay delay="0">
            <QuestionHeader style={{ transform: "translateY(-90px)" }}>
              {data.rentQuestion.content}
            </QuestionHeader>

            <LaundryButtonBlock>
              {data.rentQuestion.questions.map((x, i) => (
                <React.Fragment key={x.id}>
                  {x.__typename === "ComponentElementsRadioButton" ? (
                    <SelectRadioButton
                      id={x.id}
                      icon={x.icon}
                      icon_text={x.icon_text}
                      onClick={(e) => handleOnClick(e)}
                      label={x.label}
                      value={x.value}
                    />
                  ) : null}
                </React.Fragment>
              ))}
            </LaundryButtonBlock>

            <Button
              onClick={() => {
                nextStep();
                setLaundry(selected);
              }}
              label="Next"
            />
          </Delay>
        </>
      );
    }

    const background = data.rentQuestion.background_image.url;
    return (
      <>
        <QuestionBlock
          backgroundImage={background}
          headerText={data.rentQuestion.content}
        >
          <ResponsiveButtonBlock>
            {data.rentQuestion.questions.map((x, i) => {
              return (
                <React.Fragment key={x.id}>
                  {x.__typename === "ComponentElementsRadioButton" ? (
                    <ResponsiveSelectRadioButton
                      id={x.id}
                      icon={x.icon}
                      icon_text={x.icon_text}
                      onClick={(e) => handleOnClick(e)}
                      label={x.label}
                      value={x.value}
                    />
                  ) : null}
                </React.Fragment>
              );
            })}
          </ResponsiveButtonBlock>

          <Button
            onClick={() => {
              nextStep();
              setLaundry(selected);
            }}
            label="Next"
          />
        </QuestionBlock>
      </>
    );
  }
};
const Step10 = ({ route, animation }) => {
  const { handleStep, previousStep, nextStep } = useWizard();
  // Multi Select
  const [selected, setSelected] = useState([]);

  function onlyOne(e) {
    console.log("isChecked", e.target.checked);
    console.log("==", e.target.value);
    var checkboxes = document.getElementsByName("check");
    console.log("cheek1", checkboxes[0]);
    console.log("cheek2", checkboxes[1]);
    console.log("cheek3", checkboxes[2]);
    checkboxes.forEach((item) => {
      if (item.value == e.target.value) {
        if (item.checked) item.checked = true;
      } else {
        item.checked = false;
      }
    });
  }

  const handleOnClick = (e) => {
    const item = e.target.value;
    setSelected(item);
    onlyOne(e);
  };

  console.log(selected);

  const { setMatters } = useContext(RentContext);

  // QUERY
  const GET_RENT_STEP_10 = gql`
    query {
      rentQuestion(id: "10") {
        title
        slug
        id
        content
        background_image {
          url
          alternativeText
        }
        ...elements
      }
    }
    fragment elements on RentQuestions {
      questions {
        ... on ComponentElementsRadioButton {
          __typename
          id
          label
          value
          icon {
            url
            alternativeText
          }
          icon_text
        }
        ... on ComponentElementsInput {
          __typename
          id
          min
          max
          htmlFor
          step
          type
        }
        ... on ComponentElementsCheckbox {
          id
          icon {
            url
            alternativeText
          }
          label
          value
        }
      }
    }
  `;

  const { loading, error, data } = useQuery(GET_RENT_STEP_10);
  if (loading) return <AnimationLoading />;
  if (error) return "Error!";
  if (data) {
    if (animation) {
      return (
        <>
          <Delay delay="0">
            <QuestionHeader style={{ transform: "translateY(-50px)" }}>
              {data.rentQuestion.content}
            </QuestionHeader>
            <MattersButtonBlock>
              {data.rentQuestion.questions.map((x, i) => {
                return (
                  <React.Fragment key={x.id}>
                    {x.__typename === "ComponentElementsRadioButton" ? (
                      <MattersLongRadioButton
                        id={x.id}
                        icon={x.icon}
                        icon_text={x.icon_text}
                        onClick={(e) => handleOnClick(e)}
                        label={x.label}
                        value={x.value}
                        type="radio"
                        name="select"
                      />
                    ) : null}
                  </React.Fragment>
                );
              })}
            </MattersButtonBlock>

            <Content> Don't worry, this won't remove any matches</Content>
            {selected.length === 0 ? (
              <Button disabled onClick={() => nextStep()} label="Next" />
            ) : (
              <Button
                onClick={() => {
                  nextStep();
                  setMatters(selected);
                }}
                label="Next"
              />
            )}
          </Delay>
        </>
      );
    }

    const background = data.rentQuestion.background_image.url;

    return (
      <>
        <QuestionBlock
          backgroundImage={background}
          headerText={data.rentQuestion.content}
        >
          <ResponsiveButtonBlock>
            {data.rentQuestion.questions.map((x, i) => {
              return (
                <React.Fragment key={x.id}>
                  {x.__typename === "ComponentElementsRadioButton" ? (
                    <ResponsiveLongRadioButton
                      id={x.id}
                      icon={x.icon}
                      icon_text={x.icon_text}
                      onClick={(e) => handleOnClick(e)}
                      label={x.label}
                      value={x.value}
                      type="radio"
                      name="select"
                    />
                  ) : null}
                </React.Fragment>
              );
            })}

            <Content> Don't worry, this won't remove any matches</Content>
          </ResponsiveButtonBlock>
          {selected.length === 0 ? (
            <Button disabled onClick={() => nextStep()} label="Next" />
          ) : (
            <Button
              onClick={() => {
                nextStep();
                setMatters(selected);
              }}
              label="Next"
            />
          )}
        </QuestionBlock>
      </>
    );
  }
};
const Step11 = ({ route, animation }) => {
  const { handleStep, previousStep, nextStep } = useWizard();
  // Multi Select
  const [selected, setSelected] = useState([]);
  function onlyOne(e) {
    console.log("isChecked", e.target.checked);
    console.log("==", e.target.value);
    var checkboxes = document.getElementsByName("check");
    console.log("cheek1", checkboxes[0]);
    console.log("cheek2", checkboxes[1]);
    console.log("cheek3", checkboxes[2]);
    checkboxes.forEach((item) => {
      if (item.value == e.target.value) {
        if (item.checked) item.checked = true;
      } else {
        item.checked = false;
      }
    });
  }

  const handleOnClick = (e) => {
    const item = e.target.value;
    setSelected(item);
    onlyOne(e);
  };
  const { setPriority } = useContext(RentContext);
  // QUERY
  const GET_RENT_STEP_11 = gql`
    query {
      rentQuestion(id: "11") {
        title
        slug
        id
        content
        background_image {
          url
          alternativeText
        }
        ...elements
      }
    }
    fragment elements on RentQuestions {
      questions {
        ... on ComponentElementsRadioButton {
          __typename
          id
          label
          value
          icon {
            url
            alternativeText
          }
          icon_text
        }
        ... on ComponentElementsInput {
          __typename
          id
          min
          max
          htmlFor
          step
          type
        }
        ... on ComponentElementsCheckbox {
          id
          icon {
            url
            alternativeText
          }
          label
          value
        }
      }
    }
  `;

  const { loading, error, data } = useQuery(GET_RENT_STEP_11);
  if (loading) return <AnimationLoading />;
  if (error) return "Error!";
  if (data) {
    if (animation) {
      return (
        <>
          <Delay delay="0">
            <QuestionHeader style={{ transform: "translateY(-50px)" }}>
              {data.rentQuestion.content}
            </QuestionHeader>
            <LongButtonBlock>
              {data.rentQuestion.questions.map((x, i) => {
                return (
                  <React.Fragment key={x.id}>
                    {x.__typename === "ComponentElementsRadioButton" ? (
                      <ResponsiveLongRadioButton
                        id={x.id}
                        icon={x.icon}
                        icon_text={x.icon_text}
                        onClick={(e) => handleOnClick(e)}
                        label={x.label}
                        value={x.value}
                        name="select"
                        type="radio"
                      />
                    ) : null}
                  </React.Fragment>
                );
              })}
            </LongButtonBlock>

            {selected.length === 0 ? (
              <Button disabled onClick={() => nextStep()} label="Next" />
            ) : (
              <Button
                onClick={() => {
                  nextStep();
                  setPriority(selected);
                }}
                label="Next"
              />
            )}
          </Delay>
        </>
      );
    }
    const background = data.rentQuestion.background_image.url;

    return (
      <>
        <QuestionBlock
          backgroundImage={background}
          headerText={data.rentQuestion.content}
        >
          <ResponsiveButtonBlock>
            {data.rentQuestion.questions.map((x, i) => {
              return (
                <React.Fragment key={x.id}>
                  {x.__typename === "ComponentElementsRadioButton" ? (
                    <LongRadioButton
                      id={x.id}
                      icon={x.icon}
                      icon_text={x.icon_text}
                      onClick={(e) => handleOnClick(e)}
                      label={x.label}
                      value={x.value}
                      name="select"
                      type="radio"
                    />
                  ) : null}
                </React.Fragment>
              );
            })}
          </ResponsiveButtonBlock>

          {selected.length === 0 ? (
            <Button disabled onClick={() => nextStep()} label="Next" />
          ) : (
            <Button
              onClick={() => {
                nextStep();
                setPriority(selected);
              }}
              label="Next"
            />
          )}
        </QuestionBlock>
      </>
    );
  }
};
const Step12 = ({ route, animation }) => {
  const { handleStep, previousStep, nextStep } = useWizard();
  const { setMonths, months } = useContext(RentContext);
  // QUERY
  const GET_RENT_STEP_12 = gql`
    query {
      rentQuestion(id: "12") {
        title
        slug
        id
        content
        background_image {
          url
          alternativeText
        }
        ...elements
      }
    }
    fragment elements on RentQuestions {
      questions {
        ... on ComponentElementsRadioButton {
          __typename
          id
          label
          value
          icon {
            url
            alternativeText
          }
          icon_text
        }
        ... on ComponentElementsInput {
          __typename
          id
          min
          max
          htmlFor
          step
          type
          minLabel
          maxLabel
          sliderId
          value
          inputLabelRight
          inputLabelLeft
        }
        ... on ComponentElementsCheckbox {
          id
          icon {
            url
            alternativeText
          }
          label
          value
        }
      }
    }
  `;

  const { loading, error, data } = useQuery(GET_RENT_STEP_12);
  if (loading) return <AnimationLoading />;
  if (error) return "Error!";
  if (data) {
    if (animation) {
      return (
        <>
          <Delay delay="0">
            <QuestionHeader style={{ transform: "translateY(-50px)" }}>
              {data.rentQuestion.content}
            </QuestionHeader>
            <ButtonBlock>
              {data.rentQuestion.questions.map((x, i) => (
                <React.Fragment key={x.id}>
                  {x.__typename === "ComponentElementsInput" ? (
                    <>
                      <SliderBlock
                        min={x.min}
                        max={x.max}
                        placeholder={months ? months : "XX"}
                        onChange={(e) => setMonths(e.target.value)}
                        value={months}
                        minLabel={x.minLabel}
                        maxLabel={x.maxLabel}
                        sliderId="months"
                        step={x.step}
                        type="number"
                        inputLabelRight={x.inputLabelRight}
                      />
                    </>
                  ) : null}
                </React.Fragment>
              ))}
            </ButtonBlock>
            <p>You can type or slide your answer</p>

            <ButtonWrapper>
              <Button
                onClick={() => {
                  nextStep();
                  setMonths(months);
                }}
                label="Next"
              />
            </ButtonWrapper>
          </Delay>
        </>
      );
    }

    const background = data.rentQuestion.background_image.url;

    return (
      <>
        <QuestionBlock
          backgroundImage={background}
          headerText={data.rentQuestion.content}
        >
          <ResponsiveButtonBlock>
            {data.rentQuestion.questions.map((x, i) => (
              <React.Fragment key={x.id}>
                {x.__typename === "ComponentElementsInput" ? (
                  <>
                    <ResponsiveSliderBlock
                      min={x.min}
                      max={x.max}
                      placeholder={months ? months : "XXX,XXX"}
                      onChange={(e) => setMonths(e.target.value)}
                      value={months}
                      minLabel={x.minLabel}
                      maxLabel={x.maxLabel}
                      sliderId="months"
                      step={x.step}
                      type="number"
                      inputLabelRight={x.inputLabelRight}
                    />
                  </>
                ) : null}
              </React.Fragment>
            ))}
          </ResponsiveButtonBlock>
          <p>You can type or slide your answer</p>

          <ButtonWrapper>
            <Button
              onClick={() => {
                nextStep();
                setMonths(months);
              }}
              label="Next"
            />
          </ButtonWrapper>
        </QuestionBlock>
      </>
    );
  }
};
const Step13 = ({ route, animation }) => {
  const { handleStep, previousStep, nextStep } = useWizard();
  // Multi Select
  const [selected, setSelected] = useState([]);
  function onlyOne(e) {
    console.log("isChecked", e.target.checked);
    console.log("==", e.target.value);
    var checkboxes = document.getElementsByName("check");
    console.log("cheek1", checkboxes[0]);
    console.log("cheek2", checkboxes[1]);
    console.log("cheek3", checkboxes[2]);
    checkboxes.forEach((item) => {
      if (item.value == e.target.value) {
        if (item.checked) item.checked = true;
      } else {
        item.checked = false;
      }
    });
  }

  const handleOnClick = (e) => {
    const item = e.target.value;
    setSelected(item);
    onlyOne(e);
  };
  const { setHistory } = useContext(RentContext);
  // QUERY
  const GET_RENT_STEP_13 = gql`
    query {
      rentQuestion(id: "13") {
        title
        slug
        id
        content
        background_image {
          url
          alternativeText
        }
        ...elements
      }
    }
    fragment elements on RentQuestions {
      questions {
        ... on ComponentElementsRadioButton {
          __typename
          id
          label
          value
          icon {
            url
            alternativeText
          }
          icon_text
        }
        ... on ComponentElementsInput {
          __typename
          id
          min
          max
          htmlFor
          step
          type
        }
        ... on ComponentElementsCheckbox {
          id
          icon {
            url
            alternativeText
          }
          label
          value
        }
      }
    }
  `;
  const { loading, error, data } = useQuery(GET_RENT_STEP_13);
  if (loading) return <AnimationLoading />;
  if (error) return "Error!";
  if (data) {
    if (animation) {
      return (
        <>
          <Delay delay="0">
            <QuestionHeader style={{ transform: "translateY(-50px)" }}>
              {data.rentQuestion.content}
            </QuestionHeader>
            <ButtonBlock>
              {data.rentQuestion.questions.map((x, i) => {
                return (
                  <React.Fragment key={x.id}>
                    {x.__typename === "ComponentElementsRadioButton" ? (
                      <ResponsiveLongRadioButton
                        id={x.id}
                        icon={x.icon}
                        icon_text={x.icon_text}
                        onClick={(e) => handleOnClick(e)}
                        label={x.label}
                        value={x.value}
                        name="select"
                        type="radio"
                      />
                    ) : null}
                  </React.Fragment>
                );
              })}
            </ButtonBlock>
            {!selected ? (
              <Button disabled onClick={() => nextStep()} label="Next" />
            ) : (
              <Button
                onClick={() => {
                  nextStep();
                  setHistory(selected);
                }}
                label="Next"
              />
            )}
          </Delay>
        </>
      );
    }
    const background = data.rentQuestion.background_image.url;
    return (
      <>
        <QuestionBlock
          backgroundImage={background}
          headerText={data.rentQuestion.content}
        >
          <ResponsiveButtonBlock>
            {data.rentQuestion.questions.map((x, i) => {
              return (
                <React.Fragment key={x.id}>
                  {x.__typename === "ComponentElementsRadioButton" ? (
                    <LongRadioButton
                      id={x.id}
                      icon={x.icon}
                      icon_text={x.icon_text}
                      onClick={(e) => handleOnClick(e)}
                      label={x.label}
                      value={x.value}
                      name="select"
                      type="radio"
                    />
                  ) : null}
                </React.Fragment>
              );
            })}
          </ResponsiveButtonBlock>
          {!selected ? (
            <Button disabled onClick={() => nextStep()} label="Next" />
          ) : (
            <Button
              onClick={() => {
                nextStep();
                setHistory(selected);
              }}
              label="Next"
            />
          )}
        </QuestionBlock>
      </>
    );
  }
};
const Step14 = ({ route, animation }) => {
  const { handleStep, previousStep, nextStep } = useWizard();
  // get context storage
  // Rental
  const {
    rental,
    bedrooms,
    budget,
    amenities,
    pets,
    floors,
    features,
    laundry,
    matters,
    priority,
    months,
  } = useContext(RentContext);
  let history = useHistory();

  const { address, boundaries } = useContext(MapContext);
  const { loader } = useContext(AnimationContext);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const background =
    "https://househawkbucket.s3.amazonaws.com/final_step_77fca47fa6.png";

  const [createNewRentInquiry] = useMutation(CREATE_NEW_RENT_INQUIRY, {
    variables: {
      name: name,
      email: email,
      phone: phone,
      rental: rental,
      bedrooms: bedrooms,
      budget: budget,
      location: address,
      amenities: amenities,
      pets: pets,
      floor: floors,
      features: features,
      laundry: laundry,
      matters: matters,
      priority: priority,
      length: months,
      history: history,
      boundaries: boundaries,
    },
    onCompleted(data) {
      history.push("/success");
    },
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    createNewRentInquiry();
  };
  if (loader) {
    return <ResultsLoader delay="4800" />;
  }
  if (!loader) {
    return (
      <>
        <FinalQuestionBlock backgroundImage={background}>
          <p>
            Enter your information to see your results and get real-time updates
            delivered right to your inbox.
          </p>
          <form onSubmit={handleSubmit} className="final-form">
            <Label htmlFor="name">
              <span className="image_space">
                <PersonIcon />
              </span>
              <Input
                placeholder="Name"
                type="name"
                id="name"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Label>
            <Label htmlFor="Email">
              <span className="image_space">
                <EmailIcon />
              </span>
              <Input
                placeholder="Email"
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Label>
            <p
              style={{
                textAlign: "center",
                marginBottom: 10,
              }}
            >
              And/Or
            </p>
            <Label htmlFor="phone">
              <span className="image_space">
                <LocalPhoneIcon />
              </span>
              <Input
                placeholder="Phone"
                type="phone"
                id="phone"
                name="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </Label>
            {!email || !name ? (
              <Button
                disabled
                onClick={() => nextStep()}
                label="See My Matches"
                btnClass="submit-button"
              />
            ) : (
              <Button
                type="submit"
                label="See My Matches"
                btnClass="submit-button"
              />
            )}
            You agree to The House Hawk LLC's Terms of Use & Privacy Policy and
            to be contacted by us or third parties. By registering, you give us
            your express written consent to deliver automated emails to you.
            Consent is not a condition of purchase. Your registration acts as
            your binding electronic signature.
          </form>
        </FinalQuestionBlock>
      </>
    );
  }
};
export default Rent;
const ButtonWrapper = styled.div`
  display: block;
  margin: 0 auto;
`;
const QuestionHeader = styled.div`
  text-align: center;
  font-size: 1.8rem;
  margin: 0 auto;
  width: 420px;
  font-weight: bold;
  text-shadow: 2px 2px #000;
`;
const Seperator = styled.div`
  width: 100%;
  padding-top: 4px;
  padding-bottom: 4px;
`;
const Content = styled.div`
  font-size: 16px;
  margin: 0px auto;
  margin-top: 16px;
  margin-bottom: 16px;
  font-weight: bold;
  text-shadow: 1px 1px black;
`;
const SliderWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;
