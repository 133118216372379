import React, { useState } from "react";
import styled from "styled-components";
import Input from "./Input";
import { device } from "../../../utils/mediaQueries";
import Slider from "@mui/material/Slider";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

const ResponsiveDualSliderBlock = ({
  value,
  min,
  max,
  budget,
  onChange,
  handleAmountMaxInput,
  handleAmountMinInput,
  minLabel,
  maxLabel,
  placeholderMax,
  placeholderMin,
  sliderId,
  ml,
  width,
  inputLabelLeft,
  inputLabelRight,
  step,
  placeholder,
  type,
  minYear,
  maxYear,
  minValue,
  maxValue,
  handleChange,
  marks,
}) => {
  console.log(max, min);
  console.log(inputLabelLeft);
  return (
    <Box sx={{ flexGrow: 1 }}>
      <SliderWrapper>
        <StlyedSliderInput>
          {inputLabelLeft}
          <StyledNumber
            onChange={handleAmountMinInput}
            type="number"
            min={min}
            max={max}
            step={step}
            value={value[0]}
            placeholder={placeholderMin}
            disabled
          />
        </StlyedSliderInput>

        <StlyedSliderInput>
          {inputLabelRight}
          <StyledNumber
            onChange={handleAmountMaxInput}
            type="number"
            min={min}
            max={max}
            step={step}
            value={value[1]}
            placeholder={placeholderMax}
            disabled
          />
        </StlyedSliderInput>
      </SliderWrapper>
      <Slider
        sx={{ width: "80%" }}
        getAriaLabel={() => "Minimum distance"}
        value={value}
        onChange={handleChange}
        valueLabelDisplay="auto"
        disableSwap
        min={1900}
        max={2023}
        marks={marks}
      />
    </Box>
  );
};

export default ResponsiveDualSliderBlock;
const DateRangeLeft = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: left;
  transform: translate(8px, -12px);
  font-size: 14px;
  color: black;
`;
const DateRangeRight = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  text-align: right;
  font-size: 14px;
  color: black;
  transform: translate(-4px, -12px);
`;
const SliderWrapper = styled.div`
  width: 500px;
  margin: 0px auto;
  text-align: center;
  position: relative;
  display: flex;
  flex-wrap: flex;
  overflow: hidden;
  justify-content: space-evenly;
`;

const StyledRange = styled.input.attrs({
  type: "range",
})`
  width: 100%;
  height: 20px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  color: black;
  margin-bottom: 1rem;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  position: absolute;
  bottom: 0;
  left: 0;
  &:focus {
    outline: none;
  }
  -webkit-appearance: none;

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 20px;
    cursor: pointer;
    animate: 0.2s;
    background: #d3d3d3;
    border-radius: 5px;
    box-shadow: none;
    border: 2px solid #adcae6;
  }
  &:focus::-ms-fill-lower {
    background: #b67df3;
  }
  &:focus::-ms-fill-upper {
    background: #b67df3;
  }
  &::-webkit-slider-thumb {
    z-index: 2;
    position: relative;
    margin-top: -5px;
    box-shadow: 0px 0px 0px #000;
    border: 1px solid #b67df3;
    height: 25px;
    width: 25px;
    border-radius: 25px;
    background: #8233d4;
    cursor: pointer;
    -webkit-appearance: none;
  }
`;

const StyledNumber = styled.input.attrs({
  type: "number",
})`
  width: 60%;
  margin: 0px auto;
  text-align: center;
  letter-spacing: 5.6px;
  border-radius: 8px;
  border: 2px solid black;
  height: calc(1.6em + 0.75rem + 2px);
  background: #fdcae1;
  padding: 5px;
  @media () &::placeholder {
    color: #f9398f;
  }
`;
const StlyedSliderInput = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 240px;
  margin: 10px;
  background: #fdcae1;
  color: #f9398f;
  letter-spacing: 2px;
  border-radius: 25px;
  border: 2px solid #adcae6;
  margin-bottom: 40px;
`;
const SliderRange = styled.div`
  ul {
    display: flex;
    width: 100%;
  }

  li:last-child {
    margin-left: auto;
  }
`;
