import React from "react";
import styled, { css } from "styled-components";
import { device } from "../../../utils/mediaQueries";
const ResponsiveMapQuestionBox = ({
  backgroundImage,
  headerText,
  route,
  animation,
  children,
}) => {
  console.log(backgroundImage);
  return (
    <QuestionBlockWrapper backgroundImage={backgroundImage} route={route}>
      {children}

      {/* <StyledQuestionBlock>
        <Content>
          <QuestionHeader>
            <p>{headerText} </p>
          </QuestionHeader>
          {children}
        </Content>
      </StyledQuestionBlock> */}
    </QuestionBlockWrapper>
  );
};

export default ResponsiveMapQuestionBox;
const QuestionBlockWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-image: url("${(props) => props.backgroundImage}");
`;
