import React, { createContext, useState, useEffect } from "react";

const BuyContext = createContext();

export const BuyProvider = (props) => {
  // Step 1
  const [type, setType] = useState("");

  // Step 2
  const [bedrooms, setBedrooms] = useState("");
  const [bathrooms, setBathrooms] = useState("");

  // Step 3
  const [budget, setBudget] = useState(0);
  const [monthly, setMonthly] = useState(0);

  // Step 4
  const [features, setFeatures] = useState("");

  // Step 5 from MAP Context

  // Step 6
  const [floors, setFloors] = useState("");

  // Step 7
  const [minYear, setMinYear] = useState(1900);
  const [maxYear, setMaxYear] = useState(2023);

  // Step 8
  const [amenities, setAmenities] = useState("");

  // Step 9
  const [matters, setMatters] = useState("");

  // Step 10
  const [priority, setPriority] = useState("");

  const buyData = {
    type,
    bedrooms,
    bathrooms,
    budget,
    monthly,
    features,
    minYear,
    maxYear,
    amenities,
    floors,
    matters,
    priority,
  };

  return (
    <BuyContext.Provider
      value={{
        type,
        setType,
        bedrooms,
        setBedrooms,
        bathrooms,
        setBathrooms,
        budget,
        setBudget,
        monthly,
        setMonthly,
        features,
        setFeatures,
        minYear,
        setMinYear,
        maxYear,
        setMaxYear,
        amenities,
        setAmenities,
        floors,
        setFloors,
        matters,
        setMatters,
        priority,
        setPriority,
        buyData,
      }}
    >
      {props.children}
    </BuyContext.Provider>
  );
};

export default BuyContext;
