import React, { useContext } from "react";
import styled, { css } from "styled-components";
import { device } from "../../../utils/mediaQueries";
import AnimationContext from "../../../context/AnimationContext";

const MapQuestionBlock = ({
  backgroundImage,
  headerText,
  animation,
  children,
}) => {
  const { route } = useContext(AnimationContext);

  return (
    <QuestionBlockWrapper backgroundImage={backgroundImage} route={route}>
      {children}

      {/* <StyledQuestionBlock>
        <Content>
          <QuestionHeader>
            <p>{headerText} </p>
          </QuestionHeader>
          {children}
        </Content>
      </StyledQuestionBlock> */}
    </QuestionBlockWrapper>
  );
};

export default MapQuestionBlock;
const QuestionBlockWrapper = styled.div`
  background-position: center;
  background-repeat: no-repeat;

  @media (max-width: 1280px) {
    width: 1280px;
    height: 800px;
  }

  @media (max-width: 3440px) {
    height: 100vh;
  }
  ${(props) =>
    props.animation === true ||
    (props.route === "isRent" &&
      css`
        background-image: url("https://househawkbucket.s3.amazonaws.com/househawkoneyellow_d406e53cf5.png");
      `)}
  ${(props) =>
    props.animation === true ||
    (props.route === "isBuy" &&
      css`
        background-image: url("backgrounds/househawkonebackgroundpurple_cc672e141d.png");
      `)}
  ${(props) =>
    props.animation === true ||
    (props.route === "isSell" &&
      css`
        background-image: url("https://househawkbucket.s3.amazonaws.com/househawkonered_8280daae68.png");
      `)}
        ${(props) =>
    props.animation === true ||
    (props.route === "isInvest" &&
      css`
        background-image: url("https://househawkbucket.s3.amazonaws.com/househawkonegreen_bb68cef909.png");
      `)}
`;
const QuestionHeader = styled.div`
  transform: translateY(-3vh);
  text-align: center;
  font-size: 1.6rem;
  margin: 0 auto;
  width: 300px;
`;
const StyledQuestionBlock = styled.div`
  @media (max-width: 1680px) {
    width: 540px;
    display: block;
    justify-content: center;
    align-items: center;
    color: white;
    position: fixed;
    top: 50%;
    left: 50%;
    margin-top: 50px;
    margin-left: -255px;
  }
  @media (min-width: 1920px) {
    display: block;
    justify-content: center;
    align-items: center;
    color: white;
    width: 640px;
    position: fixed;
    top: 50%;
    left: 50%;
    margin-top: 50px;
    margin-left: -300px;
  }
`;

const Content = styled.div`
  padding: 0px;
  background-color: light-grey;
  display: flex;
  flex-direction: column;
  width: 420px;
  justify-content: center;
  border: 0px;
  align-items: center;
  text-align: center;
  margin: 0 auto;
  font-size: 1.6rem;
`;
