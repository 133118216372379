import React from "react";
import styled from "styled-components";

const ButtonRow = ({ children }) => {
  return <StyledButtonRow>{children}</StyledButtonRow>;
};

export default ButtonRow;

const StyledButtonRow = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: green;
  width: 6000px;
`;
