import React, { useContext } from "react";
import { TheSun, Loader } from ".";

import AnimationContext from "../../../context/AnimationContext";

const AnimationLoading = () => {
  const { animation } = useContext(AnimationContext);

  if (animation) {
      return null
  } else {
    return <Loader />;
  }
};

export default AnimationLoading;
