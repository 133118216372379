import React, { useContext } from "react";
import styled, { css } from "styled-components";
import { ProgressBar, StepProgressBar, Button } from "../../common";
import AnimationContext from "../../../context/AnimationContext";
import { device } from "../../../utils/mediaQueries";
const BottomBar = ({
  activeStep,
  stepCount,
  label,
  children,
  color,
  completed,
  route,
  percent,
}) => {
  const { animation, toggleAnimation, windowWidth } =
    useContext(AnimationContext);

  // if (windowWidth < 1280) {
  //   return null;
  // }

  return (
    <StyledBottomBar windowWidth={windowWidth}>
      <TopRow>
        <StepProgressBar
          activeStep={activeStep}
          stepCount={stepCount}
          route={route}
          percent={percent}
        />
      </TopRow>
      <Row>
        <Button onClick={toggleAnimation}>
          {animation ? "Animation Off" : "Animation On"}
        </Button>
      </Row>
    </StyledBottomBar>
  );
};

export default BottomBar;

const StyledBottomBar = styled.div`
  width: 100vw;
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  height: 40px;
`;

const TopRow = styled.div`
  margin: 0px auto;
  width: 50vw;
  bottom: 10px;
  position: fixed;
`;

const Row = styled.div`
  margin-left: auto;
  @media ${device.phone} {
    display: none;
  }
  @media ${device.xs} {
    display: block;
  }
`;
const Grass = styled.div`
  ${(props) =>
    props.animation == true &&
    css`
      background-color: green;
      height: 40px;
      width: 100vw;
      bottom: 0px;
      position: absolute;
      z-index: -90;
    `}
`;
