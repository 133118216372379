export const breakpoints = {
    phone: 320,
    xs: 576,
    sm: 768,
    md: 992,
    lg: 1024,
    xl: 1440,
    xxl: 1800
};

export const size = {
    phone: `${breakpoints.phone}px`,
    xs: `${breakpoints.xs}px`,
    sm: `${breakpoints.sm}px`,
    md: `${breakpoints.md}px`,
    lg: `${breakpoints.lg}px`,
    xl: `${breakpoints.xl}px`,
    xxl: `${breakpoints.xxl}px`
};

export const device = {
    phone: `only screen and (min-width: ${size.phone})`,
    xs: `only screen and (min-width: ${size.xs})`,
    sm: `only screen and (min-width: ${size.sm})`,
    md: `only screen and (min-width: ${size.md})`,
    lg: `only screen and (min-width: ${size.lg})`,
    xl: `only screen and (min-width: ${size.xl})`,
    xxl: `only screen and (min-width: ${size.xxl})`
};

export const variants = {
    container: {
        mx: 'auto',
        maxWidth: 1440,
        width: '100%',
        px: 30,
    }
}