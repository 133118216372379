import * as React from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import {
  Container,
  Grid,
  Box,
  Stack,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Tab,
  Tabs,
} from "@mui/material";
// components
import styled from "styled-components";

import AccordionAboutUs from "../../about-us/AccordionAboutUs";
import Page from "../../Page";
import AccordionSell from "../../about-us/AccordianSell";
import AccordionBuy from "../../about-us/AccordianBuy";
import AccordianInvest from "../../about-us/AccordianInvest";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function About() {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <Wrapper sx={{ p: 4 }}>
      <Grid
        sx={{
          backgroundColor: "blue",
          fontColor: "white",
          p: 8,
          height: 200,
          color: "white",
        }}
        container
        spacing={2}
      >
        <Grid item>
          <Typography variant="h2">Frequently Asked Questions</Typography>
        </Grid>
        <Box flexGrow={1} />
        <Grid item>
          <Tabs
            sx={{
              color: "white",
            }}
            value={value}
            TabIndicatorProps={{
              style: { background: "cyan", height: "6px", top: "0px" },
            }}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="inherit"
            aria-label="full width tabs example"
          >
            <Tab sx={{ fontSize: 24 }} label="About Us" {...a11yProps(0)} />
            <Tab sx={{ fontSize: 24 }} label="Seller" {...a11yProps(1)} />
            <Tab sx={{ fontSize: 24 }} label="Buyer" {...a11yProps(2)} />
            <Tab sx={{ fontSize: 24 }} label="Investor" {...a11yProps(3)} />
          </Tabs>
        </Grid>
      </Grid>
      <Container sx={{ overflowY: "scroll", height: 600 }}>
        <Grid item sx={{ mt: 4 }}>
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            <TabPanel value={value} index={0} dir={theme.direction}>
              <AccordionAboutUs />
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
              <AccordionSell />
            </TabPanel>
            <TabPanel value={value} index={2} dir={theme.direction}>
              <AccordionBuy />
            </TabPanel>
            <TabPanel value={value} index={3} dir={theme.direction}>
              <AccordianInvest />
            </TabPanel>
          </SwipeableViews>
        </Grid>
      </Container>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-top: 20px;
  font-weight: bold;
`;
