import React, { useState, useContext, useEffect } from "react";
import styled, { css, keyframes } from "styled-components";
import { useHistory } from "react-router";
import AnimationContext from "../../../context/AnimationContext";
import { Button } from "../../common";
import ArrowIcon from "../../../assets/arrow.svg";
import { GeoCoder } from "../../common/types";
import MapContext from "../../../context/MapContext";
import bkgimg from "../../../assets/heroBg.jpg";
import { Spinner } from "react-bootstrap";
import SearchIcon from "@mui/icons-material/Search";
import { device } from "../../../utils/mediaQueries";
import { Link } from "react-router-dom";
import { ReactComponent as Search } from "../../../assets/search.svg";
const Landing = () => {
  // state for button
  const [hoverClick, setHoverClick] = useState("blue");
  const [hoverColor, setHoverColor] = useState("blue");
  const [hoverWidth, setHoverWidth] = useState("92px");

  // location
  const [location, setLocation] = useState("");
  const { latlng, address } = useContext(MapContext);

  // onClick for search button
  const history = useHistory();
  const [label, setLabel] = useState("");
  const [selected, setSelected] = useState({
    Buy: false,
    Rent: false,
    Invest: false,
    Sell: false,
  });

  const buttonToggle = (buttonName) => {
    setSelected((prev) => ({ [buttonName]: !prev[buttonName] }));
  };
  const onClickForward = (e) => {
    e.preventDefault();
    localStorage.setItem("location", JSON.stringify(location));
    history.push(`/${label}`);
  };

  // context
  const { route, setRoute, setAnimation, setAnimationIn, animationIn } =
    useContext(AnimationContext);

  //Check Screen Width
  const [width, setWidth] = useState(window.innerWidth);

  //Hides Animation Screen based on width
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  useEffect(() => {
    width < 1280 && handleToggleOn();
  });

  function handleToggleOn() {
    setAnimation(false);
  }

  // check hoverClick State
  const hoverClickCheck = () => {
    if (hoverClick === "red") {
      setHoverColor("red");
    } else if (hoverClick === "yellow") {
      setHoverColor("yellow");
    } else if (hoverClick === "green") {
      setHoverColor("green");
    } else if (hoverClick === "blue") {
      setHoverColor("blue");
    }
  };

  const { Buy, Invest, Sell, Rent } = selected;
  return (
    <>
      {" "}
      <Wrapper>
        <Container>
          <Title>
            <h1>The Hawk Is In The House!</h1>
            <h4>LET THE HOUSE HAWK FIND YOU THE RIGHT HOUSE, RIGHT NOW.</h4>
          </Title>
          <GoalWrapper>
            <GoalCol>
              <h4>SELECT YOUR GOALS: </h4>
            </GoalCol>
            <GoalCol>
              <Arrow src={ArrowIcon} alt="Red Arrow pointing to selection" />
            </GoalCol>

            <LandingButtonsRow>
              <HoverLine
                hoverWidth={hoverWidth}
                hoverColor={hoverColor}
              ></HoverLine>
              <LandingButton
                onClick={(e) => {
                  setLabel("Buy");
                  setRoute("isBuy");
                  setHoverClick("blue");
                  buttonToggle("Buy");
                  setAnimationIn(true);
                }}
                selected={Buy}
                selectedFont="white"
                onMouseEnter={() => {
                  setHoverColor("blue");
                }}
                onMouseLeave={() => {
                  hoverClickCheck();
                }}
                variant="#0052FF"
              >
                Buy
              </LandingButton>
              <LandingButton
                onClick={(e) => {
                  setLabel("Sell");
                  setRoute("isSell");
                  setHoverClick("red");
                  buttonToggle("Sell");
                  setAnimationIn(true);
                }}
                variant="#ff0800"
                selected={Sell}
                selectedFont="white"
                label={label}
                onMouseEnter={() => {
                  setHoverColor("red");
                }}
                onMouseLeave={() => {
                  hoverClickCheck();
                }}
              >
                Sell
              </LandingButton>
              <LandingButton
                onClick={(e) => {
                  setLabel("Invest");
                  setRoute("isInvest");
                  setHoverClick("green");
                  buttonToggle("Invest");
                  setAnimationIn(true);
                }}
                selected={Invest}
                selectedFont="white"
                label={label}
                onMouseEnter={() => {
                  setHoverColor("green");
                }}
                onMouseLeave={() => {
                  hoverClickCheck();
                }}
                variant="#00b050"
              >
                Invest
              </LandingButton>
              {/* <LandingButton
              onClick={(e) => {
                setLabel("Sell");
                setRoute("isSell");
                setHoverClick("red");
                buttonToggle("Sell");
                setAnimationIn(true);
              }}
              variant="#ff0800"
              selected={Sell}
              selectedFont="white"
              label={label}
              onMouseEnter={() => {
                setHoverColor("red");
              }}
              onMouseLeave={() => {
                hoverClickCheck();
              }}
            >
              Sell
            </LandingButton>
            <LandingButton
              onClick={(e) => {
                setLabel("Invest");
                setRoute("isInvest");
                setHoverClick("green");
                buttonToggle("Invest");
                setAnimationIn(true);
              }}
              selected={Invest}
              selectedFont="white"
              label={label}
              onMouseEnter={() => {
                setHoverColor("green");
              }}
              onMouseLeave={() => {
                hoverClickCheck();
              }}
              variant="#00b050"
            >
              Invest
            </LandingButton>
            <LandingButton
              onClick={(e) => {
                setLabel("Rent");
                setRoute("isRent");
                setHoverClick("yellow");
                buttonToggle("Rent");
                setAnimationIn(true);
              }}
              variant="#ffe817"
              selected={Rent}
              selectedFont="black"
              label={label}
              onMouseEnter={() => {
                setHoverColor("yellow");
              }}
              onMouseLeave={() => {
                hoverClickCheck();
              }}
            >
              Rent
            </LandingButton> */}
            </LandingButtonsRow>
          </GoalWrapper>
          <GoalWrapper>
            <GoalCol>
              <h4 className="sm-margin margin-top">
                SELECT YOUR LOCATION: THEN CLICK{" "}
                <SearchIcon
                  sx={{ fontSize: 24, verticalAlign: "middle", marginRight: 1 }}
                />
                TO BEGIN
              </h4>
            </GoalCol>

            <GoalCol>
              <Arrow src={ArrowIcon} alt="Red Arrow pointing to selection" />
            </GoalCol>
            <GeoWrapper className="loc_div flex-row justify-center">
              <GeoCoder />
              {address === "Please enable location" ||
              address === "" ||
              !route ? (
                <span
                  onClick={(e) =>
                    alert("Please select a goal and enter a location")
                  }
                  onKeyDown={(e) =>
                    handleKeyDown(e, () => {
                      alert("Please select a goal and enter a location");
                    })
                  }
                  style={{ cursor: "pointer" }}
                >
                  <Search className="grey_svg"></Search>
                </span>
              ) : (
                <span
                  onClick={(e) => onClickForward(e)}
                  onKeyDown={(e) => handleKeyDown(e, onClickForward)}
                  style={{ cursor: "pointer" }}
                >
                  <Search className="purple_svg" />
                </span>
              )}
            </GeoWrapper>
          </GoalWrapper>
        </Container>
      </Wrapper>
    </>
  );
};

export default Landing;
const Footer = styled.div`
  display: flex;
  width: 100vw;
  justify-content: center;
  align-items: center;
  height: 100px;
  background-color: #000;
  color: #fff;
  font-size: 1.5rem;
`;
const Wrapper = styled.div`
  background-image: url(${bkgimg});
  background-position: center;
  background-size: cover;
  height: 100vh;
  display: flex;
  .landing-location {
    height: 2.2rem;
    border-radius: 4px;
    margin-left: 1rem;
    padding: 0.4rem 0.4rem 0.4rem 1.6rem;
    border: 1px solid $light-grey;
  }

  @media (min-width: 320px) {
    width: 100vw;
  }
  @media ${device.sm} {
    width: 100vw;
  }

  @media (min-width: 1024px) {
    width: 100vw;
  }
`;

const Container = styled.div`
  @media ${device.phone} {
    width: 94%;
    padding: 10px;
    margin-top: 50px;
    margin-bottom: 0px;
    h1 {
      font-size: 24px;
    }
  }
  @media ${device.xs} {
    width: 100%;
    margin-top: 10vh;
    h1 {
      font-size: 3.6rem;
    }
  }
  @media ${device.sm} {
    width: 100%;
    margin-top: 30vh;
    margin-left: 5vw;
    h1 {
      font-size: 3.6rem;
    }
  }
  @media ${device.lg} {
    width: 800px;
    padding: 40px;
    margin-top: 30vh;
    margin-left: 10vw;
    h1 {
      font-size: 3.6rem;
    }
  }
`;
const Title = styled.div`
  margin-bottom: 4rem;
  width: 100%;
`;
const GoalWrapper = styled.div`
  @media ${device.phone} {
    display: block;
    width: 100%;
  }
  @media ${device.sm} {
    display: flex;
    width: 100%;
  }
  @media ${device.lg} {
    display: flex;
    width: 100%;
    h4 {
      width: 240px;
    }
  }
`;
const GoalCol = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;

  h4 {
    width: 225px;
  }
`;

const Arrow = styled.img`
  align-items: center;
  @media ${device.phone} {
    display: none;
    width: 100%;
  }
  @media ${device.sm} {
    display: block;
    float: right;
    height: 2rem;
    color: red;
    margin-right: 2rem;
    align-self: center;
    animation: ease-in-out 1s ease-in-out infinite;
    width: 60px;
  }
  @media ${device.lg} {
    display: block;
    height: 2rem;
    color: red;
    margin-right: 2rem;
    align-self: center;
    animation: ease-in-out 1s ease-in-out infinite;
  }
`;
const GeoWrapper = styled.div`
  margin-top: 10px;
  height: 4rem;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
  border-radius: 5px;
  border: 1px solid $purple;
  background: white;
  .purple_svg {
    align-self: flex-start;
    height: 1.6rem;
    width: 2rem;
    padding: 13px 1.2rem;
    background-color: #6212c4;
    margin-top: -1px;
    margin-left: -0.9rem;
    padding-left: 17px;
    border-radius: 0 3px 3px 0;
  }
  .grey_svg {
    align-self: flex-start;
    height: 1.6rem;
    width: 2rem;
    padding: 13px 1.2rem;
    background-color: grey;
    margin-top: -1px;
    margin-left: -0.9rem;
    padding-left: 17px;
    border-radius: 0 3px 3px 0;
  }
  .landing_icon {
    font-size: 10px;
    background-color: #6212c4;
  }
  .map_svg {
    height: 1.6rem;
    padding: 1.2rem;
    background-color: $white;
    border-radius: 3px 0 0 3px;
  }
`;
const LandingButtonsRow = styled.section`
  align-items: baseline;
  margin-bottom: 1.6rem;
  flex-wrap: wrap;
  display: flex;
  @media ${device.phone} {
    width: 100%;
    justify-content: space-evenly;
    margin-top: 10px;
  }
  @media ${device.sm} {
    width: 100%;
    justify-content: flex-start;
    margin-top: 0px;
  }

  @media ${device.lg} {
    width: 100%;
  }
`;

const LandingButton = styled.button`
  color: ${(props) => (props.selected ? `${props.selectedFont}` : "white")};
  background-color: ${(props) =>
    props.selected ? `${props.variant}` : "#8233d4"};
  @media ${device.phone} {
    width: 48%;
    font-size: 1.6rem;
    flex-direction: row;
    height: 4rem;
    margin-bottom: 10px;
    margin-left: 0px;
    padding: 0;
    border-radius: 0.3rem;
  }
  @media ${device.sm} {
    width: 8rem;
    height: 2.8rem;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-top: 15px;
    margin-right: 11px;
    padding: 0;
    border-radius: 0.3rem;
  }
`;
const HoverLine = styled.div`
  margin-top: -10px;
  height: 3px;
  @media ${device.phone} {
    display: none;
  }
  @media ${device.sm} {
    border: 0px;
    width: 80px;
    position: absolute;
    transition: all 500ms ease;
    display: block;
  }
  ${(props) =>
    props.hoverColor === "blue" &&
    css`
      background: #0052ff;
      transition: all 1s ease;
      margin-left: calc(${(props) => props.hoverWidth} * 0);
    `}
  ${(props) =>
    props.hoverColor === "green" &&
    css`
      background: #00b050;
      margin-left: 182px;
    `}
  ${(props) =>
    props.hoverColor === "red" &&
    css`
      background: #ff0800;
      margin-left: 91px;
    `}
  ${(props) =>
    props.hoverColor === "yellow" &&
    css`
      background: rgb(255, 232, 23);
      margin-left: 273px;
    `}
`;
