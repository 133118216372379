import React, { useContext } from "react";
import styled, { css, keyframes } from "styled-components";
import { device } from "../../../utils/mediaQueries";
import AnimationContext from "../../../context/AnimationContext";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
const ResponsiveOtherInput = ({
  onBlur,
  onFocus,
  min,
  max,
  step,
  id,
  type,
  placeholder,
  value,
  onChange,
  className,
  style,
  InputLabelLeft,
}) => {
  const { route } = useContext(AnimationContext);
  console.log(value);
  return (
    <StyledWrapper route={route} value={value}>
      <StyledLabel route={route} value={value}>
        <StyledSpan>
          <MoreHorizIcon fontSize="large" />
        </StyledSpan>

        <StyledOtherInput
          style={style}
          onBlur={onBlur}
          onFocus={onFocus}
          min={min}
          max={max}
          id={id}
          type={type}
          step={step}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          className={className}
        />
      </StyledLabel>
    </StyledWrapper>
  );
};

export default ResponsiveOtherInput;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media ${device.phone} {
    width: 27rem;
    margin: 5px;
  }
  @media ${device.xs} {
    width: 250px;
    margin: 5px;
  }
`;

const StyledLabel = styled.div`
  @media ${device.phone} {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 26rem;
    height: 30px;
    color: black;
    border-radius: 5px;
    padding: 7px 10px;
    border: 2px solid #ffffff;
    cursor: pointer;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease-in-out;
    font-size: 12px;
    font-weight: 400;
    align-items: center;
    ${(props) =>
      props.value === "" ||
      (props.route === "isBuy" &&
        css`
          border-radius: 5px;
          border: 2px solid #0052ff;
        `)}
    ${(props) =>
      props.value === "" ||
      (props.route === "isRent" &&
        css`
          border-radius: 5px;
          border: 2px solid #ffe817;
        `)}
        ${(props) =>
      props.value === "" ||
      (props.route === "isSell" &&
        css`
          border-radius: 5px;
          border: 2px solid #ff0800;
        `)}
        ${(props) =>
      props.value === "" ||
      (props.route === "isInvest" &&
        css`
          border-radius: 5px;
          border: 2px solid #00b050;
        `)}
  }
`;
const StyledSpan = styled.span`
  width: 28px;
  height: 28px;
  font-size: 16px;
  text-align: center;
  line-height: 24px;
  font-weight: bold;

  img {
    height: 24px;
    width: 24px;
    padding: 0;
    border-radius: 0;
    background: none;
  }
`;
const StyledOtherInput = styled.input`
  border: none;
  flex: 1;
  margin-left: 10px;
  &:focus {
    outline: none;
    box-shadow: 0px 0px 2px blue;
  }
`;
