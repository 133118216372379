import React, { createContext, useState, useEffect } from "react";

const InvestContext = createContext();

export const InvestProvider = (props) => {
  // Step 1
  const [propertyType, setPropertyType] = useState("");
  // Step 2
  const [bedrooms, setBedrooms] = useState("");
  const [bathrooms, setBathrooms] = useState("");
  // Step 3
  const [minAcreage, setMinAcreage] = useState(0);
  const [maxAcreage, setMaxAcreage] = useState(10);
  // Step 4
  const [criteria, setCriteria] = useState("");
  // Step 5
  const [budget, setBudget] = useState("");
  // Step 6
  const [minCapRate, setMinCapRate] = useState("");
  const [minCash, setMinCash] = useState("");
  const [minARV, setMinARV] = useState("");
  const [minROI, setMinROI] = useState("");
  const [minProfit, setMinProfit] = useState("");
  const [minRent, setMinRent] = useState("");
  // Step 7 handled through MapContext
  // Step 8
  const [minSF, setMinSF] = useState(0);
  const [maxSF, setMaxSF] = useState(10000);
  // Step 9
  const [minYear, setMinYear] = useState(1900);
  const [maxYear, setMaxYear] = useState(2023);
  // Setp 10
  const [avoid, setAvoid] = useState("");

  const [location, setLocation] = useState("");

  const investData = {
    propertyType,
    minSF,
    maxSF,
    bedrooms,
    bathrooms,
    minAcreage,
    maxAcreage,
    criteria,
    budget,
    minCapRate,
    minROI,
    minCash,
    minARV,
    minProfit,
    minRent,
    avoid,
    location,
    minYear,
    maxYear,
  };
  return (
    <InvestContext.Provider
      value={{
        propertyType,
        setPropertyType,
        minSF,
        setMinSF,
        maxSF,
        setMaxSF,
        bedrooms,
        setBedrooms,
        bathrooms,
        setBathrooms,
        minAcreage,
        setMinAcreage,
        maxAcreage,
        setMaxAcreage,
        criteria,
        setCriteria,
        budget,
        setBudget,
        minCapRate,
        setMinCapRate,
        minROI,
        setMinROI,
        minCash,
        setMinCash,
        minARV,
        setMinARV,
        minProfit,
        setMinProfit,
        setMinRent,
        minRent,
        avoid,
        setAvoid,
        location,
        setLocation,
        minYear,
        setMinYear,
        maxYear,
        setMaxYear,
        investData,
      }}
    >
      {props.children}
    </InvestContext.Provider>
  );
};

export default InvestContext;
