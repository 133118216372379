import React from "react";
import styled, { css } from "styled-components";
import { device } from "../../../utils/mediaQueries";

const ButtonBlock = ({ ml, width, children }) => {
  return (
    <StyledButtonBlock width={width} ml={ml}>
      {children}
    </StyledButtonBlock>
  );
};

export default ButtonBlock;

const StyledButtonBlock = styled.div`
  display: flex;
  width: 600px;
  height: 180px;
  justify-content: center;
  align-items: center;
`;
