import React, { useContext } from "react";
import styled, { css } from "styled-components";
import { device } from "../../../utils/mediaQueries";
import AnimationContext from "../../../context/AnimationContext";

const InputButtonBlock = ({ ml, width, children }) => {
  const { route } = useContext(AnimationContext);

  return (
    <StyledButtonBlock route={route} width={width} ml={ml}>
      {children}
    </StyledButtonBlock>
  );
};

export default InputButtonBlock;

const StyledButtonBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  height: 220px;
  overflow-y: auto;
  /* custom scrollbar */
  ::-webkit-scrollbar {
    width: 20px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    ${props =>
      props.route === "isBuy" &&
      css`
        background: #0052ff;
      `}
    ${props =>
      props.route === "isRent" &&
      css`
        color: black;
        background: #ffe817;
      `}
      ${props =>
        props.route === "isSell" &&
        css`
          background: #ff0800;
        `}
        ${props =>
          props.route === "isInvest" &&
          css`
            background: #00b050;
          `}
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
  }
  @media ${device.phone} {
    width: 40rem;
    margin-left: 10px;
  }

  @media ${device.sm} {
    margin-left: 10px;
  }
`;
